import { type SvgIconProps } from '@mui/material'

export type IconType = SvgIconProps

import Bass from './bass.js'
import DeadFish from './dead-fish.js'
import GuitarTab from './guitar-tab.js'
import Chord from './guitar.js'
import Instagram from './instagram.js'
import Midi from './midi.js'
import Pdf from './pdf.js'
import Effect from './pedal.js'
import Piano from './piano.js'
import Spotify from './spotify.js'
import YouTube from './youtube.js'
import Evernote from './evernote.js'

import {
  TableRowsOutlined as TableRows,
  NotInterested as AccessDenied,
  AccountTreeOutlined as AccountTree,
  ViewListOutlined as Activities,
  AddOutlined as Add,
  AssessmentOutlined as Analyze,
  ArrowDropDownOutlined as ArrowDropDown,
  ArrowRightOutlined as ArrowRight,
  GraphicEqOutlined as Audio,
  GroupsOutlined as Band,
  Crop169Outlined as Block,
  FormatBoldOutlined as Bold,
  PsychologyOutlined as Brain,
  CalendarMonth as Calendar,
  CalendarToday as CalendarEvent,
  CameraFrontOutlined as CameraFront,
  CameraRearOutlined as CameraRear,
  CancelOutlined as Cancel,
  BarChartOutlined as Chart,
  AddchartOutlined as ChartData,
  ChatBubbleOutline as Chat,
  CheckOutlined as Check,
  CloseOutlined as Close,
  GroupWorkOutlined as Collaboration,
  ViewColumnOutlined as Columns,
  LaunchOutlined as Launch,
  RecordVoiceOverOutlined as Compose,
  CenterFocusStrongOutlined as Context,
  TuneOutlined as Controls,
  FileCopyOutlined as Copy,
  ArtTrackOutlined as Daw,
  AdbOutlined as Debug,
  DeleteOutlined as Delete,
  HorizontalSplitOutlined as Divider,
  DownloadOutlined as Download,
  DragHandleOutlined as DragHandle,
  DragIndicatorOutlined as DragIndicator,
  GestureOutlined as Drawing,
  EditOutlined as Edit,
  EjectOutlined as Eject,
  OpenInBrowserOutlined as Embed,
  EmojiEmotionsOutlined as Emoji,
  ErrorOutlineOutlined as ErrorOutline,
  ExitToAppOutlined as Exit,
  ExpandLessOutlined as ExpandLess,
  ExpandMoreOutlined as ExpandMore,
  DoDisturbOutlined as Fail,
  FavoriteOutlined as Favorite,
  FolderOutlined as Folder,
  FolderOpenOutlined as FolderOpen,
  TextFormatOutlined as Form,
  FormatAlignCenterOutlined as FormatAlignCenter,
  FormatAlignJustifyOutlined as FormatAlignJustify,
  FormatAlignLeftOutlined as FormatAlignLeft,
  FormatAlignRightOutlined as FormatAlignRight,
  FullscreenOutlined as FullScreen,
  FullscreenExitOutlined as FullScreenExit,
  FunctionsOutlined as Functions,
  CollectionsOutlined as Gallery,
  GamesOutlined as Game,
  GridViewOutlined as GridView,
  TitleOutlined as Heading,
  HeadsetOutlined as Headset,
  HelpOutline as Help,
  HomeOutlined as Home,
  ImageOutlined as Image,
  InfoOutlined as Info,
  FormatItalicOutlined as Italic,
  BuildOutlined as Labs,
  ChevronLeftOutlined as Left,
  LibraryMusicOutlined as LibraryMusic,
  LinkOutlined as Link,
  MeetingRoomOutlined as MeetingRoom,
  MenuOutlined as Menu,
  MenuOpenOutlined as MenuOpen,
  MicOffOutlined as MicOff,
  MicNoneOutlined as Microphone,
  MoreVertOutlined as MoreVertIcon,
  MoveUpOutlined as Move,
  LibraryMusicOutlined as MuscialScore,
  MusicNoteOutlined as MusicNote,
  KeyboardArrowRightOutlined as Next,
  NoteAltOutlined as Note,
  CloudOffOutlined as Offline,
  CloudOutlined as Online,
  OpenInNewOutlined as OpenLink,
  SubjectOutlined as Page,
  PauseOutlined as Pause,
  SlideshowOutlined as Performance,
  PlayArrowOutlined as Play,
  QueueMusicOutlined as PlayList,
  PollOutlined as Poll,
  HowToRegOutlined as PollFinalOption,
  BallotOutlined as PollResults,
  AudiotrackOutlined as Pool,
  PresentToAllOutlined as Present,
  CancelPresentationOutlined as PresentOff,
  SettingsOutlined as Preset,
  KeyboardArrowLeftOutlined as Prev,
  VisibilityOutlined as Preview,
  PrintOutlined as Print,
  QuestionMarkOutlined as Question,
  RestoreOutlined as Recent,
  FiberManualRecordOutlined as Record,
  FiberManualRecordOutlined as RecordOff,
  RefreshOutlined as Refresh,
  RemoveOutlined as Remove,
  DriveFileRenameOutline as Rename,
  AspectRatioOutlined as Resize,
  RestoreFromTrashOutlined as RestoreFromTrash,
  RestorePageOutlined as RestorePage,
  ChevronRightOutlined as Right,
  VideoCallOutlined as Room,
  RotateLeftOutlined as RotateLeft,
  RotateRightOutlined as RotateRight,
  SaveOutlined as Save,
  ListAltOutlined as Schema,
  SearchOutlined as Search,
  SettingsOutlined as Settings,
  ShareOutlined as Share,
  LyricsOutlined as Song,
  StarOutlined as Star,
  StarBorderOutlined as StarOutlined,
  StopOutlined as Stop,
  FormatStrikethroughOutlined as Strikethrough,
  LightbulbOutlined as Suggestion,
  AlbumOutlined as SwitchOff,
  AlbumOutlined as SwitchOn,
  TagOutlined as Tag,
  CheckBoxOutlined as Todo,
  TransformOutlined as Transform,
  PlusOneOutlined as Transpose,
  VerticalAlignCenterOutlined as Tuner,
  FormatUnderlinedOutlined as Underlined,
  UploadOutlined as Upload,
  VideocamOutlined as VideoCam,
  VideocamOffOutlined as VideoCamOff,
  VisibilityOutlined as View,
  ViewSidebarOutlined as ViewSidebar,
  WebAssetOutlined as WebAsset,
} from '@mui/icons-material'

const Video = Room
const Pedal = Effect
const PedalIcon = Effect
const Guitar = Chord
const ViewList = Activities
const Section = MuscialScore
const Mixer = Audio
const Comment = Chat
const Convert = Transform

export {
  AccessDenied,
  AccountTree,
  Activities,
  Add,
  Analyze,
  ArrowDropDown,
  ArrowRight,
  Audio,
  Band,
  Bass,
  Block,
  Bold,
  Brain,
  Calendar,
  CalendarEvent,
  CameraFront,
  CameraRear,
  Cancel,
  Chart,
  ChartData,
  Chat,
  Check,
  Chord,
  Close,
  Collaboration,
  Columns,
  Comment,
  Compose,
  Context,
  Controls,
  Convert,
  Copy,
  Daw,
  DeadFish,
  Debug,
  Delete,
  Divider,
  Download,
  DragHandle,
  DragIndicator,
  Drawing,
  Edit,
  Effect,
  Eject,
  Embed,
  Emoji,
  ErrorOutline,
  Exit,
  ExpandLess,
  ExpandMore,
  Fail,
  Favorite,
  Folder,
  FolderOpen,
  Form,
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FullScreen,
  FullScreenExit,
  Functions,
  Gallery,
  Game,
  GridView,
  Guitar,
  GuitarTab,
  Heading,
  Headset,
  TableRows,
  Help,
  Home,
  Image,
  Info,
  Instagram,
  Italic,
  Labs,
  Left,
  LibraryMusic,
  Link,
  MeetingRoom,
  Menu,
  MenuOpen,
  MicOff,
  Microphone,
  Midi,
  Mixer,
  MoreVertIcon,
  Move,
  MuscialScore,
  MusicNote,
  Next,
  Note,
  Offline,
  Online,
  OpenLink,
  Page,
  Pause,
  Pdf,
  Pedal,
  PedalIcon,
  Performance,
  Piano,
  Play,
  PlayList,
  Poll,
  PollFinalOption,
  PollResults,
  Pool,
  Present,
  PresentOff,
  Preset,
  Prev,
  Preview,
  Print,
  Question,
  Recent,
  Record,
  RecordOff,
  Refresh,
  Remove,
  Rename,
  Resize,
  RestoreFromTrash,
  RestorePage,
  Right,
  Room,
  RotateLeft,
  RotateRight,
  Save,
  Schema,
  Search,
  Section,
  Settings,
  Share,
  Song,
  Spotify,
  Star,
  StarOutlined,
  Stop,
  Strikethrough,
  Suggestion,
  SwitchOff,
  SwitchOn,
  Tag,
  Todo,
  Transform,
  Transpose,
  Tuner,
  Underlined,
  Upload,
  Video,
  VideoCam,
  VideoCamOff,
  View,
  ViewList,
  ViewSidebar,
  WebAsset,
  YouTube,
  Evernote,
  Launch,
}
