import { z } from 'zod'
import { baseEntitySchema } from '../entity.js'

export const folderSchema = baseEntitySchema.extend({
  type: z.literal('folder'),
})

export type Folder = z.infer<typeof folderSchema>

export function isFolder(node: unknown): node is Folder {
  return folderSchema.safeParse(node).success
}
