import { useCallback, useEffect, useRef } from 'react'

const hasIdleCallback = typeof requestIdleCallback === 'function' && typeof cancelIdleCallback === 'function'

/**
 * Delay invoke until idle or a timeout occurs. Will cancel previous invoke if called again.
 * idle can cause some issues in iteractive scenarios, so use with caution and check the performance.
 */
export const useDelayedInvoke = (maxDelay: number, type: 'timeout' | 'idle' = 'timeout') => {
  const useIdleCallback = type === 'idle' && hasIdleCallback
  const register = useCallback(
    (cb: () => void, timeout: number) =>
      useIdleCallback ? requestIdleCallback(cb, { timeout }) : (setTimeout(cb, timeout) as never),
    [useIdleCallback]
  )
  const cancel = useIdleCallback ? cancelIdleCallback : clearTimeout
  const handler = useRef<number | null>(null)

  const delayedInvoke = useCallback(
    (callback: () => void) => {
      if (handler.current) {
        cancel(handler.current)
      }
      const newHandler = register(callback, maxDelay)
      handler.current = newHandler

      return {
        /** Cancel the deferred invoke */
        cancelInvoke: () => {
          cancel(newHandler)
        },
        timeoutId: newHandler,
      }
    },
    [maxDelay, register, cancel]
  )

  /** Clear timeout on exit */
  useEffect(
    () => () => {
      if (!handler.current) {
        return
      }
      cancel(handler.current)
      handler.current = null
    },
    [cancel]
  )

  return delayedInvoke
}
