/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Y from 'yjs'
export const slateYjsOriginSymbol = Symbol('Denotes that an event originated from slate-yjs')
export const SyncElement = {
  getText(element: any) {
    return element === null || element === void 0 ? void 0 : element.get('text')
  },
  getChildren(element: any) {
    return element === null || element === void 0 ? void 0 : element.get('children')
  },
}
export const SyncNode = {
  getChildren(node: unknown) {
    if (node instanceof Y.Array) {
      return node
    }
    return SyncElement.getChildren(node)
  },
  getText(node: unknown) {
    if (node instanceof Y.Array) {
      return undefined
    }
    return SyncElement.getText(node)
  },
}
