/** Handle popup messaging for a key handler */

import type { NodeEntry } from 'slate'
import { ReactEditor } from 'slate-react'
import { type TunaEditor } from './plugin-types.js'
import { getEditorContainerId } from './editor-container.js'
import type { EditorEvent, EditorEventData, EditorEventType } from './hooks/editor-message.js'

export const dispatchRootEvent = (
  editor: TunaEditor,
  nodeEntry: NodeEntry,
  type: EditorEventType,
  data?: Omit<EditorEventData, 'htmlElement' | 'type' | 'nodeEntry' | 'selection'>
) => {
  if (!(nodeEntry && editor.id)) {
    return
  }
  const [node] = nodeEntry
  let domNode: HTMLElement | null = null
  try {
    domNode = ReactEditor.toDOMNode(editor as unknown as ReactEditor, node)
  } catch {}
  const eventData = {
    detail: { editor, type, nodeEntry, htmlElement: domNode, selection: editor.selection, ...data },
  }
  const ev: EditorEvent = new CustomEvent<EditorEventData>(type, eventData as never)
  const editorContainerId = getEditorContainerId(editor)
  const e = document.getElementById(editorContainerId)
  if (!e) {
    throw new Error(`Cannot find root DIV for editor ${editorContainerId}`)
  }
  e.dispatchEvent(ev)
}
