import * as React from 'react'
import cn from 'classnames'
import useStyles from './icon.styles.js'

function Pedal(props: { className?: string }) {
  const { className, ...restProps } = props
  const { classes } = useStyles()
  return (
    <svg
      className={cn(classes.root, className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
      {...restProps}
    >
      <path d="M4.222 19.778c-4.289-4.289-4.289-11.268 0-15.557l1.414 1.414c-3.509 3.509-3.509 9.219 0 12.729l-1.414 1.414zM19.778 19.778l-1.414-1.414c3.509-3.509 3.509-9.219 0-12.729l1.414-1.414c4.289 4.29 4.289 11.268 0 15.557z" />
      <path d="M18.349 11.499c-.598-.094-.961-.064-1.95-.621-.989-.557-1.1-1.421-1.482-2.045-.116-.227-.368-.621-1.152-.621-.512 0-1.517.596-2.874.596-.676 0-1.438-.147-2.271-.589-.537-.278-.993-.396-1.39-.396-.094 0-.185.007-.273.019-.56.083-1.117.614-1.537 1.181a1.008 1.008 0 00-.002 1.202 10.514 10.514 0 003.217 2.826c2.286 1.288 4.494 1.488 4.494 1.488l-.307.861 3.458 1.951c.629-.54 1.668-1.765 2.261-2.815.208-.371.498-1.532.584-1.973.058-.538-.274-.945-.776-1.064zM17.06 13.7c-.275.487-.69 1.05-1.09 1.524l-.19-.107a1.97 1.97 0 01-.392-.221s-.505-.311-.514-.312c.042-.667.209-1.171-.169-1.476-.079-.064-.175-.1-.265-.148l-1.155-.114c-.019-.002-1.906-.198-3.819-1.276a8.97 8.97 0 01-2.342-1.927c.058-.055.106-.093.14-.118.088.007.266.044.558.196.985.523 2.018.788 3.068.788 1.109 0 2.01-.292 2.607-.485l.103-.033.04.089c.268.598.716 1.6 1.925 2.28.724.408 1.231.586 1.697.687-.08.294-.16.56-.202.653z" />
    </svg>
  )
}

export default Pedal
