import { entitySort, type FilterFunc, includeElement, isHiddenSysEntity, type SortOptions } from '@tunasong/models'
import type { Entity, EntityType, Persisted } from '@tunasong/schemas'
import { useMemo } from 'react'
import { entitiesApi } from '../api/entities.js'

interface UseChildren {
  parentId?: string | null
  filter?: EntityType | FilterFunc
  // skip the query
  skip?: boolean
  defaultSort?: boolean
  includeSys?: boolean
  networkFirst?: boolean
  // Poll every x ms
  pollingInterval?: number
}

const childFilter =
  ({ parentId, filter: type, includeSys }: UseChildren) =>
  (child: Entity) =>
    child &&
    child.parentId === parentId &&
    includeElement(child, type) &&
    !child.trash &&
    (includeSys ? true : !isHiddenSysEntity(child))

export const useChildren = <T extends Entity = Entity>(
  {
    parentId = null,
    defaultSort = false,
    filter,
    includeSys = true,
    skip: providedSkip,
    networkFirst = false,
    pollingInterval,
  }: UseChildren = {
    includeSys: true,
  }
) => {
  const skip = providedSkip || Boolean(!parentId)
  const {
    currentData = [],
    isSuccess,
    ...restProps
  } = entitiesApi.useLoadChildEntitiesQuery(
    { parentId: parentId ?? '', networkFirst },
    {
      skip,
      pollingInterval,
    }
  )

  /** Make sure we don't re-render infinitely */
  const sortOptions = useMemo(
    (): SortOptions | undefined =>
      defaultSort
        ? {
            sortBy: 'updatedAt',
            order: 'desc',
          }
        : undefined,
    [defaultSort]
  )

  const entities = useMemo(() => {
    const filteredEntities = currentData?.filter(childFilter({ parentId, filter, includeSys }))
    return sortOptions && filteredEntities ? entitySort({ entities: filteredEntities, sortOptions }) : filteredEntities
  }, [currentData, filter, includeSys, parentId, sortOptions])

  const hasLoaded = Boolean(isSuccess && entities)

  return {
    entities: entities as Persisted<T>[],

    /** useMemo will happen the next render, so isSuccess will be set before we have data in entities */
    isSuccess: hasLoaded,

    hasLoaded,
    ...restProps,
  }
}
