// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Listener = (...args: any[]) => void
// Simple event emitter that simulates the DOM event emitter interface
// Use TEventNames to specify the types of events that can be emitted
export class EventEmitter<TEventName extends string = string> {
  listeners: Map<string, Set<Listener>>
  constructor() {
    this.listeners = new Map()
  }
  addListener(event: TEventName, listener: Listener) {
    const listenerSet = this.listeners.get(event) ?? new Set()
    if (!this.listeners.has(event)) {
      this.listeners.set(event, listenerSet)
    }
    listenerSet.add(listener)
  }
  removeListener(event: TEventName, listener: Listener) {
    const listenerSet = this.listeners.get(event)
    if (!listenerSet) {
      return
    }
    listenerSet.delete(listener)
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emit(event: TEventName, ...args: any[]) {
    const listenerSet = this.listeners.get(event)
    if (!listenerSet) {
      return
    }
    for (const listener of listenerSet) {
      listener(...args)
    }
  }
  removeAllListeners(event: TEventName) {
    this.listeners.delete(event)
  }
  destroy() {
    this.listeners.clear()
  }
}
