import { type FC } from 'react'
import type { RenderPluginsProps } from '../plugin-types.js'

export const RenderPlugins: FC<RenderPluginsProps> = props => {
  const { type, className, editor, componentProps, onClose } = props

  const { plugins } = editor

  const components = plugins.map((plugin, idx) => {
    // const pluginIdx = 0
    const {
      Help: HelpComponent,
      Suggest: SuggestComponent,
      Top: TopComponent,
      Root: RootComponent,
    } = plugin?.components ?? {}
    /** The index of actual rendered component */
    let RenderComponent
    switch (type) {
      /** Context is rendered outside of Slate Editor */
      case 'root':
        RenderComponent = RootComponent
        break
      /** Rendered inside of Slate Editor */
      case 'top':
        RenderComponent = TopComponent
        break
      case 'help':
        RenderComponent = HelpComponent
        break
      case 'suggest':
        RenderComponent = SuggestComponent
        break
      default:
        throw new Error(`Unsupported plugin render type: ${type}`)
    }

    if (!RenderComponent) {
      return null
    }

    return <RenderComponent key={idx} {...componentProps} className={className} editor={editor} onClose={onClose} />
  })

  return <>{components}</>
}
