/**
 * Sort an array of Persisted items in priority order:
 * 1. Starred items (if any)
 * 2. Most recently updated
 */

import type { Persisted } from '@tunasong/schemas'

interface SortItem extends Persisted<unknown> {
  starred?: boolean
}

export const prioritySort = <T extends SortItem>(items: T[], type: 'updatedAt' | 'createdAt' = 'createdAt') =>
  [...items].sort((a, b) => {
    /** Starred items are always on top */
    if (a.starred && b.starred) {
      return 0
    }
    if (a.starred) {
      return -2
    }
    if (b.starred) {
      return 2
    }
    /** Now look at the updated dates */
    return a[type] > b[type] ? -1 : 1
  })
