import { type SerializedError } from '@reduxjs/toolkit'
import { type FetchBaseQueryError } from '@reduxjs/toolkit/query'

export const formatError = (err: FetchBaseQueryError | SerializedError) => {
  /**
   * Bug in RTK Query
   * @see https://github.com/reduxjs/redux-toolkit/issues/3789
   */
  const error = 'error' in err ? (err.error as FetchBaseQueryError | SerializedError) : err
  if ('status' in error) {
    switch (error.status) {
      case 401:
        return 'You do not have access to this page'
      case 403:
        return 'Forbidden'
      case 404:
        return 'Page not found'

      case 'FETCH_ERROR':
        return 'Network error'
      case 'PARSING_ERROR':
        return 'Invalid data received'
      case 'TIMEOUT_ERROR':
        return 'Request timed out'

      default:
        return 'An unknown error occurred'
    }
  }
  return error.message ?? 'An unknown error occurred'
}
