import { type Entity } from '@tunasong/schemas'
import { type Persisted } from '@tunasong/schemas'
import { useCallback, useEffect, useState } from 'react'
import invariant from 'tiny-invariant'
import { entitiesApi } from '../api/entities.js'

/** Load parent entities to ensure that e.g., breadcrumbs work properly */
export const useAncestors = (entity?: Pick<Entity, 'parentId'>) => {
  const [ancestors, setAncestors] = useState<Record<string, Persisted<Entity>>>({})

  const [loadEntity] = entitiesApi.useLazyLoadEntityQuery()

  const parentId = entity?.parentId

  const loadParents = useCallback(
    async (entityId: string) => {
      invariant(entityId, 'entityId must be specified')
      let parentId: string | undefined = entityId
      const parents = []
      while (parentId && parentId !== 'ROOT') {
        const e: Persisted<Entity> = await loadEntity({ id: parentId }, true).unwrap()
        parents.push(e)
        parentId = e.parentId
      }
      return parents
    },
    [loadEntity]
  )

  useEffect(() => {
    if (!parentId) {
      return
    }
    loadParents(parentId).then(ancestors => {
      const ancestorsMap: Record<string, Persisted<Entity>> = {}
      for (const ancestor of ancestors) {
        ancestorsMap[ancestor.id] = ancestor
      }
      setAncestors(ancestorsMap)
    })
  }, [loadParents, parentId])

  return ancestors
}
