import { type ACL, type ItemOperation, PrincipalType } from '@tunasong/schemas'
import { type Owned } from './owned.js'

export const permImplies = (permA: ItemOperation, permB: ItemOperation) => {
  if (permA === 'WRITE' && permB === 'READ') {
    return true
  }
  return permA === permB
}

/** When not authenticated, use null as userId */
export const hasACLPermission = (userId: string | null, acls?: ACL[], perm: ItemOperation = 'READ') =>
  Boolean(userId || userId === null) &&
  Array.isArray(acls) &&
  Boolean(
    acls.find(
      acl =>
        (acl.principal === PrincipalType.PUBLIC ||
          acl.principal === PrincipalType.AUTHENTICATED ||
          (userId !== null && acl.principal === userId)) &&
        permImplies(acl.permission, perm)
    )
  )

export const hasPublicAccess = (acls?: ACL[]) =>
  Boolean(acls?.find(acl => acl.principal === 'PUBLIC' && acl.permission === 'READ'))

export const canRead = (userId: string | null, acls?: ACL[]) =>
  Boolean(hasACLPermission(userId, acls, 'READ') || hasACLPermission(userId, acls, 'WRITE') || hasPublicAccess(acls))

export const canReadEntity = <T>(userId: string | null, entity: Owned<T>) =>
  userId === entity.userId || canRead(userId, entity.acls)

export const canWriteEntity = <T>(userId: string | null, entity: Owned<T>) =>
  userId === entity.userId || canWrite(userId, entity.acls)

export const canWrite = (userId: string | null, acls?: ACL[]) => hasACLPermission(userId, acls, 'WRITE')

export const canDelete = (userId: string | null, acls?: ACL[]) => hasACLPermission(userId, acls, 'DELETE')

/** We remove duplicates server-side, so we can simply append them on the client */
export const mergeACLs = (acls?: ACL[] | null, newAcls?: ACL[] | null) => [...(acls ?? []), ...(newAcls ?? [])]
