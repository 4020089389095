import { Paper, Popper, type PopperProps } from '@mui/material'
import { type CoreElement, type Link } from '@tunasong/schemas'
import React, { type FC, useCallback, useRef, useState } from 'react'
import ReactFocusLock from 'react-focus-lock'
import { type EditorEvent, type EditorEventType, useEditor, useEditorMessage } from './hooks/index.js'

export interface PopupComponent<T extends CoreElement = CoreElement> {
  element: T
  onClose(): void
}

export interface PopupProps<T extends CoreElement> {
  className?: string
  Component: FC<PopupComponent<T>>
  showMessage: EditorEventType
  hideMessage?: EditorEventType
  children?: React.ReactNode
}

/** Context Popup that tracks the current position */

export const ContextPopup: FC<PopupProps<CoreElement>> = props => {
  const { showMessage, children, Component } = props

  const editor = useEditor()

  const anchorElRef = useRef<PopperProps['anchorEl']>(null)

  const [element, setElement] = useState<CoreElement>()

  const handleOpen = useCallback((ev: EditorEvent) => {
    setElement(ev.detail.nodeEntry?.[0] as CoreElement)
    anchorElRef.current = ev.detail.htmlElement
  }, [])

  useEditorMessage({ editor, type: showMessage, handler: handleOpen })

  const handleClose = useCallback(() => {
    setElement(undefined)
  }, [])

  if (!element) {
    return null
  }

  const show = Boolean(anchorElRef.current)

  return (
    <Popper
      contentEditable={false}
      open={show}
      transition={true}
      disablePortal={false}
      keepMounted={false}
      placement="top-start"
      anchorEl={anchorElRef.current}
    >
      {({}) => (
        <ReactFocusLock returnFocus={true}>
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: theme => theme.spacing(0.5),
            }}
          >
            <Component element={element} onClose={handleClose} />
            {children}
          </Paper>
        </ReactFocusLock>
      )}
    </Popper>
  )
}

export default ContextPopup
