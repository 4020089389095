/**
 * @note storing JWTs in localStorage is not safe.
 * As a kludge, we encrypt it, so at least people must go the extra length to get to the data:
 *
 * 1. Compromise the site
 * 2. Load all localStorage data to a adversary site
 * 3. Figure out the data is encrypted.
 * 4. Reverse engineer the encryption scheme from minimized Javascript
 *
 * A government entity will do this easily. The others - probably not.
 */

import CryptoJS from 'crypto-js'

const s = 'w3qoiue'

export const encrypt = (data: string) => CryptoJS.AES.encrypt(data, s).toString()
export const decrypt = (cipherText: string) => CryptoJS.AES.decrypt(cipherText, s).toString(CryptoJS.enc.Utf8)
