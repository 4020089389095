import { aclSchema } from '@tunasong/schemas'

import { z } from 'zod'

export const ShareItemSchema = z.object({
  name: z.string().optional(),
  userId: z.string(),
  acls: z.array(aclSchema).optional(),
})

export type ShareItem = z.infer<typeof ShareItemSchema>
