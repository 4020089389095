import { Button, TextField } from '@mui/material'
import { slugsApi, useEntity } from '@tunasong/redux'
import { useCallback, type FC, type FocusEvent } from 'react'
import { useCurrentUser } from '../user/index.js'

export interface SlugProps {
  entityId: string
}

export const Slug: FC<SlugProps> = props => {
  const { entityId } = props
  const { entity } = useEntity(entityId)

  const { userId } = useCurrentUser()
  const { currentData: slug } = slugsApi.useGetSlugByEntityIdQuery({ entityId })
  const [updateSlug] = slugsApi.useUpdateSlugMutation()
  const [createSlug] = slugsApi.useCreateSlugMutation()

  const updateSlugHandler = useCallback(
    (ev: FocusEvent<HTMLInputElement>) => {
      updateSlug({ entityId, data: { slug: ev.target.value } })
    },
    [entityId, updateSlug]
  )

  const createSlugHandler = useCallback(() => {
    if (!userId) {
      return
    }
    createSlug({
      slug: {
        userId,
        entityId,
        slug: `${entity?.name?.replace(' ', '-') ?? 'untitled'}-${entityId}`,
      },
    })
  }, [createSlug, entity, entityId, userId])

  return slug ? (
    <TextField defaultValue={slug?.slug} onBlur={updateSlugHandler} />
  ) : (
    <Button onClick={createSlugHandler}>Create Slug</Button>
  )
}

export default Slug
