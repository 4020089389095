import { yTextToSlateElement } from '@slate-yjs/core'
import { type CoreElement } from '@tunasong/schemas'
import * as Y from 'yjs'
import { toSlateDoc } from '../legacy/convert.js'
import invariant from 'tiny-invariant'

export const getElementV1 = (yDoc: Y.Doc) => {
  const sharedTypeV1 = yDoc.getArray('doc')
  const value = toSlateDoc(sharedTypeV1)
  return value
}

export const getSharedType = (yDoc: Y.Doc) => yDoc.get('content', Y.XmlText) as Y.XmlText
const getElementV2 = (yDoc: Y.Doc) => yTextToSlateElement(getSharedType(yDoc)) as CoreElement

export const getElementValue = (yDoc: Y.Doc) => {
  const { v1, v2 } = getSlateYDocVersion(yDoc)
  if (v2) {
    return getElementV2(yDoc).children as CoreElement[]
  }
  if (v1) {
    return getElementV1(yDoc) as CoreElement[]
  }

  throw new Error(`Could not determine slate-yjs version`)
}

/** Version check for YDoc. We use this when migrating from v1 (slate-yjs, Y.Array) to v2 (@slate-yjs/core, Y.XMLText) */
export const getSlateYDocVersion = (yDoc: Y.Doc) => {
  const sharedType = yDoc.getArray('doc')
  const sharedTypeV2 = getSharedType(yDoc)
  const v1 = sharedType.length > 0
  const v2 = sharedTypeV2.length > 0

  invariant(v1 || v2, `v1 or v2 should be true`)

  return {
    v1,
    v2,
  }
}
