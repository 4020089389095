import { type EntityOrElement } from '@tunasong/schemas'
import { type TunaPlugin } from './plugin-types.js'
import { type RenderMenuItemProps } from './render.js'

export const getActionsComponent = (plugins: TunaPlugin[] | null) => (props: RenderMenuItemProps<EntityOrElement>) => {
  const { element, ...restProps } = props

  if (!(element && plugins)) {
    return null
  }
  const p = plugins
    .map((plugin, idx) =>
        plugin.components?.Actions ? (
        <plugin.components.Actions element={element} autoFocus={idx === 0} key={idx} {...restProps} />
      ) : null
    )
    .filter(Boolean) as JSX.Element[]
  if (p.length === 0) {
    return null
  }
  return <>{p}</>
}
