import * as z from 'zod'
import { pageSchema } from '../../core/entities/page.js'

export const setlistSchema = pageSchema.extend({
  type: z.literal('setlist'),
})

export type Setlist = z.infer<typeof setlistSchema>

export function isSetlist(node?: unknown): node is Setlist {
  return setlistSchema.safeParse(node).success
}
