import { z } from 'zod'

const ItemOperationNames = ['READ', 'WRITE', 'DELETE'] as const
export type ItemOperation = (typeof ItemOperationNames)[number]

const PrincipalTypeNames = ['PUBLIC', 'AUTHENTICATED'] as const

export enum PrincipalType {
  PUBLIC = 'PUBLIC',
  AUTHENTICATED = 'AUTHENTICATED',
}

export type Principal = string | PrincipalType

export const aclSchema = z.object({
  principal: z.union([z.string(), z.enum(PrincipalTypeNames)]),
  permission: z.enum(ItemOperationNames),
})
export type ACL = z.infer<typeof aclSchema>

export function isACL(val: unknown): val is ACL {
  return aclSchema.safeParse(val).success
}
export function isACLs(val: unknown): val is ACL[] {
  return z.array(aclSchema).safeParse(val).success
}
