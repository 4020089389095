import { useMemo } from 'react'
import { activitiesApi } from '../api/activities.js'
import { prioritySort } from '../priority-sort.js'

export const useActivities = (entityId: string) => {
  const { currentData: activities } = activitiesApi.useLoadActivitiesForEntityQuery({ entityId })
  /** Sort by createdAt */
  return useMemo(() => prioritySort(activities ?? [], 'createdAt'), [activities])
}

export const useAllActivities = () => {
  const { currentData: activities = [], isLoading, isFetching } = activitiesApi.useLoadActivitiesQuery()

  const sorted = useMemo(() => prioritySort(activities, 'createdAt'), [activities])

  return { activities: sorted, isLoading: isLoading || isFetching }
}
