import { type FC } from 'react'
import BooleanSchemaField from './controls/boolean.js'
import { DateTimeSchemaField } from './controls/datetime.js'
import EnumSchemaField from './controls/enum.js'
import ListSchemaField from './controls/list.js'
import LiteralSchemaField from './controls/literal.js'
import NumberSchemaField from './controls/number.js'
import ObjectSchemaField from './controls/object.js'
import TextSchemaField from './controls/text.js'
import type { FormComponentGroupComponentProps, FormComponentProps, FormField } from './form-component.js'
import type { FieldValues } from 'react-hook-form'

export const getFieldComponent = <T extends FieldValues>(
  formElement: FormField
): FC<FormComponentProps<FormField, T> | FormComponentGroupComponentProps<FormField, T>> => {
  switch (formElement.type) {
    case 'text':
      return TextSchemaField as never
    case 'number':
      return NumberSchemaField as never
    case 'enum':
      return EnumSchemaField as never
    case 'datetime':
      return DateTimeSchemaField as never
    case 'list':
      return ListSchemaField as never
    case 'literal':
      return LiteralSchemaField as never
    case 'boolean':
      return BooleanSchemaField as never
    case 'object':
      return ObjectSchemaField as never
    default:
      return TextSchemaField as never
  }
}
