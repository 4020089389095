/** Meta information about another node, stored as a child of the node */
import * as z from 'zod'
import { baseEntitySchema } from '../../core/entity.js'

const settingsSchema = z.object({
  /** Transpose the song display, e.g., for trumpets */
  transpose: z.number().optional(),
})

/** Personalized user settings */
export const userSettingsSchema = baseEntitySchema.extend({
  type: z.literal('usersettings'),
  parentId: z.string(),
  settings: settingsSchema,
})

export type UserSettings = z.infer<typeof userSettingsSchema>
export type UserSettingsData = z.infer<typeof settingsSchema>
