import { useCallback, useEffect } from 'react'
import { usePageControl } from './page-control.hook.js'

export interface PageTurner {
  /** The view container */
  container?: HTMLElement | null
  /** Function to call when the user request the previous entity */
  onPrevious?(): void
  /** Function to call when the user request the next entity */
  onNext(): void
}

/** Page turner hook */
export const usePageTurner = ({ container, onPrevious = () => {}, onNext = () => {} }: PageTurner) => {
  const c = container ?? document.body

  const { isAtBeginning, isAtEnd, pageUp, pageDown } = usePageControl(c)

  const handlePrev = useCallback(() => {
    if (isAtBeginning) {
      onPrevious()
    } else {
      pageUp()
    }
  }, [isAtBeginning, onPrevious, pageUp])

  const handleNext = useCallback(() => {
    if (isAtEnd) {
      onNext()
    } else {
      pageDown()
    }
  }, [isAtEnd, onNext, pageDown])

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      /** If the target is an editable, don't handle the key */
      if (e.target instanceof HTMLElement && e.target.isContentEditable) {
        return
      }
      if (e.key === 'PageUp' || e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
        handlePrev()
      } else if (e.key === 'PageDown' || e.key === 'ArrowRight' || e.key === 'ArrowDown') {
        handleNext()
      }
    },
    [handleNext, handlePrev]
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  }, [onKeyDown, c])

  return { onKeyDown }
}
