import Sandbox from '@nyariv/sandboxjs'
import { entityCommandSpecSchema } from '@tunasong/schemas'
import z from 'zod'
/** Run the custom javascript code in a sandbox. Entity and params will be provided in the scope. */
export const generateCommandSpecsFromCustomCommand = ({
  code,
  scope,
}: {
  scope: Record<string, unknown>
  code: string
}) => {
  const sandbox = new Sandbox()

  // @todo support regular commands as well?
  const schema = z.array(entityCommandSpecSchema.passthrough())

  try {
    const exec = sandbox.compile(code)
    const runResult = exec(scope).run()
    // Verify that the result is an array of CommandSpec

    const result = schema.safeParse(runResult)
    return result
  } catch (error) {
    return { success: false, error } as ReturnType<typeof schema.safeParse>
  }
}
