import { type FC } from 'react'
import { HBox } from '../layout/horizontal-box.js'
import { PulseProgress, type PulseProps } from './pulse.js'

export interface PulseProgressBoxProps extends PulseProps {}

export const PulseProgressBox: FC<PulseProgressBoxProps> = props => {
  const {} = props
  return (
    <HBox sx={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <PulseProgress {...props} />
    </HBox>
  )
}

export default PulseProgressBox
