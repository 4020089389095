import { List, ListItemAvatar, ListItemText, MenuItem, type ListProps } from '@mui/material'
import { capitalize, isTopLevelEntityType } from '@tunasong/models'
import { usePlugins } from '@tunasong/plugin-lib'
import type { EntityType } from '@tunasong/schemas'
import { useMemo, type MouseEvent } from 'react'
import EntityAvatar from './entity-avatar.js'

export interface EntityTypeListProps extends Omit<ListProps, 'onSelect' | 'selected'> {
  selected?: EntityType | null
  size?: 'small' | 'medium'
  onSelect?: (type: EntityType) => void
}

export const EntityTypeList = (props: EntityTypeListProps) => {
  const { selected, onSelect, size = 'medium', ...restProps } = props
  const plugins = usePlugins('all')
  const handleSelect = (type: EntityType) => (ev: MouseEvent) => {
    if (onSelect) {
      ev.preventDefault()
      onSelect(type)
    }
  }
  const topLevelPlugins = useMemo(
    () =>
      plugins
        ?.filter(p => p.type && isTopLevelEntityType(p.type as EntityType))
        .sort((a, b) => (a.type ? a.type?.localeCompare(b.type ?? '') : 0)),
    [plugins]
  )

  return (
    <List {...restProps}>
      {topLevelPlugins
        ?.filter(p => p.type)
        .map(plugin => {
          const type = plugin.type as EntityType
          if (!type) {
            return null
          }
          return (
            <MenuItem key={plugin.type} onClick={handleSelect(type)} selected={selected === type}>
              <ListItemAvatar>
                <EntityAvatar type={type as EntityType} />
              </ListItemAvatar>
              {size === 'medium' ? <ListItemText primary={capitalize(plugin.type ?? 'Unknown')} /> : null}
            </MenuItem>
          )
        })}
    </List>
  )
}
