import { useEffect } from 'react'

export const useOnResize = (el: HTMLElement | null | undefined, fn: (rect: DOMRectReadOnly) => void) => {
  useEffect(() => {
    if (!(el && fn)) {
      return
    }
    /** Re-render on resize */
    const handleResize = (entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.contentRect) {
          fn(entry.contentRect)
        }
      }
    }

    const ro = new ResizeObserver(handleResize)
    ro.observe(el)

    return () => {
      ro.disconnect()
    }
  }, [el, fn])
}
