import { baseEntitySchema } from '../entity.js'
import { z } from 'zod'

export const chatSchema = baseEntitySchema.extend({
  type: z.literal('chat'),
  resolved: z.boolean().optional(),
})

export function isChat(node: unknown): node is z.infer<typeof chatSchema> {
  return chatSchema.safeParse(node).success
}

export type Chat = z.infer<typeof chatSchema>

export default chatSchema
