import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from './base-query.js'

export interface PushSub {
  url: string
  deviceName: string
  deviceId?: string
  /** Client crypto */
  p256dh: string
  auth: string
}

export interface PushResponse {
  url: string
  deviceName: string
  p256dh: string
  auth: string
  userId: string
}

export const pushApi = createApi({
  reducerPath: 'push-api',
  baseQuery,
  endpoints: builder => ({
    getPushPublicKey: builder.query<{ publicKey: string }, void>({
      query: () => `push/publickey`,
    }),
    registerPushSubscription: builder.query<PushResponse, PushSub>({
      query: body => ({
        url: `push/register`,
        method: 'POST',
        body,
      }),
    }),
  }),
})
