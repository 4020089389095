import { features, useSelector, useThunkDispatch } from '@tunasong/redux'
import { useEffect } from 'react'

/** Hook to get / set the global busy state */
export const useIsBusy = (busy: boolean | null = null) => {
  const dispatch = useThunkDispatch()
  const state = useSelector(state => state.notifications.isBusy)
  useEffect(() => {
    if (busy === null) {
      return
    }
    dispatch(features.notifications.actions.setBusy(busy))
    return () => {
      dispatch(features.notifications.actions.setBusy(false))
    }
  }, [busy, dispatch])

  return state
}
