import { useEffect } from 'react'
import { useRedraw } from './redraw.js'

export const useRedrawInterval = (refreshInterval = 50) => {
  /** Re-render the audio meters every 50ms */
  const refresh = useRedraw()
  useEffect(() => {
    const interval = setInterval(() => refresh(), refreshInterval)
    return () => {
      clearInterval(interval)
    }
  }, [refresh, refreshInterval])
}
