import { useEntitiesById } from '@tunasong/redux'
import type { Entity, Persisted } from '@tunasong/schemas'
import { useCallback } from 'react'
import { useUserConfig } from '../config/config-service.hook.js'

export const useFavorites = () => {
  const [favoriteIds, setFavorites] = useUserConfig('favorites')
  const { entities: favorites } = useEntitiesById(favoriteIds)

  const addFavorite = useCallback(
    (favorite: Persisted<Entity>) => {
      setFavorites([...(favoriteIds ?? []), favorite.id])
    },
    [favoriteIds, setFavorites]
  )

  const removeFavorite = useCallback(
    (favorite: Persisted<Entity>) => {
      setFavorites((favoriteIds ?? []).filter(id => id !== favorite.id))
    },
    [favoriteIds, setFavorites]
  )

  const isFavorite = useCallback(
    (entity?: Persisted<Entity>) => entity && (favorites ?? []).some(f => f.id === entity.id),
    [favorites]
  )

  return { favorites, addFavorite, removeFavorite, isFavorite }
}
