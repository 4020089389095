import { usePlugins } from '@tunasong/plugin-lib'
import type { EntityType } from '@tunasong/schemas'
import { useMemo } from 'react'

// Entity types that support event commands
export const useEventCommandEntityTypes = () => {
  const plugins = usePlugins('all')

  /** Find all types that provide entity commands */

  const types = useMemo(
    () =>
      plugins
        ?.filter(p => p.getEntityCommands)
        .map(p => p.type)
        .filter(Boolean),
    [plugins]
  )
  return types ? (types as EntityType[]) : []
}
