import { createApi } from '@reduxjs/toolkit/query/react'
import type { Slug } from '@tunasong/models'
import baseQuery from './base-query.js'

// Define a service using a base URL and expected endpoints
export const slugsApi = createApi({
  reducerPath: 'slugs-api',
  baseQuery,
  endpoints: builder => ({
    getSlug: builder.query<Slug, { slug: string }>({
      query: ({ slug }) => `slugs/?slug=${slug}`,
    }),
    getSlugByEntityId: builder.query<Slug, { entityId: string }>({
      query: ({ entityId }) => `slugs/?entityId=${entityId}`,
    }),
    createSlug: builder.mutation<Slug, { slug: Slug }>({
      query: ({ slug }) => ({
        url: `slugs/`,
        method: 'POST',
        body: slug,
      }),
    }),
    updateSlug: builder.mutation<Slug, { entityId: string; data: Partial<Slug> }>({
      query: ({ entityId, data }) => ({
        url: `slugs/${entityId}`,
        method: 'PATCH',
        body: data,
      }),
    }),
  }),
})
