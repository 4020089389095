/** Command. Currently this is just a function that takes the editor as an argument and returns the Editor for chainability.  */

import type { SvgIconComponent } from '@tunasong/icons'
import { capitalize, isPersistedEntity, type Maturity } from '@tunasong/models'
import { isCoreElement, type CoreElement, type ElementType } from '@tunasong/schemas'
import type { DefaultGlobalContext } from '../app-context.js'
import type { Command, Template, TunaEditor, TunaPlugin } from '../plugin-types.js'
import { Transforms, type NodeEntry } from '../slate-typescript.js'
import { getElementMediaForPlugin } from './plugin-util.js'

/** Get a command by name */

interface GetGlobalCommands {
  plugins: TunaPlugin[]
  text?: string
  maturity?: Maturity
  globalContext: DefaultGlobalContext
}

interface GetEditorCommand<TCommandName extends string = string> {
  commandId: TCommandName
  plugins: TunaPlugin[]
  editor: TunaEditor
  /** Current text that the plugin can use to generate the command(s) */
  text?: string

  nodeEntry?: NodeEntry<CoreElement>

  maturity?: Maturity
  /** @todo move redux to global context */
  globalContext: DefaultGlobalContext
}

/** Synthesize <Type>: Insert <Template> */
interface GetInsertCommands {
  editor: TunaEditor
  type?: ElementType
  nodeEntry?: NodeEntry<CoreElement>
  templates?: Record<string, Template>
}

const getInsertCommands = ({ editor, type, templates = {}, nodeEntry }: GetInsertCommands): Command[] => {
  const commands: Command[] = []
  if (!type) {
    return commands
  }
  for (const [template, node] of Object.entries(templates)) {
    /** Insert only supported for CoreElements, i.e., templates with `children`. */
    if (!isCoreElement(node)) {
      continue
    }
    /** @todo this is probably broken now as only Entities have templates */
    const name = `${capitalize(type)}: Insert ${template !== 'default' ? capitalize(template) : '...'}`
    commands.push({
      id: `insert-${type}-${template}`,
      name,
      cmd: () => {
        const at = nodeEntry ? nodeEntry[1] : editor.selection ?? undefined
        Transforms.insertNodes(editor, [node, { text: '' }], { at, select: true })
        return editor
      },
    })
  }

  return commands
}

/**
 * Get the available global commands.
 *
 */
export const getGlobalCommands = ({
  plugins,
  maturity = 'stable',
  globalContext,
  text,
}: GetGlobalCommands): Command[] => {
  const commands: Command[] = []
  for (const plugin of plugins) {
    const { getGlobalCommands } = plugin

    const { icon: Icon, materialColor } = getElementMediaForPlugin(plugin)

    const pluginCommands = getGlobalCommands ? getGlobalCommands({ maturity, globalContext, text }) : []

    /** Add the commands with media from the plugin */
    for (const cmd of pluginCommands) {
      commands.push({ Icon: Icon as SvgIconComponent, color: materialColor, ...cmd })
    }
  }
  return commands
}

/**
 * Get the available editor commands for this context. If there are duplicates, we use the last defined ones.
 *
 */
export const getEditorCommands = ({
  editor,
  nodeEntry,
  plugins,
  text = '',
  maturity = 'stable',
  globalContext,
}: Omit<GetEditorCommand, 'commandId'>): Command[] => {
  const commands: Command[] = []
  const entity = isPersistedEntity(editor.rootElement) ? editor.rootElement : undefined
  for (const plugin of plugins) {
    const { getEditorCommands, type, templates } = plugin

    const { icon: Icon, materialColor: color } = getElementMediaForPlugin(plugin)

    const pluginCommands = getEditorCommands
      ? getEditorCommands({ editor, nodeEntry, maturity, text, globalContext, entity })
      : getInsertCommands({ type, editor, templates })
    /** Add the commands with media from the plugin */
    for (const cmd of pluginCommands) {
      commands.push({ Icon: Icon as SvgIconComponent, color, ...cmd })
    }
  }
  return commands
}

//

export const getEditorCommand = ({
  commandId,
  editor,
  nodeEntry,
  plugins,
  text = '',
  maturity = 'stable',
  globalContext,
}: GetEditorCommand): Command | null => {
  const commands = getEditorCommands({ editor, plugins, nodeEntry, text, maturity, globalContext })
  return commands.find(command => command.id === commandId) ?? null
}

/** Run the editor command by name */
interface RunEditorCommand<T extends string = string> extends GetEditorCommand<T> {
  cmdParams?: Record<string, unknown>
}
export const runEditorCommand = async <TCommandName extends string = 'string'>({
  commandId,
  editor,
  plugins,
  nodeEntry,
  text = '',
  globalContext,
  maturity = 'stable',
  cmdParams = {},
}: RunEditorCommand<TCommandName>) => {
  const command = getEditorCommand({ commandId, plugins, editor, nodeEntry, text, maturity, globalContext })
  if (!command?.cmd) {
    throw new Error(`Command ${name} not found or command is null (not available)`)
  }
  return command.cmd(cmdParams)
}
