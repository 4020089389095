import { useEntityGraph } from '@tunasong/redux'
import { useEffect } from 'react'
import { useHover } from '../hooks/hover.js'

/** Pre-load the entity on hover */
export const usePreloadOnHover = ({
  entityId,
  containerEl,
}: {
  entityId?: string
  containerEl: HTMLElement | null
}) => {
  const hover = useHover(containerEl)
  const { getEntityById } = useEntityGraph()
  useEffect(() => {
    if (!(hover && entityId)) {
      return
    }
    // We use getEntityById instead of prefetch because we want to load the entity to check for storageUrls
    getEntityById(entityId)
  }, [hover, entityId, getEntityById])
}
