import { Button, ListItemAvatar, ListItemText, MenuItem, MenuList } from '@mui/material'
import { Share } from '@tunasong/icons'
import { entitiesApi, useProfiles } from '@tunasong/redux'
import type { ACL, Persisted } from '@tunasong/schemas'
import { type Entity } from '@tunasong/schemas'
import { useCallback, useMemo, useState, type FC } from 'react'
import { UserAvatar } from '../profile/index.js'
import { useCurrentUser } from '../user/index.js'

export interface TransferEntityProps {
  entity?: Persisted<Entity>
}

export const TransferEntity: FC<TransferEntityProps> = props => {
  const { entity } = props
  const acls: ACL[] = useMemo(() => entity?.acls ?? [], [entity])
  const userIds = useMemo(() => acls.map(acl => acl.principal), [acls])
  const { profiles } = useProfiles({ userIds, includeAssistant: false })
  const ownerProfile = useCurrentUser()

  const [transferEntityMutation] = entitiesApi.useTransferEntityMutation()

  const transferEntity = useCallback(
    (principal: string) => async () => {
      if (!entity) {
        return
      }
      await transferEntityMutation({ entity, toUserId: principal }).unwrap()
      setSelectedPrincipal(null)
    },
    [entity, transferEntityMutation]
  )

  const [selectedPrincipal, setSelectedPrincipal] = useState<string | null>(null)
  const handleSelect = useCallback((userId: string) => () => setSelectedPrincipal(userId), [])

  return (
    <>
      <MenuList>
        {acls
          .filter(acl => acl.permission === 'WRITE' && acl.principal !== ownerProfile.userId)
          .map((a, idx) => {
            const profile = profiles?.find(p => p.userId === a.principal)
            const id = profile ? profile.email : `Loading ${a.principal}...`
            return (
              <MenuItem key={idx} onClick={handleSelect(a.principal)} selected={selectedPrincipal === a.principal}>
                <ListItemAvatar>
                  <UserAvatar aria-label="user" userId={profile?.userId} />
                </ListItemAvatar>
                <ListItemText primary={id} />
              </MenuItem>
            )
          })}
      </MenuList>
      {selectedPrincipal && (
        <Button startIcon={<Share />} onClick={transferEntity(selectedPrincipal)}>
          Transfer to {profiles?.find(p => p.userId === selectedPrincipal)?.email}
        </Button>
      )}
    </>
  )
}

export default TransferEntity
