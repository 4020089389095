export const SectionTypeValues = [
  'Song',
  'Intro',
  'Verse',
  'Chorus',
  'Outro',
  'Bridge',
  'Middle8',
  'Riff',
  'Other',
] as const

export const SectionTypeMap = {
  Song: 'Song',
  Intro: 'Intro',
  Verse: 'Verse',
  Chorus: 'Chorus',
  Outro: 'Outro',
  Bridge: 'Bridge',
  Middle8: 'Middle8',
  Riff: 'Riff',
  Other: 'Other',
} as const

export type SectionType = (typeof SectionTypeValues)[number]
