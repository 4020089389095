import { colors } from '@mui/material'
import { type ColorName } from '@tunasong/schemas'
import { type CSSObject } from 'tss-react'
import { makeStyles } from '../styles.js'

/**
 * Create color classes for use with hashColor.
 * @example usage
 *
 * const colorClasses = useBackgroundColors(props)
 *
 * className={classNames(classes.tag, { [colorClasses[hashColorName(c)]]: true })}
 */

export type ColorType = 'background' | 'color' | 'border'

const allColors = Object.entries(colors).filter(e => e[0] !== 'common')
export const useBorderColors = makeStyles()(() => {
  const obj: Record<string, CSSObject> = {}

  for (const [key, value] of allColors) {
    const v = value as Record<number, string>
    obj[key] = {
      borderColor: `${v[500]} !important`,
    }
  }
  return obj as Record<ColorName, CSSObject>
})

export const useMainColors = makeStyles()(() => {
  const obj: Record<string, CSSObject> = {}
  for (const [key, value] of Object.entries(colors).filter(e => e[0] !== 'common')) {
    const v = value as Record<number, string>
    obj[key] = {
      color: `${v[500]} !important`,
    }
  }
  return obj as Record<ColorName, CSSObject>
})

export const useBackgroundColors = makeStyles()(theme => {
  const obj: Record<string, CSSObject> = {}
  for (const [key, value] of Object.entries(colors).filter(e => e[0] !== 'common')) {
    const v = value as Record<number, string>

    obj[key] = {
      backgroundColor: `${v[800]} !important`,
      color: `${theme.palette.getContrastText(v[800])} !important`,
    }
  }
  return obj as Record<ColorName, CSSObject>
})
