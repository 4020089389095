import {
  type CoreElement,
  type Entity,
  type EntityType,
  NO_PARENT,
  type Persisted,
  entitySchema,
  entityTypeNames,
  isEntity,
  isPersisted,
} from '@tunasong/schemas'
import { z } from 'zod'

/** For navigation, breadcrumbs */
export const navigationEntityWithTypeSchema = entitySchema
  .pick({
    parentId: true,
    type: true,
    name: true,
    tags: true,
  })
  .extend({ id: z.string() })

// Make type optional
export const navigationEntitySchema = navigationEntityWithTypeSchema.extend({
  type: navigationEntityWithTypeSchema.shape.type.optional(),
})

export type NavigationEntity = z.infer<typeof navigationEntitySchema>
export type NavigationEntityWithType = z.infer<typeof navigationEntityWithTypeSchema>

export interface CollabEntity extends Entity, Omit<CoreElement, 'type'> {
  /** Base64 encoded YDoc for collaborative documents */
  yDoc: string
}

export const isEntityType = (name: string) => entityTypeNames.includes(name as never)

export function isPersistedEntity<T>(node?: T): node is Persisted<T & Entity> {
  return isEntity(node) && isPersisted(node)
}

export function isCollabEntity<T>(node?: T): node is T & CollabEntity {
  return Boolean(isEntity(node) && ('yDoc' in node || node.storage?.docType === 'yDoc'))
}

/** @todo the whole concept of "top level" is fuzzy - refactor */
const excludeFromTopLevel: readonly EntityType[] = ['comment', 'usersettings'] as const
export const isTopLevelEntityType = (name: EntityType) =>
  entityTypeNames.filter(e => !excludeFromTopLevel.includes(e)).includes(name)

/** Check if the Entity is a Top-level entity, i.e., it is not by default a child of another.  */
export function isTopLevelEntity(node?: unknown): node is Entity {
  return Boolean(isEntity(node) && isTopLevelEntityType(node.type))
}

export const hasParent = (entity: Entity) => Boolean(entity?.parentId && entity.parentId !== NO_PARENT)
