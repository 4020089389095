import { aclSchema, baseEntitySchema, entityTypeNames } from '@tunasong/schemas'
import { z } from 'zod'
import { navigationEntityWithTypeSchema } from '../element/index.js'

export const searchSummarySchema = baseEntitySchema.extend({
  id: z.string(),
  type: z.enum(entityTypeNames),
  freetext: z.string(),
  parentChain: z.array(navigationEntityWithTypeSchema),
  embedding: z.array(z.number()).optional(),
  /** * @note we don't merge with persistedSchema due to type instantiation depth issues */
  userId: z.string(),
  acls: z.array(aclSchema).optional(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
})
// .merge(persistedSchema.pick({ updatedAt: true, createdAt: true }))

export type SearchSummary = z.infer<typeof searchSummarySchema>

export function isSearchSummary(node: unknown): node is SearchSummary {
  return searchSummarySchema.safeParse(node).success
}
