import { type AudioEvent } from '@tunasong/schemas'

export type EventFilterFunc = (...params: (number | undefined)[]) => (event: AudioEvent) => boolean

const eventLength = (event: AudioEvent) => (event.end ? event.end - event.start : 0)

/** Filter for events that are longer than minLength */
const length =
  (minLength = 0) =>
  (event: AudioEvent) =>
    eventLength(event) > minLength

/** Filter for events that contains a position  */
const contains =
  (position = 0) =>
  (event: AudioEvent) =>
    Boolean(event.start <= position && event.end && event.end >= position)

export const EventFilters = {
  length,
  contains,
}
