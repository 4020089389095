import { createApi } from '@reduxjs/toolkit/query/react'
import { type SearchSummary } from '@tunasong/models'
import type { SearchRequest, SearchResult } from '@tunasong/schemas'
import baseQuery from './base-query.js'

// Define a service using a base URL and expected endpoints
export const searchApi = createApi({
  reducerPath: 'search-api',
  keepUnusedDataFor: 60,
  baseQuery,
  endpoints: builder => ({
    search: builder.query<SearchResult<SearchSummary>, { query: SearchRequest; queryType: string }>({
      query: ({ query, queryType }) => ({
        method: 'POST',
        body: query,
        url: `search/?queryType=${queryType}`,
      }),
    }),
  }),
})
