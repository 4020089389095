/** Use reach router navigate for now */

import { type NavigationEntity } from '@tunasong/models'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { create } from 'zustand'
import { getEntityPath } from '../entity/entity-link.js'

export type NavigationMode = 'page' | 'panel' | 'drawer' | 'dialog' | 'tab'
export interface NavigationOptions {
  layout?: string
  navigationMode?: NavigationMode
}
export type NavFn = (entity?: NavigationEntity, props?: NavigationOptions) => void

export const useNavigateToEntityStore = create<{
  customNavigateFnStack: NavFn[]
  customNavigateFn: NavFn | null
  setCustomNavigateFn: (fn: NavFn) => void
  clearCustomNavigateFn: () => void
}>(set => ({
  // We need to support nested customNavigateFn calls, so we keep the stack of customNavigateFn
  setCustomNavigateFn: (fn: NavFn) =>
    set(state => {
      const newStack = [state.customNavigateFn, ...state.customNavigateFnStack].filter(Boolean)
      return { customNavigateFn: fn, customNavigateFnStack: newStack }
    }),
  customNavigateFn: null,
  customNavigateFnStack: [],
  clearCustomNavigateFn: () =>
    set(state => {
      const [customNavigateFn, ...newStack] = state.customNavigateFnStack
      return { customNavigateFn: customNavigateFn ?? null, customNavigateFnStack: newStack }
    }),
}))

export const useNavigateToEntity = ({ replace = false }: { replace?: boolean } = {}): NavFn => {
  const routerNavigate = useNavigate()
  /** @todo does not use the page/panel navigation hint */
  const defaultNavigate: NavFn = useCallback(
    (
      entity?: NavigationEntity,
      {
        layout,
      }: {
        layout?: string
        navigationMode?: NavigationMode
      } = {}
    ) => {
      if (!entity) {
        return
      }
      routerNavigate(getEntityPath({ entity, layout }), {
        replace,
      })
    },
    [replace, routerNavigate]
  )

  const { customNavigateFn } = useNavigateToEntityStore()
  if (customNavigateFn) {
    return customNavigateFn
  }

  return defaultNavigate
}

export {
  BrowserRouter,
  createBrowserRouter,
  Link as NavLink,
  Route,
  RouterProvider,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
