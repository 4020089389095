import { type Entity, type EntityType, type Persisted } from '@tunasong/schemas'
import { elementSort } from './sort.js'

/**
 * Sort the entities as a folder. This means that we:
 * 1. Folders first, sorted by name
 * 2. Then, entities grouped by type, sorted by name
 */
export const folderSort = (entities: Persisted<Entity>[]) => {
  const byType: Partial<Record<EntityType, Persisted<Entity>[]>> = {}
  for (const entity of entities) {
    const a = byType[entity.type] ?? []
    byType[entity.type] = a
    a.push(entity)
  }

  /** Sort all of the items by name */
  for (const key of Object.keys(byType)) {
    const typeKey = key as EntityType
    byType[typeKey] = elementSort(byType[typeKey], { order: 'asc', sortBy: 'name', ignoreStars: true })
  }

  let sortedItems: Persisted<Entity>[] = []

  /** First show all the folders and remove from the result */
  sortedItems = [...(byType.folder ?? [])]
  byType['folder'] = []

  /** Sort by entity type and then by name */
  for (const key of Object.keys(byType).sort()) {
    sortedItems = [...sortedItems, ...(byType[key as EntityType] ?? [])]
  }

  return sortedItems
}
