/* eslint-disable @typescript-eslint/no-unused-vars */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { type UserInfo } from '@tunasong/models'
import type {
  DirectedMessage,
  ICEServers,
  NewICECandidate,
  StopTransceiver,
  VideoAnswer,
  VideoMessage,
  VideoOffer,
  VideoRequest,
} from './webrtc.js'

// Define a type for the slice state
export interface WebRTCState {
  /** Signalling - the connections are PeerConnections */

  /** @todo we should figure out a way to not store objects in Redux */
  connections: Record<string, { peerClientId: string }>

  /** Presence is keyed on entityId first, senderClientId second */
  presence: Record<string, Record<string, UserInfo> | undefined>

  iceServers: RTCIceServer[]
  /** Have we requested ICE servers from the server? */
  iceServersRequested: boolean
  /** Date.now() of when the iceServers expires */
  iceServersExpires: number
}

// Define the initial state using that type
const initialState: WebRTCState = {
  connections: {},

  presence: {},

  /** Fallback ICE Servers. */
  iceServersRequested: false,
  iceServersExpires: 0,
  iceServers: [
    {
      urls: [
        'stun:stun1.l.google.com:19302',
        'stun:stun2.l.google.com:19302',
        'stun:stun3.l.google.com:19302',
        'stun:stun4.l.google.com:19302',
      ],
    },
    {
      urls: 'stun:stun.stunprotocol.org:3478',
    },
  ],
}

/**
 * @note if you get an Immer error here, pin the Immer version to ensure a single instance
 * @see https://github.com/reduxjs/redux-toolkit/issues/1181
 */
export const webRTCSlice = createSlice({
  name: 'webrtc',
  initialState,
  reducers: {
    /** @todo we should not keep peerConnect in Redux probably */
    addConnection: (state, action: PayloadAction<{ connection: { peerClientId: string } }>) => {
      const { connection } = action.payload
      state.connections[connection.peerClientId] = connection
    },
    removeConnection: (state, action: PayloadAction<{ peerClientId: string }>) => {
      delete state.connections[action.payload.peerClientId]
    },
    requestIceServers: state => {
      state.iceServersRequested = true
    },
    setIceServers: (state, action: PayloadAction<ICEServers>) => {
      const { iceServers, expires } = action.payload
      state.iceServers = iceServers
      state.iceServersExpires = expires ? expires + 1000 * 60 * 60 * 24 /** 24 hours */ : 0
    },
    newIceCandidate: (state, action: PayloadAction<NewICECandidate>) => {},
    videoRequest: (state, action: PayloadAction<VideoRequest>) => {},
    videoOffer: (state, action: PayloadAction<VideoOffer>) => {},
    videoAnswer: (state, action: PayloadAction<VideoAnswer>) => {},
    stopTranceiver: (state, action: PayloadAction<StopTransceiver>) => {},
    enterRoom: (state, action: PayloadAction<{ roomId: string; senderClientId?: string; timestamp?: number }>) => {},
    leaveRoom: (state, action: PayloadAction<{ roomId: string; senderClientId?: string; timestamp?: number }>) => {},
  },
})

export type WebRTCVideoRequestMessage = ReturnType<typeof webRTCSlice.actions.videoRequest>
export type WebRTCVideoOfferMessage = ReturnType<typeof webRTCSlice.actions.videoOffer>
export type WebRTCVideoAnswerMessage = ReturnType<typeof webRTCSlice.actions.videoAnswer>
export type WebRTCStopTranceiverMessage = ReturnType<typeof webRTCSlice.actions.stopTranceiver>
export type WebRTCNewICECandidateMessage = ReturnType<typeof webRTCSlice.actions.newIceCandidate>
export type WebRTCStopTransceiverMessage = ReturnType<typeof webRTCSlice.actions.stopTranceiver>
export type WebRTCEnterRoomMessage = ReturnType<typeof webRTCSlice.actions.enterRoom>
export type WebRTCLeaveRoomMessage = ReturnType<typeof webRTCSlice.actions.leaveRoom>

export type WebRTCMessage =
  | WebRTCVideoRequestMessage
  | WebRTCVideoOfferMessage
  | WebRTCVideoAnswerMessage
  | WebRTCStopTranceiverMessage
  | WebRTCNewICECandidateMessage
  | WebRTCStopTransceiverMessage
  | WebRTCEnterRoomMessage
  | WebRTCLeaveRoomMessage

/** These messages are directed, i.e., they should be sent to a specific peer */
export function isDirectedMessage(msg?: PayloadAction<unknown>): msg is PayloadAction<DirectedMessage> {
  return Boolean(msg?.payload && typeof msg.payload === 'object' && 'receiverClientId' in msg.payload)
}

export function isVideoMessage(msg: PayloadAction<unknown>): msg is PayloadAction<VideoMessage> {
  return Boolean(msg?.payload && typeof msg.payload === 'object' && 'senderClientId' in msg.payload)
}
