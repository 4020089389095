/** The Google Analytics uses the gatsby-plugin-google-analytics plugin */
type GTag = (
  event: string,
  action: string,
  data: {
    event_category?: string
    event_label?: string
  }
) => void

declare global {
  interface Window {
    gtag: GTag
  }
}

/** See https://developers.google.com/analytics/devguides/collection/upgrade/analyticsjs */
export type EventCategory = 'general' | 'video'

export interface CustomEvent {
  category: EventCategory
  action: string
  label: string
}

export interface PageViewEvent {
  path: string
  title?: string
}

export const trackEvent = ({ category, action, label }: CustomEvent) => {
  if (!window.gtag) {
    return
  }
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
  })
}
