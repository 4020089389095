import { type CoreElement, type Entity, isCoreElement } from '@tunasong/schemas'

/**
 * Reference entity contains a reference to another (persisted) Entity.
 * Typically used when embedding other Entities in documents, e.g., an audio recording in a song.
 */

type EntityRefOverrides<T extends Entity = Entity> = Omit<T, 'id' | 'type'>

export interface EntityRef<T extends Entity = Entity, S extends EntityRefOverrides<T> = EntityRefOverrides<T>>
  extends CoreElement {
  type: 'ref'

  /** The ID of the referenced Persisted Entity */
  entityId: string

  /** Property overrides */
  overrides?: Partial<S>
}

export function isEntityRef(element?: unknown): element is EntityRef {
  return Boolean(isCoreElement(element) && element.type === 'ref' && (element as EntityRef)?.entityId)
}
