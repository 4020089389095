import { isDirectedMessage, isVideoMessage, webRTCSlice } from './webrtc-slice.js'

/** Decorate the thunks in the slice */
export const webRTCFeature = {
  /** Type guards */
  actions: webRTCSlice.actions,
  guards: {
    isDirectedMessage,
    isVideoMessage,
  },
}
