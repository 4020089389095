import { z } from 'zod'

export const colorNames = [
  'amber',
  'blue',
  'blueGrey',
  'brown',
  'cyan',
  'deepOrange',
  'deepPurple',
  'green',
  'grey',
  'indigo',
  'lightBlue',
  'lightGreen',
  'lime',
  'orange',
  'pink',
  'purple',
  'red',
  'teal',
  'yellow',
] as const

export const colorNameSchema = z.enum(colorNames)

export type ColorName = z.infer<typeof colorNameSchema>
