import { type CoreElement, isCoreElement } from '@tunasong/schemas'
import { type EmbedSize } from '../element/sizable.js'
import { type Alignable } from '../element/alignment.js'

export interface Embed extends Alignable<CoreElement> {
  type: 'embed'
  service: string
  url?: string

  /** Layout */
  size: EmbedSize

  width?: string | number
}

export function isEmbed(node?: unknown): node is Embed {
  return Boolean(isCoreElement(node) && node.type === 'embed')
}

export interface YouTube extends Embed {
  service: 'youtube'
  mediaId?: string
  offset?: number
}

export function isYouTube(element: unknown): element is YouTube {
  return Boolean(isEmbed(element) && element.service === 'youtube')
}

export interface Spotify extends Embed {
  service: 'spotify'

  /** URL on the format of https://open.spotify.com/${mediaType}/${mediaId} */
  url?: string

  mediaType?: 'album' | 'song' | 'playlist'
  mediaId?: string
}

export function isSpotify(element: unknown): element is Spotify {
  return Boolean(isEmbed(element) && element.service === 'spotify')
}
