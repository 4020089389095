import { logger } from '@tunasong/models'
import { useEffect, useState } from 'react'

export type ResponsiveBreakpoint = number

/**
 * Return the first key in the provided breakpoints map where the contentRect width is less than the value.
 * @param el HTMLElement to monitor for size changes
 * @param breakpoints provide the keys in increasing width order, smallest first
 * */
export const useResponsiveContainer = <T extends string>(
  el: HTMLElement | null,
  breakpoints: Record<T, ResponsiveBreakpoint>
) => {
  const [size, setSize] = useState<T>()
  useEffect(() => {
    if (!el) {
      return
    }

    // const bp = breakpoints.sort((a, b) => (a.width < b.width ? -1 : a.width === b.width ? 0 : 1))
    const ro = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      const entry = entries.filter(e => e.target === el)[0]
      /** Iterate through the breakpoints and set the className */
      let largestSize: T | undefined
      for (const [key, b] of Object.entries(breakpoints)) {
        const above = entry.contentRect.width > (b as number)
        if (above) {
          logger.debug('Breakpoint, above', key, b, above, entry.contentRect.width)
          largestSize = key as T
        }
      }
      if (largestSize) {
        setSize(largestSize)
      }
    })
    ro.observe(el)
    return () => {
      ro.disconnect()
    }
  }, [breakpoints, el])
  return size
}
