/**
 * @deprecated - clean this up and use schema
 */

import { type CoreElement, inlineElementTypeNames, isCoreElement, proxyElementTypeNames } from './core/index.js'
import { entityTypeNames, isTypedElement } from './entity-types.js'

/** Custom type function to narrow the union type */
export type Narrow<T, U extends T> = U

/** @todo remove legacy  */
const LEGACY_ELEMENTS = ['spotify', 'youtube'] as const

/** Internal Entities are entities that should be shown in the UI by default */
export const INTERNAL_ELEMENTS = ['comment', 'line', 'divider'] as const

/**
 * @todo move this to schema
 * @todo this is currently used by getTypeFromMime() and used in uploadBlob()
 */
export const MEDIA_ELEMENTS = ['video', 'audio', 'image', 'audiotrack', 'pdf', 'midi', 'musicxml'] as const

/** Entities are top-level blocks that we store in the database */

/** Top block elements are elements that in an editor cannot be nested, i.e., they will always be on the top level UNLESS they are inlines */
export const TOP_BLOCK_ELEMENTS = [...entityTypeNames, 'section', 'embed'] as const

/** Block elements  */
export const BLOCK_ELEMENTS = [...TOP_BLOCK_ELEMENTS, ...INTERNAL_ELEMENTS, 'tab', 'score', 'profile'] as const
export const CHILD_ELEMENTS = ['note', 'comment', 'trackerdata'] as const
export const ELEMENTS = [
  ...CHILD_ELEMENTS,
  ...proxyElementTypeNames,
  ...BLOCK_ELEMENTS,
  ...inlineElementTypeNames,
  ...LEGACY_ELEMENTS,
  ...entityTypeNames,
] as const

export function isTopBlockElement(element?: unknown): element is CoreElement {
  /** Entity refs inherit their embedded elements characteristics */
  if (isCoreElement(element) && element.type === 'ref') {
    return isTopBlockElement(element.children[0])
  }

  return Boolean(isCoreElement(element) && TOP_BLOCK_ELEMENTS.includes(element.type as never))
}

export type ElementType = CoreElement['type']

export type BlockType = (typeof BLOCK_ELEMENTS)[number]

export type InternalEntitiesType = (typeof INTERNAL_ELEMENTS)[number]
export const isInternalEntity = (e?: unknown) =>
  Boolean(isTypedElement(e) && INTERNAL_ELEMENTS.includes(e.type as never))

export type MediaType = Narrow<ElementType, 'video' | 'audio' | 'image'>

/** Entities that must be children of other entities */
export const isChildEntityType = (name: string) => CHILD_ELEMENTS.includes(name as never)

/** Entities that are facade or proxy elements */

/** Entity ID  */
export type EntityId = string

/** DynamoDB does not allow null values in parentIndex, and we need a way to remove the parent. So we use the falsy "" value */
export const NO_PARENT = 'ROOT'
