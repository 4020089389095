import { type CoreElement, type Entity } from '@tunasong/schemas'

/** These are either set server-side or reserved (such as yDoc) */
export const embedReserved = ['yDoc', 'acls', 'version', 'versionAt', 'lastEditedBy']
export const entityReserved = [...embedReserved, 'id', 'userId', 'updatedAt', 'createdAt']
const remove = <T extends Partial<Entity>>(item: T, reservedKeys: string[]) => {
  const reserved = Object.keys(item).filter(k => reservedKeys.includes(k.trim()))
  const updatedItem = { ...item }
  for (const r of reserved) {
    delete (updatedItem as never)[r]
  }
  return updatedItem
}

export const isEntityReserved = (name: string) => entityReserved.includes(name.trim())

/** Remove keys that are reserved */
export const removeEntityReserved = <T extends Partial<Entity>>(item: T) => remove(item, entityReserved)
/** Remove keys that are reserved when embedding in a Slate editor */
export const removeEmbedReserved = <T extends Partial<Entity>>(item: T) => remove(item, embedReserved)

export const editorElement = (entity: Entity): CoreElement => ({
  children: [{ text: '' }],
  ...removeEmbedReserved(entity),
})
