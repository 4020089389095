import { isCoreElement } from '@tunasong/schemas'

export type Alignment = 'left' | 'center' | 'right'

export type Alignable<T> = T & {
  align?: Alignment
}

export function isAlignable<T>(element?: T): element is Alignable<T> {
  return Boolean(isCoreElement(element))
}
