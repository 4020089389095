import { z } from 'zod'

export const baseStorageSchema = z.object({
  type: z.enum(['S3']).optional(),
  docType: z.enum(['yDoc']).optional().describe('Type of the document, e.g., yDoc'),
  filename: z.string(),
})
export type Storage = z.infer<typeof baseStorageSchema>

export const s3StorageSchema = baseStorageSchema.extend({
  type: z.literal('S3').optional(),
  versionId: z.string().optional().describe('Version ID of the file in S3'),
})

export const storageSchema = s3StorageSchema

export type S3Storage = z.infer<typeof s3StorageSchema>

export function isStorage(node?: unknown): node is Storage {
  return storageSchema.safeParse(node).success
}

export function isS3Storage(node?: unknown): node is S3Storage {
  return s3StorageSchema.safeParse(node).success
}

export const storageUrlsSchema = z.object({
  expiresAtISODateTime: z.string().datetime(),
  /** main Url for the resource */
  url: z.string(),
})

export type StorageUrls = z.infer<typeof storageUrlsSchema>
