import { type CoreElement, type ElementType, isEntity, isProfile, isTypedElement } from '@tunasong/schemas'
import { isTopLevelEntity } from '../element/entity.js'

export type FilterFunc<T = unknown> = (node?: T) => boolean

export const isSameEntity = (id?: string) => (node?: unknown) => Boolean(id && isEntity(node) && id === node.id)
export const negateFilter = (filter: FilterFunc) => (node?: unknown) => !filter(node)

export const matchAny =
  (...funcs: FilterFunc[]) =>
  (el?: unknown) =>
    Boolean(funcs.find(func => func(el)))

export const matchTypes =
  (types: ElementType[] | boolean = []) =>
  (el?: unknown) =>
    Boolean(isTypedElement(el) && Array.isArray(types) ? types.includes(el.type) : types)

export const matchAll =
  (...funcs: FilterFunc[]) =>
  (el?: unknown) =>
    Boolean(funcs.every(func => func(el)))

export const matchNone = (...funcs: FilterFunc[]) => negateFilter(matchAny(...funcs))

export const includeElement = (element?: unknown, filter?: FilterFunc | ElementType) =>
  typeof filter === 'function' ? filter(element) : filter ? filter === (element as CoreElement)?.type : true

// const defaultSearch = matchAny(isSong, isMedia, isFolder, isPlayList, isPage, isVideo, isRoom, isChat, isEffect, isPdf)

export const EntityFilters = {
  folder: matchNone(isProfile),
  defaultSearch: isTopLevelEntity,
}
