// Generated by ts-to-zod
import { z } from 'zod'
import { baseEntitySchema, isBaseEntity } from '../../core/index.js'
import { deviceControlSchema } from '../gear/control.js'
import { midiSpecSchema } from '../midi//midispec.js'

export const EffectTypeValues = [
  'Amp',
  'Compressor',
  'Overdrive',
  'Distortion',
  'Chorus',
  'Flanger',
  'Delay',
  'Reverb',
  'MultiEffect',
  'Wah',
  'Other',
] as const

export type EffectType = (typeof EffectTypeValues)[number]

export const effectSchema = baseEntitySchema.extend({
  type: z.literal('effect'),

  name: z.string(),
  variant: z.enum(EffectTypeValues).optional(),
  /** Active program number */
  activeProgram: z.number().optional(),

  // MIDI Channel for the effect. For Omni (all channels) set channel to 0
  midiChannel: z.number().optional(),
  midiSpec: midiSpecSchema.optional(),

  controls: z.array(deviceControlSchema).optional(),
})

export type Effect = z.infer<typeof effectSchema>

export function isEffect(node?: unknown): node is Effect {
  return Boolean(isBaseEntity(node) && node.type === 'effect')
}
