/** Operations cache for yDoc */

import { logger } from '@tunasong/models'

export const clearYDocCache = (entityId: string) => {
  if (!indexedDB) {
    return
  }
  const req = indexedDB.deleteDatabase(entityId)
  req.onsuccess = () => {
    logger.debug(`Removed operations cache for ${entityId}`)
  }
  req.onerror = () => {
    throw new Error("Couldn't delete database")
  }
  req.onblocked = () => {
    logger.debug("Couldn't delete operations cache due to block")
  }
}
