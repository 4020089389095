/** Members. Currently defined as those in the ACLs + the owner */

import type { Entity, Persisted } from '@tunasong/schemas'
import { useMemo } from 'react'
import { useProfiles } from './profiles.js'

export const useMembers = (entity?: Persisted<Entity>) => {
  const memberIds = useMemo(
    () => [entity?.userId, ...(entity?.acls?.map(acl => acl.principal) ?? [])].filter(Boolean),
    [entity]
  )

  const { profiles: members } = useProfiles({
    userIds: memberIds,
    includeAssistant: false,
  })
  return members
}
