/** Get the shared type and initialize it with children */

import { slateNodesToInsertDelta } from '@slate-yjs/core'
import { type TunaDecendant } from '@tunasong/schemas'
import { fromUint8Array } from 'js-base64'
import invariant from 'tiny-invariant'
import * as Y from 'yjs'
import { getSharedType } from './get-shared-type.js'

export const createCollaborativeDoc = ({ id, nodes }: { id?: string; nodes: TunaDecendant[] }) => {
  const doc = new Y.Doc({ guid: id })

  const sharedType = getSharedType(doc)
  invariant(sharedType.length === 0, 'sharedType must be be empty')
  sharedType.applyDelta(slateNodesToInsertDelta(nodes))

  // Create a serialized string for the doc
  const yDoc = fromUint8Array(Y.encodeStateAsUpdate(doc))

  return { doc, sharedType, yDoc }
}
