import { IconButton, type IconButtonProps } from '@mui/material'
import { Close as CloseIcon } from '@tunasong/icons'
import { useCallback, type MouseEvent, type MouseEventHandler } from 'react'

export interface CloseButtonProps extends IconButtonProps {
  onClose: (ev: MouseEvent) => void
}

export const CloseButton = (props: CloseButtonProps) => {
  const { className, onClose, ...restProps } = props
  const handleClose: MouseEventHandler<HTMLButtonElement> = useCallback(
    (ev: MouseEvent) => {
      if (!onClose) {
        return
      }
      onClose(ev)
    },
    [onClose]
  )

  if (!onClose) {
    return null
  }

  return (
    <IconButton
      {...restProps}
      sx={{
        color: theme => theme.vars.palette.grey[500],
        zIndex: 1,
      }}
      aria-label="close"
      tabIndex={-1}
      onClick={handleClose}
    >
      <CloseIcon />
    </IconButton>
  )
}

export default CloseButton
