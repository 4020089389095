import { z } from 'zod'

export const slateTextSchema = z.object({
  text: z.string(),
})

export function isText(element?: unknown): element is SlateText {
  return slateTextSchema.safeParse(element).success
}

export type SlateText = z.infer<typeof slateTextSchema>
