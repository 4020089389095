import { Alert } from '@mui/material'

import { SystemTags } from '@tunasong/models'
import { type Entity, type Persisted } from '@tunasong/schemas'
import { type FC } from 'react'
import { useTags } from '@tunasong/redux'

export interface TemplateHeaderProps {
  entity?: Persisted<Entity>
}

export const TemplateHeader: FC<TemplateHeaderProps> = props => {
  const { entity } = props
  const { hasTag } = useTags(entity)

  if (!hasTag(SystemTags.Template)) {
    return null
  }

  return <Alert severity="info">This {entity?.type} is a template. </Alert>
}
