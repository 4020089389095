import { capitalize } from '@mui/material'

import { DatePicker, DateTimePicker } from '@mui/x-date-pickers'
import { logger } from '@tunasong/models'
import dayjs, { Dayjs } from 'dayjs'
import { type FC } from 'react'
import { Controller } from 'react-hook-form'
import type { DateTimeFormField } from '../../../lib/form-field.js'
import { type FormComponentProps } from '../form-component.js'

export interface DateTimeSchemaFieldProps extends FormComponentProps<DateTimeFormField> {}

export const DateTimeSchemaField: FC<DateTimeSchemaFieldProps> = props => {
  const { name, spec, control } = props
  const label = capitalize(spec.metadata?.label ?? name)
  const variant = spec.metadata?.variant ?? 'datetime'

  const handleDateChange = (onChange: (dateStr: string) => void) => (ev: Dayjs | null) => {
    if (!ev) {
      return
    }
    try {
      const val = ev.toISOString()
      logger.debug('DateTimeSchemaField', 'handleDateChange', val)
      onChange(val)
    } catch (e) {}
  }

  const DateControl = variant === 'date' ? DatePicker : DateTimePicker
  const format = variant === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'

  return (
    <Controller
      control={control}
      rules={{
        validate: {
          //   min: (date) => isFuture(date) || "Please, enter a future date"
        },
      }}
      name={name}
      render={({ field: { ref, onChange, value, ...field } }) => (
        <DateControl
          {...field}
          inputRef={ref}
          value={value ? (dayjs(value) as never) : null}
          sx={{ my: 2 }}
          label={label}
          onChange={handleDateChange(onChange)}
          // onBlur={handleDateChange(onBlur)}
          format={format}
        />
      )}
    />
  )
}

export default DateTimeSchemaField
