/** Viewport dimensions */
import { type Breakpoint, useMediaQuery, useTheme } from '@mui/material'

import { useMemo } from 'react'

/** Returns dimensions. Note that isSmall and isMedium  */
export const useDimensions = () => {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))
  const isSm = useMediaQuery(theme.breakpoints.only('sm'))
  const isMd = useMediaQuery(theme.breakpoints.only('md'))
  const isLg = useMediaQuery(theme.breakpoints.only('lg'))
  const isXl = useMediaQuery(theme.breakpoints.only('xl'))

  const orientation = screen.orientation?.type ?? 'portrait-primary'

  const isPortrait = orientation === 'portrait-primary' || 'portrait-secondary'
  const isLandscape = orientation === 'landscape-primary' || 'landscape-secondary'

  const isTablet = isMd || isSm
  const isDesktop = isLg || isXl

  let size: Breakpoint
  if (isXs) {
    size = 'xs'
  } else if (isSm) {
    size = 'sm'
  } else if (isMd) {
    size = 'md'
  } else if (isLg) {
    size = 'lg'
  } else {
    size = 'xl'
  }

  return useMemo(
    () => ({
      size,
      isTiny: isXs && !isSm,
      isSmallOrSmaller: isXs || isSm,
      isSmall: isSm,
      isMediumOrSmaller: isMd || isSm || isXs,
      isMedium: isMd,
      isLargeOrSmaller: isLg || isMd || isSm || isXs,
      isLarge: isLg,
      isXLargeOrSmaller: isXl || isLg || isMd || isSm || isXs,
      isXLarge: isXl,
      isPortrait,
      isLandscape,
      orientation,
      isDesktop,
      isTablet,
    }),
    [isDesktop, isLandscape, isLg, isMd, isPortrait, isSm, isTablet, isXl, isXs, orientation, size]
  )
}
