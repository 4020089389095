import { z } from 'zod'
import { baseEntitySchema } from '../entity.js'

export const linkSchema = baseEntitySchema.extend({
  type: z.literal('link'),
  url: z.string(),
  icon: z.string().optional(),
  description: z.string().optional(),
  pending: z.boolean().optional(),
})

export type Link = z.infer<typeof linkSchema>

export function isLink(node: unknown): node is Link {
  return linkSchema.safeParse(node).success
}
