import { type Entity, type Persisted } from '@tunasong/schemas'

import { useAncestors } from '@tunasong/redux'
import { useMemo } from 'react'
import { entityPath } from '../entity/entity-path.js'

/** Generate the parent chain for an entity */
export const useParentChain = (entity?: Persisted<Entity>) => {
  const ancestors = useAncestors(entity)

  const parentChain = useMemo(() => {
    if (!(entity && ancestors)) {
      return []
    }
    return entityPath(entity, ancestors, { hideSysEntities: true })
  }, [ancestors, entity])

  return parentChain
}
