/* eslint-disable tss-unused-classes/unused-classes */
import { Avatar, Box, type BoxProps, type Theme } from '@mui/material'
import { useProfile, useSelector } from '@tunasong/redux'
import classNames from 'classnames'
import { forwardRef, useCallback, useMemo } from 'react'
import { getProfileColor, useBackgroundColors } from '../color/index.js'

import { useNavigate } from 'react-router-dom'
import { makeStyles } from '../styles.js'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    '& > *': {
      margin: 0,
    },
    borderWidth: 1,
    border: 'solid',
    borderColor: theme.vars.palette.divider,
  },
  link: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  micro: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  tiny: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  medium: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}))

export interface UserAvatarProps extends BoxProps {
  className?: string
  userId?: string
  size?: 'micro' | 'tiny' | 'small' | 'medium' | 'large'

  /** Active link. @default true */
  link?: boolean

  /** Use a picture of the user (if available) */
  picture?: boolean

  /** fallbackName when userId is not set - e.g., before the user is registered */
  fallbackName?: string
}

export const UserAvatar = forwardRef((props: UserAvatarProps, ref) => {
  const { userId, className, size = 'medium', link = true, picture = true, fallbackName, ...restProps } = props
  const { classes } = useStyles()
  const loggedInId = useSelector(state => state.user.userId)
  const profile = useProfile(userId)
  const navigate = useNavigate()

  const { colorName } = useMemo(() => getProfileColor(profile), [profile])
  const { classes: colorClasses } = useBackgroundColors()

  const url = useMemo(() => (userId === loggedInId ? '/app/profile' : undefined), [loggedInId, userId])

  const handleNavigate = useCallback(() => link && url && navigate(url), [link, navigate, url])

  const name = profile?.name ?? profile?.email ?? fallbackName
  const title = `${name} ${profile?.email ? '(' + profile.email + ')' : ''}`
  const showPicture = Boolean(profile?.picture && picture)
  const pictureUrl = showPicture && profile?.picture ? profile.picture : undefined

  if (!profile) {
    return null
  }

  return (
    <Box {...restProps} display={'inline-block'} ref={ref}>
      <Avatar
        className={classNames(className, classes.root, {
          [classes.link]: Boolean(url),
          [classes[size]]: true,
          [colorClasses[colorName]]: profile && !showPicture,
        })}
        title={title}
        alt={name}
        src={pictureUrl}
        onClick={handleNavigate}
        color="inherit"
      >
        {!showPicture && name?.[0].toUpperCase()}
      </Avatar>
    </Box>
  )
})

export default UserAvatar
