import { detect } from 'detect-browser'

export const BrowserCapabilities = {
  supportsEdit: true, // browser && browser.os !== 'android' && browser.os !== 'Android OS',
}

export const getRawOS = () => {
  const browser = detect()
  /** If running tests, we just emulate a mac */
  if (typeof process !== 'undefined' && process.env.NODE_ENV === 'test') {
    return 'mac'
  }
  return browser?.os
}

export type OS = 'windows' | 'mac' | 'ios' | 'android' | 'unknown'

export const getOS = (): OS => {
  const rawOS = getRawOS()
  if (rawOS?.toLowerCase().startsWith('windows')) {
    return 'windows'
  }
  if (rawOS?.toLowerCase().startsWith('mac')) {
    return 'mac'
  }
  if (rawOS?.toLocaleLowerCase().startsWith('android')) {
    return 'android'
  }
  if (rawOS === 'iOS') {
    return 'ios'
  }
  /** Unknown OS */
  return 'unknown'
}

export const isSafari = () => {
  const browser = detect()
  const os = getOS()
  return os === 'ios' || browser?.name === 'safari'
}
export const isChrome = () => {
  const browser = detect()

  return browser?.name === 'chrome'
}
export const isEdge = () => {
  const browser = detect()
  return browser?.name.startsWith('edge')
}

const mobileOSList: OS[] = ['android', 'ios']

export const isMobileDevice = () => {
  const os = getOS()
  return mobileOSList.includes(os)
}

export const getBrowserName = () => {
  const browser = detect()
  return browser?.name
}
