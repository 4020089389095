import { useEffect, useState } from 'react'

export const useContainerSize = (el?: HTMLElement | null) => {
  const [size, setContainerSize] = useState<DOMRect>()
  useEffect(() => {
    if (!el) {
      return
    }
    /** Re-render on resize */
    const updateSize = (entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.contentRect) {
          setContainerSize(entry.contentRect)
        }
      }
    }

    const ro = new ResizeObserver(updateSize)
    ro.observe(el)

    return () => {
      ro.disconnect()
    }
  }, [el])
  return size
}
