import { z } from 'zod'
import { commandSpecSchema } from './command.js'

const entityEventNames = ['onEnter', 'onLeave'] as const
type EntityEventName = (typeof entityEventNames)[number]
export const entityEventSchema = z.enum(entityEventNames)

export const entityEvents: Record<EntityEventName, { description: string }> = {
  // the entity gets focus in the UI
  onEnter: {
    description: 'Run when entity gets focus',
  },
  // the entity de-focuses in the UI
  onLeave: {
    description: 'Run when entity loses focus',
  },
}

export type EntityEvent = z.infer<typeof entityEventSchema>

export const entityCommandSpecSchema = commandSpecSchema.extend({
  entityId: z.string(),
})

export type EntityCommandSpec = z.infer<typeof entityCommandSpecSchema>

export type EventCommands = Partial<Record<EntityEvent, EntityCommandSpec[]>>
