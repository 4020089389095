import { type FC } from 'react'
import { type LiteralFormField } from '../../../lib/form-field.js'
import { type FormComponentProps } from '../form-component.js'

export interface LiteralSchemaFieldProps extends FormComponentProps<LiteralFormField> {}

export const LiteralSchemaField: FC<LiteralSchemaFieldProps> = props => {
  const { name, spec, register } = props

  return <input type="hidden" defaultValue={spec.value} {...register(name)} />
}

export default LiteralSchemaField
