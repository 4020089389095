import { Box, ClickAwayListener, Fade, Popper, type PopperProps } from '@mui/material'
import { type VirtualElement } from '@popperjs/core'
import React, { useCallback } from 'react'
import { makeStyles } from '../styles.js'

export interface PopupProps extends PopperProps {
  open: boolean
  anchorEl: HTMLElement | VirtualElement | null
  clickAway?: boolean
  closeOnEscape?: boolean
  closeOnBackspace?: boolean
  children: React.ReactNode
  onClose?(event: Event): void
}

const useStyles = makeStyles()(() => ({
  popup: {
    zIndex: 1300,
    maxHeight: '50%',
    overflow: 'auto',
  },
}))

export const Popup: React.FC<PopupProps> = props => {
  const {
    open,
    anchorEl,
    onClose,
    children,
    closeOnBackspace = false,
    closeOnEscape = true,
    clickAway = true,
    ...restProps
  } = props
  const { classes } = useStyles()

  const handleKeyDown = useCallback(
    (ev: React.KeyboardEvent) => {
      if (!onClose) {
        return
      }
      if ((closeOnEscape && ev.key === 'Escape') || (closeOnBackspace && ev.key === 'Backspace')) {
        ev.preventDefault()
        onClose(ev.nativeEvent)
      }
    },
    [closeOnBackspace, closeOnEscape, onClose]
  )

  if (!open) {
    return null
  }
  const popper = (
    <Popper
      {...restProps}
      className={classes.popup}
      contentEditable={false}
      open={open}
      transition={true}
      disablePortal={false}
      keepMounted={false}
      placement="bottom-start"
      anchorEl={anchorEl}
      onKeyDown={handleKeyDown}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <Box>{children}</Box>
        </Fade>
      )}
    </Popper>
  )

  return clickAway && onClose ? <ClickAwayListener onClickAway={onClose}>{popper}</ClickAwayListener> : popper
}
