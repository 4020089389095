import { useEntitiesInc } from '@tunasong/redux'
import { useMemo } from 'react'
import { useCurrentUser } from '../user/index.js'
import type { Entity, Persisted } from '@tunasong/schemas'

interface UseTrash {
  pageSize?: number
}
export const useTrash = ({ pageSize = 50 }: UseTrash) => {
  const { userId } = useCurrentUser()

  const { entities, isFetching, isLoading, isComplete, resetIncrementalState, getNext } = useEntitiesInc<
    Persisted<Entity>
  >({
    trash: true,
    pageSize,
  })

  const trashed = useMemo(
    () =>
      Object.values(entities ?? {})
        .filter(e => e.userId === userId)
        .filter(e => e.trash),
    [entities, userId]
  )
  return { trashed, isFetching, isLoading, getNext, isComplete, reload: resetIncrementalState }
}
