/**
 * Get the caret position in all cases
 * @note There is still a corner case at the end of the document where the DIV is selected
 *
 * @see https://gist.github.com/nothingismagick/642861242050c1d5f3f1cfa7bcd2b3fd
 * @returns left, top distance in pixels
 */
export const getCaretTopPoint = (sel: Selection): { left: number; top: number } => {
  if (sel.rangeCount === 0) {
    return { left: 0, top: 0 }
  }
  const r = sel.getRangeAt(0)
  let rect
  let r2
  // supposed to be textNode in most cases
  // but div[contenteditable] when empty
  const node = r.startContainer
  const offset = r.startOffset
  if (offset > 0) {
    // new range, don't influence DOM state
    r2 = document.createRange()
    r2.setStart(node, offset - 1)
    r2.setEnd(node, offset)
    // https://developer.mozilla.org/en-US/docs/Web/API/range.getBoundingClientRect
    // IE9, Safari?(but look good in Safari 8)
    rect = r2.getBoundingClientRect()
    return { left: rect.right, top: rect.top }
  } else if (node.nodeType === Node.TEXT_NODE) {
    r2 = document.createRange()
    // similar but select next on letter
    r2.setStart(node, offset)
    r2.setEnd(node, offset + 1)
    rect = r2.getBoundingClientRect()
    return { left: rect.left, top: rect.top }
  } else if (node.nodeType === Node.ELEMENT_NODE) {
    const el = node as Element
    // https://developer.mozilla.org/en-US/docs/Web/API/Element.getBoundingClientRect
    rect = el.getBoundingClientRect()
    const styles = getComputedStyle(el)
    const lineHeight = parseInt(styles.lineHeight, 10)
    const fontSize = parseInt(styles.fontSize, 10)
    // roughly half the whitespace... but not exactly
    const delta = (lineHeight - fontSize) / 2
    return { left: rect.left, top: rect.top + delta }
  } else {
    throw new Error(`Unable to find caret position`)
  }
}

export const getCaretClientRect = (selection: Selection) => {
  const p = getCaretTopPoint(selection)
  const rect: ClientRect = {
    x: p.left,
    y: p.top,
    top: p.top,
    left: p.left,
    right: p.left,
    bottom: p.top,
    height: 0,
    width: 0,
    toJSON: () => '',
  }
  return rect
}
