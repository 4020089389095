/** Drag hook for element */

import { useCallback, useEffect, useState } from 'react'

/** Hook to support the case where the user clicks on an element and wants to drag it somewhere - e.g., to increase a value */
export interface DragProps {
  el: HTMLElement | null
  /** If onStart returns false we cancel the drag */
  onStart?(ev?: MouseEvent | TouchEvent): boolean | void
  onMove?(ev: MouseEvent): void
  onStop?(ev?: MouseEvent | TouchEvent): void
}

export const useDragMove = (props: DragProps) => {
  const [isDragging, setIsDragging] = useState(false)
  const { el, onStart, onMove, onStop } = props

  const handleMouseDown = useCallback(
    (ev: MouseEvent) => {
      const canDrag = onStart ? (onStart(ev) === false ? false : true) : true
      setIsDragging(canDrag)
    },
    [onStart]
  )

  const handleMouseUp = useCallback(
    (ev: MouseEvent) => {
      setIsDragging(false)
      if (onStop) {
        onStop(ev)
      }
    },
    [onStop]
  )
  const handleMouseMove = useCallback(
    (ev: MouseEvent) => {
      if (!(isDragging && onMove)) {
        return
      }
      onMove(ev)
    },
    [isDragging, onMove]
  )

  /** Event listeners on element to start the dragmove */
  useEffect(() => {
    if (!el) {
      return
    }
    el.addEventListener('mousedown', handleMouseDown)
    // el.addEventListener('touchstart', handleMouseDown)
    return () => {
      el.removeEventListener('mousedown', handleMouseDown)
      // el.removeEventListener('touchstart', handleMouseDown)
    }
  }, [el, handleMouseDown])

  /** Event handlers only while dragging */
  useEffect(() => {
    if (!isDragging) {
      return
    }
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
    // document.addEventListener('touchend', handleMouseUp)
    return () => {
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('mousemove', handleMouseMove)
      // document.removeEventListener('touchend', handleMouseUp)
    }
  }, [handleMouseDown, handleMouseMove, handleMouseUp, isDragging])

  return isDragging
}
