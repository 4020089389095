import type { UserConfig } from '@tunasong/manifest'
import manifest from '@tunasong/manifest'
import { logger } from '@tunasong/models'
import type { Profile } from '@tunasong/schemas'
import invariant from 'tiny-invariant'
import { profilesApi } from '../api/profiles.js'
import type { UserConfigService } from '../configuration/config-service.js'
import { useStore } from '../configure-store.js'
import { features } from '../features/index.js'
import { type TunaThunkDispatch } from '../hooks/thunk-dispatch.hook.js'

type PluginConfig<T extends keyof UserConfig['plugins']> = UserConfig['plugins'][T]

/**
 * Async user configuration service. This loads configuration async, so clients that must
 * have updated information should use this and wait for the promise to resolve.
 *
 * Others can use the synchronous version(s) that look like useState() hooks.
 */

export class ConfigService implements UserConfigService {
  public ready = false

  private getState: ReturnType<typeof useStore>['getState'] | null = null
  private dispatch: TunaThunkDispatch | null = null

  private readyResolveFn: (() => void | undefined) | null = null
  private readyPromise: Promise<void>
  private profile: Profile | null = null

  constructor() {
    this.readyPromise = new Promise<void>(resolve => {
      this.readyResolveFn = resolve
    })
  }

  init = ({
    getState,
    dispatch,
    profile,
  }: {
    getState: ReturnType<typeof useStore>['getState']
    dispatch: TunaThunkDispatch
    profile: Profile
  }) => {
    if (this.ready) {
      return
    }
    this.getState = getState
    this.dispatch = dispatch
    this.profile = profile

    const config = manifest.userConfig.safeParse(profile.config)
    if (config.success) {
      this.dispatch(features.config.actions.updateUserConfig(config.data))
    } else {
      logger.error('Invalid user config', { config: profile.config })
      // if we have an invalid configuration, we'll just drop it
      // and update the profile with the default configuration at the next change.
    }

    invariant(this.readyResolveFn, 'readyResolveFn is not set')

    this.readyResolveFn()

    this.dispatch(features.config.actions.setUserConfigLoaded(true))
  }

  getUserConfig = async <T extends keyof UserConfig>(key: T) => {
    await this.readyPromise

    invariant(this.profile, `Cannot update config without a profile`)
    invariant(this.getState, `Cannot update config for without a state`)
    invariant(this.dispatch, `Cannot update config for without dispatch`)

    const userConfig = this.getState().config.userConfig
    invariant(userConfig, 'User config are not set')
    return userConfig[key]
  }

  getPluginConfig = async <T extends keyof UserConfig['plugins']>(pluginName: T) => {
    const config = await this.getUserConfig('plugins')
    return config[pluginName] ?? {}
  }

  updateUserConfigByKey = <T extends keyof UserConfig>(key: T, value: UserConfig[T]) => {
    invariant(this.profile, `Cannot set config for ${key} without a profile`)
    invariant(this.getState, `Cannot set config for ${key} without a state`)
    invariant(this.dispatch, `Cannot set config for ${key} without dispatch`)

    // Parse with Zod to ensure it's valid
    const newValue = manifest.userConfig.parse({
      ...this.getState().config.userConfig,
      [key]: value,
    })

    this.dispatch(features.config.actions.updateUserConfig(newValue))

    return this.dispatch(
      profilesApi.endpoints.updateProfile.initiate({ id: this.profile.id, profile: { config: newValue } })
    )
  }

  updateUserConfigPartial = async (value: Partial<UserConfig>) => {
    invariant(this.profile, `Cannot update config without a profile`)
    invariant(this.getState, `Cannot update config for without a state`)
    invariant(this.dispatch, `Cannot update config for without dispatch`)

    // Parse with Zod to ensure it's valid
    const newValue = manifest.userConfig.parse({ ...this.getState().config.userConfig, ...value })

    this.dispatch(features.config.actions.updateUserConfig(newValue))

    return this.dispatch(
      profilesApi.endpoints.updateProfile.initiate({ id: this.profile.id, profile: { config: newValue } })
    ).unwrap()
  }

  updateUserConfig = <T extends keyof UserConfig | Partial<UserConfig>>(
    keyOrValue: T,
    value?: T extends keyof UserConfig ? UserConfig[T] : never
  ) => {
    if (typeof keyOrValue === 'string') {
      return this.updateUserConfigByKey(keyOrValue, value)
    }
    return this.updateUserConfigPartial(keyOrValue)
  }

  updatePluginConfig = <T extends keyof UserConfig['plugins']>(pluginName: T, val: PluginConfig<T>) => {
    invariant(this.profile, `Cannot update config without a profile`)
    invariant(this.getState, `Cannot update config for without a state`)
    invariant(this.dispatch, `Cannot update config for without dispatch`)

    // First get the current value of "plugins", then update the plugin's value
    return this.updateUserConfigByKey('plugins', { ...this.getState().config.userConfig.plugins, [pluginName]: val })
  }
}
