import Splitter, { SplitDirection } from '@devbookhq/splitter'
import { Box, type BoxProps } from '@mui/material'
import React, { forwardRef, useCallback } from 'react'
import { makeStyles } from '../styles.js'

export interface VericalSplitterProps extends Omit<BoxProps, 'left' | 'right' | 'onResize'> {
  left: React.ReactNode
  /** Specify flex as the percentage the left pane should occupy. */
  defaultLeftPercentage?: number
  minWidths?: [number, number]
  right: React.ReactNode
  thickness?: number
  /** onResize will return the leftFlex */
  onResize?(left: number): void
}

const useStyles = makeStyles()(theme => ({
  splitterGutter: {
    backgroundColor: theme.vars.palette.divider,
    width: theme.spacing(0.8),
  },
  splitterDragger: {
    backgroundColor: theme.vars.palette.divider,
  },
}))

export const VerticalSplitter = forwardRef((props: VericalSplitterProps, ref) => {
  const {
    left,
    right,
    minWidths = [50, 50],

    defaultLeftPercentage = 50,
    onResize,
    ...restProps
  } = props

  const { classes } = useStyles()

  const [leftFlex, setLeftFlex] = React.useState(defaultLeftPercentage ?? 50)

  const onResizePane = useCallback(
    (pairIdx: number, newSizes: number[]) => {
      if (pairIdx !== 0) {
        return
      }
      const flex = newSizes[0]
      setLeftFlex(flex)
      if (onResize) {
        onResize(flex)
      }
    },
    [onResize]
  )

  /** @note in order for the splitter to work, the container must have an explicitly set height */
  const sx = {
    display: 'flex',
    flex: 1,
    height: '100%',
    flexDirection: 'column',
    overflow: 'auto',
  }
  return (
    <Box ref={ref} {...restProps} sx={{ ...restProps.sx, height: '100%' }}>
      <Splitter
        direction={SplitDirection.Horizontal}
        minWidths={minWidths}
        initialSizes={[leftFlex, 100 - leftFlex]}
        onResizeFinished={onResizePane}
        gutterClassName={classes.splitterGutter}
        draggerClassName={classes.splitterDragger}
      >
        <Box sx={sx}>{left}</Box>
        <Box sx={sx}>{right}</Box>
      </Splitter>
    </Box>
  )
})

export default VerticalSplitter
