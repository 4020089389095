import { type FileWithPath } from 'react-dropzone'
import { getOS } from '../browser/index.js'

export const splitFolderPath = (file: Pick<FileWithPath, 'path'>) => {
  /** Get all the paths from the file */
  const sep = getOS() === 'windows' ? '\\' : '/'
  /** Get the folder names */
  const folderNames = file.path?.split(sep).filter(Boolean) ?? []
  return { folder: folderNames.slice(0, -1).join('/'), filename: folderNames.at(-1) }
}

export const getUniqueFolders = (files: Pick<FileWithPath, 'path'>[]) => {
  const paths = files.map(splitFolderPath).map(p => p.folder)
  return [...new Set(paths)].filter(Boolean)
}
