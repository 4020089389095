import { type FilterFunc, isLine, isSection, matchAny } from '@tunasong/models'
import { type CoreElement, type TunaDecendant, isCoreElement } from '@tunasong/schemas'
import { Node, Text } from 'slate'

const defaultInclude: FilterFunc = matchAny(isLine, isSection)

interface SerializeText {
  /** If not specified, all separators will be newlines */
  editor?: { children: TunaDecendant[] }
  /** The root element. If not specified, start from the editor root */
  el?: CoreElement
  /** Separator between each line. @default \n */
  separator?: string
  /** Include filter. @default is to include line and sections */
  include?: FilterFunc
}
/** Serialize text for the provided `el` element (or `editor` if el is not specified). By default, only text children of line and sections are included; use the `include` filter for custom filters */
export const serializeText = ({ editor, el, include = defaultInclude, separator = '\n' }: SerializeText): string => {
  const root = el?.children ?? editor?.children ?? []
  const text = root
    .flatMap(n => {
      if (!(include(n) || Text.isText(n))) {
        return
      }
      if (isCoreElement(n)) {
        const name = isSection(n) ? `${separator}${n.sectionType}:${separator}` : null
        // const separator = editor?.isInline(n) ? ' ' : '\n'
        return name
          ? [name, separator, serializeText({ editor, el: n, include, separator })]
          : serializeText({ editor, el: n, include, separator })
      } else {
        return Node.string(n).trim()
      }
    })
    .filter(Boolean)
    /** Remove whitespace */
    .filter(s => !s?.match(/^\W*$/))

    .join(separator)

  return text
}
