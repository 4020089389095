import { type Entity, isEntity } from '@tunasong/schemas'

export interface Score extends Entity {
  type: 'score'
  staves: unknown[]
  /* The DSL text string for the score */
  lang: string
}

export function isScore(node?: unknown): node is Score {
  return Boolean(isEntity(node) && node.type === 'score')
}
