import type { Entity, EventCommands, Persisted } from '@tunasong/schemas'
import { useMemo } from 'react'
import { useProfileCommands } from './profile-commands.hook.js'

/** Get all the defined EventCommands from the entity.  */
export const useEventCommands = (entity?: Persisted<Entity>) => {
  const { eventCommands: profileEventCommands } = useProfileCommands(entity?.type)
  const { eventCommands: entityEventCommands } = entity ?? {}

  const eventCommands = useMemo(() => {
    const keys = [
      ...Object.keys(entityEventCommands ?? {}),
      ...Object.keys(profileEventCommands ?? {}),
    ] as (keyof EventCommands)[]

    const commands: EventCommands = {}
    for (const key of keys) {
      commands[key] = [...(entityEventCommands?.[key] ?? []), ...(profileEventCommands?.[key] ?? [])]
    }
    return commands
  }, [entityEventCommands, profileEventCommands])

  return eventCommands
}
