import { logger } from '@tunasong/models'
import type { Entity } from '@tunasong/schemas'
import { useContext, useEffect, useState } from 'react'
import invariant from 'tiny-invariant'
import { UploadContext } from './upload-context.js'

/** Upload an entity backed by storage (IndexedDB). Note that this may upload other (deferred) files  */
export const useUploadStoredEntity = () => {
  const uploadManager = useContext(UploadContext)
  invariant(uploadManager, 'Upload manager must be defined in UploadContext')

  // We poll the upload manager to check if it is uploading
  const [isUploading, setIsUploading] = useState(false)
  useEffect(() => {
    const interval = setInterval(() => {
      setIsUploading(uploadManager.isUploading)
    }, 1000)
    return () => clearInterval(interval)
  })

  return {
    uploadNewEntity: uploadManager.uploadNewStoredEntity,
    getPendingUploadBlobURL: uploadManager.getPendingUploadBlobURL,
    isUploading,
  }
}

export const usePendingUploadBlobUrl = (entity?: Entity) => {
  /** Use upload  */
  const [uploadUrl, setUploadUrl] = useState<string>()
  const { getPendingUploadBlobURL } = useUploadStoredEntity()
  useEffect(() => {
    if (!entity?.id) {
      return
    }
    getPendingUploadBlobURL(entity.id).then(url => {
      logger.debug(`Pending upload URL for ${entity.id}: ${url}`)
      setUploadUrl(url)
    })
  }, [entity?.id, getPendingUploadBlobURL])

  return uploadUrl
}
