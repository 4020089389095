import { z } from 'zod'
import { baseEntitySchema } from '../../core/entity.js'

export const bandSpaceSchema = baseEntitySchema.extend({
  type: z.literal('bandspace'),
})

export type BandSpace = z.infer<typeof bandSpaceSchema>

export function isBandSpace(node?: unknown): node is BandSpace {
  return bandSpaceSchema.safeParse(node).success
}
