import { z } from 'zod'
import { baseEntitySchema } from '../../core/entity.js'
import { storageSchema } from '../../core/interfaces/storage.js'

export const musicXMLSchema = baseEntitySchema.extend({
  type: z.literal('musicxml'),
  url: z.string().url().optional(),
  storage: storageSchema,
})

export type MusicXML = z.infer<typeof musicXMLSchema>

export function isMusicXML(node: unknown): node is MusicXML {
  return musicXMLSchema.safeParse(node).success
}
