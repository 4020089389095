import { aclSchema } from './acl.js'
import { z } from 'zod'

export const accessControlSchema = z.object({
  userId: z.string(),
  acls: z.array(aclSchema).optional(),
})

export function isAccessControlled(node: unknown): node is z.infer<typeof accessControlSchema> {
  return accessControlSchema.safeParse(node).success
}
