import { Typography } from '@mui/material'
import { type FC } from 'react'

export interface EmptyLinksProps {
  text?: string
}

export const EmptyLinks: FC<EmptyLinksProps> = props => {
  const { text = 'No links' } = props
  return (
    <Typography
      sx={{ display: 'flex', justifyContent: 'center', color: theme => theme.vars.palette.text.disabled }}
      variant="caption"
    >
      {text}
    </Typography>
  )
}

export default EmptyLinks
