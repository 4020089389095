// eslint-disable-next-line spaced-comment
///  <reference types="vite/client" />

/** Logger wrapper. Currently just console */

const noop = () => {}

const logLevels: Record<string, number> = {
  trace: 0,
  debug: 1,
  info: 2,
  warn: 3,
  error: 4,
}

const envLogLevel = typeof process !== 'undefined' ? process.env?.LOG_LEVEL : undefined
const logLevelName: string = import.meta.env?.VITE_LOG_LEVEL ?? envLogLevel ?? 'info'
const logLevel = logLevels[logLevelName] ?? 3

// For local dev, we want a direct link to open directly in VS Code.
// Chrome: to enable direct links to open in VS Code on mac (terminal) run this command:
//  defaults write com.google.Chrome URLAllowlist -array 'vscode://*'
const formatContext = (context: string | undefined) =>
  // The idea here was to format direct VSCode links to open in VSCode, but it's not working.
  // if (context?.includes('localhost:8000/@fs')) {
  //   const path = context.split('localhost:8000/@fs')[1]
  //   const [sourcePath, searchParams] = path.split('?t=')
  //   const [_, line, col] = searchParams?.split(':') ?? []
  //   return `${sourcePath}:${line}:${col}`
  // }
  context

const logWithCallerContext =
  (logFn: (...args: unknown[]) => void) =>
  (...args: unknown[]) => {
    // Create an Error to get the call site
    const e = new Error()

    const stackFrames = e.stack?.split('\n')
    const callSite = stackFrames && stackFrames.length >= 3 ? stackFrames[2].trim() : ''

    return logFn(...args, formatContext(callSite))
  }

export const logger = {
  trace: logLevel <= logLevels.trace ? logWithCallerContext(console.trace) : noop,
  debug: logLevel <= logLevels.debug ? logWithCallerContext(console.debug) : noop,
  info: logLevel <= logLevels.info ? logWithCallerContext(console.log) : noop,
  warn: logLevel <= logLevels.warn ? logWithCallerContext(console.warn) : noop,
  error: logLevel <= logLevels.error ? logWithCallerContext(console.error) : noop,
  time: console.time,
  timeEnd: console.timeEnd,
}

logger.debug('Logger initialized', { logLevel: logLevelName })
