import { getOS } from '../browser/index.js'
import {
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_RIGHT,
  ARROW_UP,
  BACKSPACE,
  CMD,
  CTRL,
  OPTION,
  RETURN,
  SHIFT,
  WIN,
} from './shortcut.js'

const os = getOS()

export const makeLabel = (hotkey = '') =>
  hotkey
    .replace('ctrl', os === 'windows' ? 'Ctrl' : CTRL)
    .replace('shift', SHIFT)
    .replace('meta', os === 'windows' ? WIN : CMD)
    .replace('mod', os === 'windows' ? 'Ctrl' : CMD)
    .replace('opt', os === 'windows' ? 'Alt' : OPTION)
    .replace('alt', OPTION)
    .replace('backspace', BACKSPACE)
    .replace('delete', BACKSPACE)
    .replace('enter', RETURN)
    .replace('left', ARROW_LEFT)
    .replace('right', ARROW_RIGHT)
    .replace('down', ARROW_DOWN)
    .replace('up', ARROW_UP)
    .toUpperCase()
