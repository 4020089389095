/** Check if the document is a valid document */

import { type CoreElement } from '@tunasong/schemas'
import { Element, Node, Text } from 'slate'

/** Check if element is a valid Slate Document */
export function isValidSlateDocument<T>(node: T): node is T & CoreElement {
  return (
    Element.isElement(node) &&
    Array.isArray(node.children) &&
    node.children.length > 0 &&
    Element.isElement(node.children[0])
  )
}

/** Check if node is a valid Slate Element */
export const isValidSlateElement = (node: unknown) =>
  Element.isElement(node) && Array.isArray(node.children) && node.children.length > 0 && Node.isNode(node.children[0])

export const ensureAllNodesHaveChildren = (node: Element | Text): Element | Text => {
  if (Text.isText(node)) {
    return node
  }
  const children = node.children ?? [{ text: '' }]
  return { ...node, children: children.map(n => ensureAllNodesHaveChildren(n)) }
}
