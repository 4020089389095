import z from 'zod'

export const loginStateSchema = z.object({
  callbackUrl: z.string(),
  // A random code string the client has stored that will be used to verify the login request
  clientCode: z.string(),
})

export type LoginStateSchema = z.infer<typeof loginStateSchema>

export const decodeLoginState = (state: string) => {
  const str = Buffer.from(state, 'base64').toString()
  const result = loginStateSchema.safeParse(JSON.parse(str))
  return result.success ? result.data : null
}

export const encodeLoginState = (state: LoginStateSchema) => {
  const clientStateJson = JSON.stringify(state)
  return btoa(clientStateJson)
}
