import { profilesApi, useProfile } from '@tunasong/redux'
import type { EntityType, EventCommands } from '@tunasong/schemas'
import invariant from 'tiny-invariant'

// Map of EntityType,
type EventCommandsByTypeConfig = Partial<Record<EntityType, EventCommands>>

export const useProfileCommands = (type?: EntityType) => {
  const profile = useProfile()
  const [updateProfile] = profilesApi.useUpdateProfileMutation()

  const eventCommandsByType = (profile?.config?.eventCommandsByType ?? {}) as EventCommandsByTypeConfig
  const eventCommands = type ? eventCommandsByType[type] ?? {} : {}

  const onChange = (newCommands: EventCommands) => {
    invariant(type && profile)
    const newConfig = {
      ...eventCommandsByType,
      [type]: newCommands,
    }
    updateProfile({
      id: profile.id,
      profile: {
        config: {
          ...profile.config,
          eventCommandsByType: newConfig,
        },
      },
    })
  }

  return { eventCommands, onChange }
}
