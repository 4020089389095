import {
  DialogTitle as MUIDialogTitle,
  Typography,
  type DialogTitleProps as MUIDialogTitleProps,
  type Theme,
} from '@mui/material'
import React, { type MouseEvent } from 'react'
import { makeStyles } from '../styles.js'
import { CloseButton } from './close-button.js'

export interface DialogTitleProps extends MUIDialogTitleProps {
  id?: string
  controls?: React.ReactNode
  children?: React.ReactNode
  onClose: (ev: MouseEvent) => void
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...(theme.typography.h6 as Record<string, string>),
  },
}))

export const DialogTitle = (props: DialogTitleProps) => {
  const { id, title, children, controls = null, onClose, ...restProps } = props
  const { classes } = useStyles()
  return (
    <MUIDialogTitle id={id} className={classes.root} {...restProps}>
      {title ? <Typography variant="h6">{title}</Typography> : null}
      {children}
      {controls}
      <CloseButton onClose={onClose} />
    </MUIDialogTitle>
  )
}

export default DialogTitle
