import { type FilterFunc, includeElement, isTaggedSysEntity } from '@tunasong/models'
import type { Entity, EntityType } from '@tunasong/schemas'

export interface UseChildren {
  parentId?: string | null
  type?: EntityType
  filter?: EntityType | FilterFunc
  includeSys?: boolean
}

export const childFilter =
  ({ parentId, filter: type, includeSys }: UseChildren) =>
  (child: Entity) =>
    child &&
    child.parentId === parentId &&
    includeElement(child, type) &&
    !child.trash &&
    (includeSys ? true : !isTaggedSysEntity(child))
