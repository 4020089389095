import { z } from 'zod'
import { audioSchema, imageSchema, pdfSchema, videoSchema } from '../../core/entities/index.js'
import { trackSchema } from './audio-track.js'
import { midiSchema } from './midi.js'

export const mediaSchema = z.union([audioSchema, trackSchema, imageSchema, pdfSchema, midiSchema, videoSchema])

export type Media = z.infer<typeof mediaSchema>

export function isMedia(node?: unknown): node is Media {
  return mediaSchema.safeParse(node).success
}
