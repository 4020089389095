import { isEntity, type Entity, type EntityType, type Persisted } from '@tunasong/schemas'
import { type SortOptions } from '../element/index.js'
import { includeElement, type FilterFunc } from '../filters/filters.js'
import { entitySearch } from './search.js'
import { elementSort } from './sort.js'

export interface FilteredParams<T extends Persisted<Entity>> {
  entities: T[]
  filter?: EntityType | FilterFunc
  typeFilter?: string[]
  query?: string
  showAll?: boolean
  sortOptions?: SortOptions
}

export const entitySort = <T extends Persisted<Entity> = Persisted<Entity>>({
  entities: allEntities,
  filter = isEntity,
  typeFilter = [],
  query,
  sortOptions,
}: FilteredParams<T>): T[] => {
  const entities = allEntities.filter(e => includeElement(e, filter))
  const search = entitySearch(entities)

  /** If query is set, search the songs. If not, just sort them. Filter on the type filter */
  return elementSort(
    query ? search(query) : entities,
    sortOptions
      ? sortOptions
      : {
          order: 'desc',
          sortBy: 'updatedAt',
        }
  ).filter(e => (typeFilter.length > 0 ? typeFilter.includes(e.type) : true)) as T[]
}
