import { TextField, capitalize } from '@mui/material'
import { type FC } from 'react'
import { type FormComponentProps } from '../form-component.js'

export const TextSchemaField: FC<FormComponentProps> = props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { name, spec, FormControl, register, ...restProps } = props
  const label = capitalize(spec.metadata?.label ?? name)
  return <TextField label={label} inputProps={{ ...register(name) }} {...restProps} />
}

export default TextSchemaField
