import { type Entity } from '@tunasong/schemas'
import type { Persisted } from '@tunasong/schemas'

import { type FC } from 'react'
import { TunaBreadcrumbs, type TunaBreadcrumbsProps } from './breadcrumbs.js'
import { useParentChain } from './parent-chain.js'

interface TunaEntityBreadcrumbsProps extends Omit<TunaBreadcrumbsProps, 'parentChain'> {
  entity?: Persisted<Entity>
}

export const TunaEntityBreadcrumbs: FC<TunaEntityBreadcrumbsProps> = props => {
  const { entity, ...restProps } = props

  const parentChain = useParentChain(entity)

  return <TunaBreadcrumbs {...restProps} parentChain={parentChain} />
}
