import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { type MusicContextType } from '@tunasong/music-lib'

// State for the editors
export interface EditorState {
  [id: string]:
    | {
        context: MusicContextType
      }
    | undefined
  default: {
    context: MusicContextType
  }
}

// Define the initial state using that type
const initialState: EditorState = {
  default: {
    context: {},
  },
}

export const editorsSlice = createSlice({
  name: 'editors',
  initialState,
  reducers: {
    setDefaultContext: (state, action: PayloadAction<{ context: Partial<MusicContextType> }>) => {
      Object.assign(state.default.context, action.payload.context)
    },
    setContext: (state, action: PayloadAction<{ id: string; context: Partial<MusicContextType> }>) => {
      const { id, context } = action.payload
      const c = (state[id] = state[id] ?? { context: {} })
      Object.assign(c.context, context)
    },
  },
})
