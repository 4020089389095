/** Content Security Policy */
import { type FC } from 'react'
import { Helmet } from 'react-helmet-async'

export interface CSPProps {
  domainName?: string
}

/**
 * @todo if we want to incrementally add to the CSP in e.g., plugins we need some sort of global state
 */
export interface CSPProps {
  domainName?: string
  previewDomainName?: string
  connectSrc?: string[]
  frameSrc?: string[]
  mediaSrc?: string[]
}

const defaultConnectSrc = [
  'tfhub.dev',
  'storage.googleapis.com',
  'tunasong-storage.s3.eu-west-1.amazonaws.com',
  'https://api.openai.com',
  'https://o526050.ingest.sentry.io',
  'https://sentry.io',
]
const defaultScriptSrc = ['https://o526050.ingest.sentry.io', 'https://sentry.io/api/embed/error-page']
const defaultMediaSrc = ['tunasong-storage.s3.eu-west-1.amazonaws.com']
const defaultFrameSrc = ['*.signatu.com', '*.spotify.com', '*.spotifycdn.com', '*.youtube.com', '*.soundcloud.com']

export const CSP: FC<CSPProps> = ({
  domainName = 'tunasong.com',
  previewDomainName = 'preview.tunasong.com',
  connectSrc = defaultConnectSrc,
  mediaSrc = defaultMediaSrc,
  frameSrc = defaultFrameSrc,
}) => {
  const csp = {
    'default-src': `'self' blob: *.${domainName} *.${previewDomainName}`,
    'script-src': `'self' blob: ${defaultScriptSrc.join(' ')}`,
    'style-src': `'self' 'unsafe-inline' fonts.googleapis.com`,
    'worker-src': `'self' blob:`,
    'child-src': `'self' blob: ${domainName} *.${domainName} ${previewDomainName} *.${previewDomainName}`,
    'media-src': `'self' blob: data: ${domainName} *.${previewDomainName} ${mediaSrc.join(' ')}`,
    'img-src': `* blob: data: ${domainName} *.${previewDomainName} www.google-analytics.com`,
    'font-src': `'self' data: ${domainName} *.${domainName} *.${previewDomainName} fonts.gstatic.com`,
    'connect-src': `'self' *.lambda-url.eu-west-1.on.aws blob: ${domainName} *.${domainName} *.${previewDomainName} wss://ws.api.${domainName} ${connectSrc.join(
      ' '
    )}`,
    'frame-src': `'self' ${domainName}  *.${previewDomainName} ${frameSrc.join(' ')}`,
  }

  const cspContent = Object.entries(csp)
    .map(([key, value]) => `${key} ${value} ;`)
    .join('\n')

  return (
    // Will overwrite the existing CSP key
    <Helmet>
      <meta http-equiv="Content-Security-Policy" content={cspContent} />
    </Helmet>
  )
}
