import { type EdgeType } from '@tunasong/schemas'

import { useCallback, useMemo } from 'react'
import { useEdges } from './edges.hook.js'
import { useEntitiesById } from './entities-by-id.js'
import { type Entity, type Persisted } from '@tunasong/schemas'
import { entitiesApi } from '../api/entities.js'
import invariant from 'tiny-invariant'

/** Load the entities referred by the edges */
export function useEdgeEntities<T extends Entity = Entity, S extends Entity = Entity>({
  sourceId,
  targetId,
  relation,
}: {
  sourceId?: string
  targetId?: string
  relation?: EdgeType
}) {
  const { edges, isLoading: isLoadingEdges } = useEdges({ sourceId, targetId, relation })

  const entityIds = useMemo(() => edges.flatMap(e => [e.source, e.target]), [edges])

  const { entities, isLoading: isLoadingEntities, isSuccess } = useEntitiesById(entityIds)

  const edgeEntities = useMemo(
    () =>
      isSuccess
        ? edges
            .map(edge => ({
              ...edge,
              source: entities.find(e => edge.source === e.id) as Persisted<T>,
              target: entities.find(e => edge.target === e.id) as Persisted<S>,
            }))
            .filter(e => e.source && e.target)
            .sort((a, b) => (a.target.updatedAt > b.target.updatedAt ? -1 : 1))
        : [],
    [edges, entities, isSuccess]
  )

  const [createEdge, createEdgeStatus] = entitiesApi.useCreateEntityEdgeMutation()
  const [deleteEdgeMutation] = entitiesApi.useDeleteEntityEdgeMutation()

  const create = useCallback(
    (targetId: string) => {
      invariant(sourceId && relation, 'Source entity and relation is required')
      return createEdge({ edge: { source: sourceId, target: targetId, relation } }).unwrap()
    },
    [createEdge, relation, sourceId]
  )
  const deleteEdge = useCallback(
    (targetId: string) => {
      invariant(sourceId && relation, 'Source entity and relation is required')
      return deleteEdgeMutation({ source: sourceId, target: targetId, relation })
    },
    [deleteEdgeMutation, relation, sourceId]
  )

  return {
    edgeEntities,
    createEdge: create,
    deleteEdge,
    hasLoaded: isSuccess,
    isCreating: createEdgeStatus.isLoading,
    isLoading: isLoadingEdges || isLoadingEntities,
  }
}
