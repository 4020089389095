import { Button, ButtonGroup, ListItem, ListItemIcon } from '@mui/material'
import { Refresh, RotateLeft, RotateRight } from '@tunasong/icons'
import { isCoreElement } from '@tunasong/schemas'
import { isImage } from '@tunasong/schemas'
import { type FC, useCallback } from 'react'
import invariant from 'tiny-invariant'
import { useEditor } from './hooks/index.js'
import { type RenderMenuItemProps } from './render.js'
import { EditorQueries } from './queries/editor-queries.js'

export const RotateAction: FC<RenderMenuItemProps> = props => {
  const { element, onClose } = props
  const editor = useEditor({ allowNull: true })

  const handleRotate = useCallback(
    (rotationDelta: number) => () => {
      invariant(isCoreElement(element) && isImage(element), `Element ${element.id} is not an image`)
      invariant(editor, `Editor is not set`)
      let rotation = ((element.rotation ?? 0) + rotationDelta) % 360
      if (rotation < 0) {
        rotation += 360
      }
      EditorQueries.updateElement(editor, element, {
        rotation,
      })
    },
    [editor, element]
  )

  if (!editor) {
    return null
  }

  return (
    <ListItem onClick={onClose}>
      <ListItemIcon>{<Refresh />}</ListItemIcon>
      <ButtonGroup size="small">
        <Button onClick={handleRotate(-90)}>
          <RotateLeft />
        </Button>
        <Button onClick={handleRotate(90)}>
          <RotateRight />
        </Button>
      </ButtonGroup>
    </ListItem>
  )
}

export default RotateAction
