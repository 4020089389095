/** An app specific context */

import type { RootState, TunaThunkDispatch, UserConfigService } from '@tunasong/redux'
import type { Entity, Persisted } from '@tunasong/schemas'
import { createContext, useContext } from 'react'
import type { ZodSchema } from 'zod'

export interface DefaultGlobalContext {
  [key: string]: unknown

  /** Config Store */
  configService: UserConfigService

  /** Redux store */
  getState: () => RootState
  /** Redux dispatch */
  dispatch: TunaThunkDispatch

  navigateToEntity: (entity: Persisted<Entity>) => void

  /** @todo factor this into a graph interface */
  graph: {
    updateEntity: <T extends Entity = Entity>(entityId: string, entity: Partial<T>) => void
  }

  prompt: <T extends Record<string, unknown>>({
    schema,
    title,
  }: {
    schema: ZodSchema<T>
    title: string
  }) => Promise<T | null>
}

export const GlobalAppContext = createContext<DefaultGlobalContext | null>(null)

export function useGlobalAppContext<TGlobalAppContext extends DefaultGlobalContext>() {
  const context = useContext(GlobalAppContext)
  if (!context) {
    throw new Error('GlobalAppContext is not set for this application')
  }

  return context as TGlobalAppContext
}
