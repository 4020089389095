import z from 'zod'
/** Command specification */

export const commandSpecSchema = z.object({
  // the commandId for the command
  commandId: z.string(),
  // the parameters for the command
  commandParams: z.record(z.unknown()).optional(),
  // the label to display for the command
  label: z.string().optional(),
})

export type CommandSpec = z.infer<typeof commandSpecSchema>

/** Custom commands are Javascript run in a sandboxed environment */
export const customCommandSchema = commandSpecSchema.extend({
  label: z.string(),
  jsCode: z.string(),
})
export type CustomCommandSpec = z.infer<typeof customCommandSchema>
