import { List } from '@mui/material'
import range from 'just-range'
import { type FC } from 'react'
import { ListItemSkeleton } from './list-item-skeleton.js'

export interface ListSkeletonProps {
  numItems?: number
}

export const ListSkeleton: FC<ListSkeletonProps> = props => {
  const { numItems = 15 } = props
  return (
    <List>
      {range(numItems).map(n => (
        <ListItemSkeleton key={n} />
      ))}
    </List>
  )
}

export default ListSkeleton
