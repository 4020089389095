import { ListItem, ListItemAvatar, ListItemText, Skeleton } from '@mui/material'
import { type FC } from 'react'

export interface ListItemSkeletonProps {}

export const ListItemSkeleton: FC<ListItemSkeletonProps> = () => (
  <ListItem sx={{ padding: 0 }}>
    <ListItemAvatar>
      <Skeleton sx={{ ml: 0.5 }} variant="circular" width={40} height={40} />
    </ListItemAvatar>
    <ListItemText>
      <Skeleton animation="wave" width="100%" height={60} variant="rectangular" />
    </ListItemText>
  </ListItem>
)

export default ListItemSkeleton
