/* eslint-disable no-underscore-dangle */
import { List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material'
import { useAllEntityCommands, type Command } from '@tunasong/plugin-lib'
import type { Entity, EntityCommandSpec, Persisted } from '@tunasong/schemas'
import { type FC } from 'react'
import z from 'zod'
import { CopyToClipboard } from '../util/copy-to-clipboard.js'

export interface CommandsListProps {
  entity?: Persisted<Entity>
}

const getEntityCommandText = (entityId: string, command: Command) => {
  const schema = command.cmdParamsSchema as z.ZodTypeAny

  // Get the command parameters
  const shape = schema?._def.shape()
  const fields = shape ? Object.keys(shape) : []

  const spec: EntityCommandSpec = {
    commandId: command.id,
    entityId,
  }
  if (fields.length > 0) {
    spec.commandParams = fields.reduce(
      (acc, field) => {
        acc[field] = ''
        return acc
      },
      {} as Record<string, string>
    )
  }
  return JSON.stringify(spec, null, 2)
}

export const CommandsList: FC<CommandsListProps> = props => {
  const { entity } = props

  const commands = useAllEntityCommands(entity)

  if (!(entity && commands)) {
    return null
  }

  return (
    <List>
      {commands.map(command => (
        <ListItem key={command.id}>
          <ListItemText primary={command.name} secondary={command.id} />
          <ListItemSecondaryAction>
            <CopyToClipboard text={getEntityCommandText(entity?.id, command)} variant="icon" snackBarText="Copied" />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  )
}

export default CommandsList
