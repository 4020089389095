import { Box, Button, ButtonGroup, TextField, Typography } from '@mui/material'
import { logger } from '@tunasong/models'
import { generateCommandSpecsFromCustomCommand, useEntityCommandsFromSpecs } from '@tunasong/plugin-lib'
import type { CustomCommandSpec, EntityCommandSpec } from '@tunasong/schemas'
import React, { useEffect, useState, type FC } from 'react'
import invariant from 'tiny-invariant'
import type { ZodError } from 'zod'

export interface CommandEditorProps {
  command?: CustomCommandSpec | null
  onChange(command: CustomCommandSpec): void
}

export const CommandEditor: FC<CommandEditorProps> = props => {
  const { command, onChange } = props

  const inputRef = React.useRef<HTMLInputElement>(null)
  const handleSave = () => {
    const value = inputRef.current?.value
    invariant(command, 'command is null')
    invariant(value, 'inputRef.current is null')
    onChange({ ...command, jsCode: value })
  }
  const [error, setError] = useState<ZodError>()
  const [output, setOutput] = useState<EntityCommandSpec[]>()
  const commands = useEntityCommandsFromSpecs(output)

  const handleRun = async () => {
    invariant(commands)
    // Run the commands
    for (const command of commands) {
      if (!command.cmd) {
        continue
      }
      logger.debug('Running command', command.id, command.name)
      await command.cmd()
    }
  }

  const handleValidate = (ev: React.ChangeEvent<HTMLInputElement>) => {
    // Validate that these are valid JS commands
    logger.debug('Validating custom command', ev.target.value)
    const code = ev.target.value
    if (!code) {
      return
    }

    /**
     * Run the custom command in a sandboxed environment
     */
    const result = generateCommandSpecsFromCustomCommand({
      code,
      scope: {
        targetEntity: {},
        cmdEntity: {},
        cmdParams: {},
      },
    })
    if (result.success) {
      setError(undefined)
      setOutput(result.data)
    } else {
      setError(result.error)
      setOutput(undefined)
    }
  }
  // Validate the command on load
  useEffect(() => {
    handleValidate({ target: { value: command?.jsCode ?? '' } } as never)
  }, [command?.jsCode])

  return (
    <Box>
      <Typography variant="caption">
        Custom commands are Javascript run in a sandboxed environment. The following variables are available:
        targetEntity, cmdEntity, cmdParams
      </Typography>
      <TextField
        inputRef={inputRef}
        disabled={!command}
        sx={{ my: 2 }}
        id={`${command?.commandId}-custom-command`}
        // label="Custom Command"
        defaultValue={command?.jsCode}
        error={Boolean(error)}
        inputProps={{
          style: { fontFamily: 'monospace' },
        }} // font size of input text
        onChange={handleValidate}
        multiline
        fullWidth
        minRows={5}
      />
      {Boolean(error) ? (
        <Typography sx={{ my: 2 }} variant="caption" color={'error'} component={'div'}>
          {error?.message}
        </Typography>
      ) : null}
      {Boolean(output) ? (
        <Typography sx={{ my: 2 }} variant="caption" color={'success'} component={'div'}>
          {JSON.stringify(output, null, 2)}
        </Typography>
      ) : null}

      <ButtonGroup fullWidth>
        <Button disabled={!commands} onClick={handleRun}>
          Run commands
        </Button>
        <Button disabled={Boolean(error) || !command} onClick={handleSave}>
          Save
        </Button>
      </ButtonGroup>
    </Box>
  )
}

export default CommandEditor
