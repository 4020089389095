import { useContext } from 'react'
import { type TunaEditor } from '../plugin-types.js'
import { TunaEditorContext } from './editor-context.js'

interface UseEditorProps {
  /**
   * Allow null TunaEditorContext, and do not throw if not present.
   * @default false
   */
  allowNull: boolean
}

export const useEditor = <T extends UseEditorProps>(props?: T) => {
  const { allowNull = false } = props ?? {}
  const editor = useContext(TunaEditorContext)
  if (!allowNull && !editor) {
    throw new Error(`useEditor used outside a TunaEditor context`)
  }
  return editor as T extends { allowNull: true } ? TunaEditor | null : TunaEditor
}
