import { createApi, defaultSerializeQueryArgs } from '@reduxjs/toolkit/query/react'
import { type EntityDownloadUrls } from '@tunasong/models'
import type { StorageUrls } from '@tunasong/schemas'
import { storageSlice } from '../features/storage/storage-slice.js'
import baseQuery from './base-query.js'

// Define a service using a base URL and expected endpoints
export const storageApi = createApi({
  /** We currently have 24 hour validity for the S3 tokens */
  keepUnusedDataFor: 60,
  reducerPath: 'storage-api',

  baseQuery,
  endpoints: builder => ({
    getEntityDownloadUrls: builder.query<EntityDownloadUrls, { entityId: string }>({
      query: ({ entityId }) => `entities/${entityId}/download`,
      onQueryStarted: async ({ entityId }, { dispatch, queryFulfilled }) => {
        dispatch(storageSlice.actions.setLoading({ entityId, loading: true }))
        await queryFulfilled
          .then(result => {
            if (result.data.wildcard) {
              const wildcardAuthQuery = new URL(result.data.wildcard).search
              dispatch(storageSlice.actions.setWildcardAuthQuery({ wildcardAuthQuery }))
            }
          })
          .finally(() => {
            dispatch(storageSlice.actions.setLoading({ entityId, loading: false }))
          })
      },
    }),

    getUploadUrl: builder.query<
      { url: string },
      { filename: string; contentType: string; metadata?: Record<string, string> }
    >({
      query: ({ filename, contentType = 'audio/wav', metadata }) => ({
        url: 'util/upload',
        method: 'POST',
        body: { filename, contentType, metadata },
      }),
    }),
    /** Download the stored file. Requires a wildcard auth query */
    getFileJSON: builder.query<unknown, { storagePath?: string }>({
      query: ({ storagePath }) => ({
        url: `https://tunasong.com/${storagePath}`,
      }),
    }),
    /** Use this with caution as we don't want to crash the browser */
    getAuthorizedStorageFile: builder.query<Uint8Array, { storageUrls: StorageUrls }>({
      // ignore the search/query part of the URL
      serializeQueryArgs: ({ queryArgs: { storageUrls }, endpointDefinition, endpointName }) => {
        if (!storageUrls) {
          return endpointName
        }
        const url = new URL(storageUrls.url)
        return defaultSerializeQueryArgs({
          queryArgs: {
            url: `${url.origin}${url.pathname}`,
          },
          endpointDefinition,
          endpointName,
        })
      },
      query: ({ storageUrls }) => ({
        url: storageUrls.url,

        responseHandler: async response => {
          const buffer = await response.arrayBuffer()
          // We allow up to 10MB files to be downloaded into memory
          if (buffer.byteLength > 10 * 1024 * 1024) {
            throw new Error('File too large to download')
          }
          return new Uint8Array(buffer)
        },
      }),
    }),
    /** signedUrl is the result of getUploadUrl. We cannot use Authorization header for this */
    uploadFile: builder.mutation<void, { signedUrl: string; data: ArrayBuffer; contentType: string }>({
      extraOptions: {
        skipAuthorization: true,
      },
      query: ({ signedUrl, data, contentType }) => ({
        url: signedUrl,
        method: 'PUT',
        headers: {
          'Content-Type': contentType,
        },
        body: data,
      }),
    }),
  }),
})
