import { Box, Typography } from '@mui/material'
import { TreeItem, type TreeItemProps } from '@mui/x-tree-view'
import { Page } from '@tunasong/icons'
import { type ColorName, type Entity, type Persisted } from '@tunasong/schemas'
import cn from 'classnames'
import { forwardRef, type FC } from 'react'
import { type ConnectDragSource } from 'react-dnd'
import { hashColorName, useMainColors } from '../color/index.js'

export interface EntityTreeItemProps extends Omit<TreeItemProps, 'nodeId'> {
  color?: ColorName
  Icon?: FC
  entity: Persisted<Entity>
  open?: boolean
  /** Item is excluded from selection */
  excluded?: boolean
}

export const EntityTreeItem = forwardRef<ReturnType<ConnectDragSource>, EntityTreeItemProps>((props, ref) => {
  const {
    entity,
    Icon = Page,
    color = entity?.type ? hashColorName(entity?.type) : 'grey',
    excluded = false,
    ...other
  } = props
  const { classes: colorClasses } = useMainColors()

  if (!entity) {
    return null
  }

  /** @todo factor out getEntityLabel, etc.  */
  const labelText = entity?.name

  return (
    <TreeItem
      label={
        <Box
          ref={ref}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: theme => theme.spacing(0.5, 0),
            opacity: excluded ? 0.5 : 1,
          }}
        >
          <Icon
            className={cn(colorClasses[color])}
            sx={{ padding: theme => theme.spacing(0, 0.5, 0, 0) }}
            color="inherit"
          />
          <Typography variant="body2">{labelText}</Typography>
        </Box>
      }
      {...other}
    />
  )
})
