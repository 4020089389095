import { z } from 'zod'
import { baseEntitySchema } from '../../core/index.js'

const midiTypes = ['ProgramChange', 'CC'] as const
export type MidiType = (typeof midiTypes)[number]
export const midiTypeSchema = z.enum(midiTypes)

export const midiSchema = baseEntitySchema
  .extend({
    type: z.literal('midi'),
    midiType: midiTypeSchema.optional(),
    channel: z.number().optional(),
  })
  .passthrough()

export const midiProgramChangeSchema = midiSchema.extend({
  midiType: z.literal('ProgramChange'),
  program: z.number(),
  patchBank: z.number().optional(),
  patchBankLsb: z.number().optional(),
  description: z.string().optional(),
})

export type MidiProgramChange = z.infer<typeof midiProgramChangeSchema>

export const midiCCSchema = midiSchema.extend({
  midiType: z.literal('CC'),
  cc: z.number(),
  value: z.number(),
})

export type MidiCC = z.infer<typeof midiCCSchema>

export type Midi = z.infer<typeof midiSchema>

export function isMidi(node: unknown): node is Midi {
  return midiSchema.safeParse(node).success
}

export function isMidiProgramChange(node: unknown): node is MidiProgramChange {
  return isMidi(node) && node.midiType === 'ProgramChange'
}

export function isMidiCC(node: unknown): node is MidiCC {
  return isMidi(node) && node.midiType === 'CC'
}
