import { Button, ButtonGroup, ListItem, ListItemIcon } from '@mui/material'
import { FormatAlignCenter, FormatAlignLeft, FormatAlignRight } from '@tunasong/icons'
import { type Alignment, isAlignable } from '@tunasong/models'
import { isCoreElement } from '@tunasong/schemas'
import { type FC, useCallback } from 'react'
import invariant from 'tiny-invariant'
import { useEditor } from './hooks/index.js'
import { type RenderMenuItemProps } from './render.js'
import { EditorQueries } from './queries/editor-queries.js'

export const AlignAction: FC<RenderMenuItemProps> = props => {
  const { element, onClose } = props
  const editor = useEditor({ allowNull: true })

  const handleAlign = useCallback(
    (align: Alignment) => () => {
      invariant(editor, `Editor is not set`)
      invariant(isCoreElement(element), `Element is not a core element`)
      invariant(isAlignable(element), `Element is not alignable`)
      EditorQueries.updateElement(editor, element, {
        align,
      })
    },
    [editor, element]
  )

  if (!editor || !isAlignable(element)) {
    return null
  }

  const { align } = element

  return (
    <ListItem onClick={onClose}>
      <ListItemIcon>{<FormatAlignCenter />}</ListItemIcon>
      <ButtonGroup size="small">
        <Button disabled={align === 'left'} onClick={handleAlign('left')}>
          <FormatAlignLeft />
        </Button>
        <Button disabled={align === 'center'} onClick={handleAlign('center')}>
          <FormatAlignCenter />
        </Button>
        <Button disabled={align === 'right'} onClick={handleAlign('right')}>
          <FormatAlignRight />
        </Button>
      </ButtonGroup>
    </ListItem>
  )
}

export default AlignAction
