// Generated by ts-to-zod
import { z } from 'zod'

export const audioSourceTypes = ['mic', 'line', 'peer', 'general', 'synth'] as const
export const audioSourceTypeSchema = z.enum(audioSourceTypes)

export type AudioSourceType = (typeof audioSourceTypes)[number]

export const audioTrackSchema = z.object({
  id: z.string(),
  type: audioSourceTypeSchema.optional(),
  name: z.string(),
  gain: z.number().optional(),
  pan: z.number().optional(),
  muted: z.boolean().optional(),
  effects: z.array(z.unknown()).optional(),
})

export type AudioTrack = z.infer<typeof audioTrackSchema>
