import { isHotkey } from '@tunasong/models'
import { useEffect } from 'react'

export type HotkeyHandler = (ev?: KeyboardEvent) => void

/** Register Hotkeys and handlers for the hotkeys. The listeners are registered on the document. To disable, set the second argument */
export const useHotkey = ({
  hotkey,
  handler,
  disabled = false,
}: {
  hotkey: string
  handler?: HotkeyHandler
  disabled?: boolean
}) => {
  useEffect(() => {
    if (disabled) {
      return
    }
    const handleKey = (ev: KeyboardEvent) => {
      if (isHotkey(hotkey)(ev) && handler) {
        ev.preventDefault()
        handler(ev)
      }
    }
    document.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('keydown', handleKey)
    }
  }, [disabled, handler, hotkey])
}
