// Generated by ts-to-zod
import { z } from 'zod'
import { baseEntitySchema } from '../../core/entity.js'

export const audioClipBaseSchema = baseEntitySchema.extend({
  duration: z.number(),
  start: z.number(),
  end: z.number().optional(),
})

export const audioClipSchema = audioClipBaseSchema.extend({
  type: z.literal('audioclip'),
  url: z.string(),
})
export type AudioClip = z.infer<typeof audioClipSchema>

export function isAudioClip(event?: unknown): event is AudioClip {
  return audioClipBaseSchema.safeParse(event).success
}

export const entityClipSchema = audioClipBaseSchema.extend({
  type: z.literal('entityclip'),
  entityId: z.string(),
})
export type EntityClip = z.infer<typeof entityClipSchema>

export function isEntityClip(event?: unknown): event is EntityClip {
  return entityClipSchema.safeParse(event).success
}

export const clipSchema = z.union([audioClipSchema, entityClipSchema])

export type Clip = z.infer<typeof clipSchema>
