import { z } from 'zod'
export const modelsSchema = z.enum([
  'gpt-3.5-turbo',
  'gpt-3.5-turbo-16k',
  'gpt-3.5-turbo-1106',
  'gpt-4',
  'gpt-4-32k',
  'gpt-4-32k-0613',
  'gpt-4-1106-preview', // 128k window
  'gpt-4o',
])

export type GPTModel = z.infer<typeof modelsSchema>
