import { type Meter, meterToString } from './meter.js'
import { tempoToString } from './tempo.js'

export interface Rhythm {
  tempo?: number
  meter?: Meter
}

/** Get the beat interval in milliseconds. If not specified, use 120 BPM */
export const getBeatInterval = (tempo = 120) => {
  /** Time interval */
  tempo = tempo ?? 120
  return (60 / tempo) * 1000
}

/** Rhythm as a string */
export const rhythmToString = (rhythm?: Rhythm) => {
  if (!rhythm) {
    return ''
  }
  const { meter, tempo } = rhythm
  const meterStr = meterToString(meter) ?? ''
  const tempoStr = tempoToString(tempo) ?? ''
  return `${tempoStr} ${meterStr}`
}
