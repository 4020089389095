import * as React from 'react'
import cn from 'classnames'
import useStyles from './icon.styles.js'

function Spotify(props: { className?: string }) {
  const { className, ...restProps } = props
  const { classes } = useStyles()
  return (
    <svg
      className={cn(classes.root, className)}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...restProps}
    >
      <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm4.057 14.406c-.188 0-.282-.094-.47-.188-1.411-.847-3.198-1.317-5.079-1.317-1.035 0-2.163.188-3.104.376-.188 0-.376.094-.47.094a.643.643 0 01-.658-.658c0-.376.188-.658.564-.658 1.223-.282 2.446-.47 3.763-.47 2.163 0 4.139.564 5.832 1.505.188.188.376.282.376.658-.19.47-.472.658-.754.658zm1.034-2.633c-.188 0-.376-.094-.564-.188-1.599-1.035-3.857-1.599-6.302-1.599-1.223 0-2.352.188-3.198.376-.188.094-.282.094-.47.094a.742.742 0 01-.753-.753c0-.47.188-.658.658-.847 1.129-.282 2.258-.564 3.857-.564 2.634 0 5.079.658 7.149 1.881.282.188.47.47.47.753-.094.47-.376.847-.847.847zm1.223-3.011c-.188 0-.282-.094-.564-.188-1.881-1.035-4.609-1.693-7.243-1.693-1.317 0-2.728.188-3.951.47-.188 0-.282.094-.564.094-.564.095-.941-.376-.941-.94s.282-.847.658-.941C7.215 7.188 8.814 7 10.507 7c2.916 0 5.926.658 8.278 1.881.282.188.564.47.564.941-.094.564-.471.94-1.035.94z" />
    </svg>
  )
}

export default Spotify
