import { blueGrey, green, orange } from '@mui/material/colors'
import { alpha, createTheme as muiCreateTheme } from '@mui/material/styles'

/** Theme is defined in ui-lib/styles.ts */

export const createTheme = ({ textSize }: { textSize?: number } = {}) => {
  const muiTheme = muiCreateTheme({
    cssVariables: {
      cssVarPrefix: 'tuna',
      colorSchemeSelector: 'class',
    },

    colorSchemes: {
      dark: {
        palette: {
          background: {
            default: '#101010',
            paper: '#121212',
          },
          layers: alpha('#222222', 0.7),
          secondary: { main: '#e34f82' },
        },
      },
      light: {
        palette: {
          background: {
            default: '#fff',
            paper: '#fff',
          },
          layers: alpha('#fafaf8', 0.7),
          secondary: { main: '#e34f82' },
        },
      },
    },
    notes: {
      main: blueGrey[500],
      outOfScale: orange[500],
      inScale: green[500],
    },

    typography: {
      fontSize: textSize,
      fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoj","Segoe UI Symbol";`,
    },
  })

  muiTheme.typography.caption = {
    ...muiTheme.typography.caption,
  }

  muiTheme.typography.h1 = {
    marginTop: `16px`,
    marginBottom: `8px`,
    fontWeight: 500,
    // color: muiTheme.palette.text.secondary,

    [muiTheme.breakpoints.down('md')]: {
      fontSize: 32,
    },
    [muiTheme.breakpoints.up('md')]: {
      fontSize: 36,
    },
    [muiTheme.breakpoints.up('lg')]: {
      fontSize: 40,
    },
  }
  muiTheme.typography.h2 = {
    marginTop: `16px`,
    marginBottom: `8px`,
    fontWeight: 500,
    [muiTheme.breakpoints.down('md')]: {
      fontSize: 24,
    },
    [muiTheme.breakpoints.up('md')]: {
      fontSize: 28,
    },
    [muiTheme.breakpoints.up('lg')]: {
      fontSize: 30,
    },
  }
  muiTheme.typography.h3 = {
    fontWeight: 500,
    [muiTheme.breakpoints.down('md')]: {
      fontSize: 20,
    },
    [muiTheme.breakpoints.up('md')]: {
      fontSize: 22,
    },
    [muiTheme.breakpoints.up('lg')]: {
      fontSize: 24,
    },
  }
  muiTheme.typography.h4 = {
    fontWeight: 500,
    [muiTheme.breakpoints.down('md')]: {
      fontSize: 18,
    },
    [muiTheme.breakpoints.up('md')]: {
      fontSize: 20,
    },
    [muiTheme.breakpoints.up('lg')]: {
      fontSize: 24,
    },
  }
  muiTheme.typography.h5 = {
    fontWeight: 500,
    [muiTheme.breakpoints.down('md')]: {
      fontSize: 18,
    },
    [muiTheme.breakpoints.up('md')]: {
      fontSize: 20,
    },
    [muiTheme.breakpoints.up('lg')]: {
      fontSize: 22,
    },
  }
  muiTheme.typography.h6 = {
    fontWeight: 500,

    [muiTheme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    [muiTheme.breakpoints.up('md')]: {
      fontSize: 18,
    },
    [muiTheme.breakpoints.up('lg')]: {
      fontSize: 20,
    },
  }
  return muiTheme
}
