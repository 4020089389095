import { logger } from '@tunasong/models'
import { type Entity } from '@tunasong/schemas'
import { useCallback, useRef, useState } from 'react'
import invariant from 'tiny-invariant'
import { type StartKey } from '../api/entities.js'

export type QueryFn<T> = (props: {
  startKey?: string
  limit: number
}) => Promise<{ entities: T[]; resumeKey?: string | null }>

export interface IncrementalOptions<T extends Entity> {
  /** The provided RTK Query hook. Must return the correct shape  */
  onLoad: QueryFn<T> | null

  pageSize: number
  startKey?: StartKey
}

/** Hook to allow incremental loading of entities */
export const useWithIncremental = <T extends Entity = Entity>(props: IncrementalOptions<T>) => {
  const { pageSize, startKey, onLoad } = props

  const [entities, setEntities] = useState<T[]>([])
  const [current, setCurrent] = useState<T[]>([])
  const busy = useRef(false)
  const failed = useRef(false)
  /** When resumeKey is null we are at the end. If it is undefined, we have not loaded anything. */
  const resumeKeyRef = useRef<string | undefined | null>()
  const [isComplete, setIsComplete] = useState(false)

  const getNext = useCallback(async () => {
    invariant(onLoad, 'onLoad is required')
    if (busy.current || resumeKeyRef.current === null || failed.current) {
      logger.debug("Can't load more, already busy, failed or complete. Ignore this in dev with <StrictMode> on", {
        busy: busy.current,
        failed: failed.current,
        resumeKey: resumeKeyRef.current,
      })
      return
    }
    try {
      busy.current = true
      const data = await onLoad({ limit: pageSize, startKey: resumeKeyRef.current ?? undefined })
      const entities = data.entities
      setCurrent(entities)
      setEntities(prev => [...prev, ...entities])
      resumeKeyRef.current = data?.resumeKey ?? null
      if (resumeKeyRef.current === null) {
        setIsComplete(true)
      }
    } catch (e) {
      failed.current = true
      throw e
    } finally {
      busy.current = false
    }
  }, [onLoad, pageSize])

  const resetIncrementalState = useCallback(() => {
    setEntities([])
    setCurrent([])
    resumeKeyRef.current = startKey
    failed.current = false
    busy.current = false
  }, [startKey])

  const addOptimistic = useCallback((entity: T) => {
    setEntities(prev => [entity, ...prev])
  }, [])

  return {
    entities,
    currentPage: current,
    isComplete,
    addOptimistic,
    getNext,
    resetIncrementalState,
  }
}
