import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Alert, NotificationServerEvent } from '@tunasong/models'

// Define a type for the slice state
export interface NotificationsState {
  /** User Alerts */
  alert: Alert | null

  /** The application is busy and should show a fullpage progress bar */
  isBusy: boolean
}

// Define the initial state using that type
const initialState: NotificationsState = {
  alert: null,
  isBusy: false,
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<Alert | null>) => {
      state.alert = action.payload
    },
    clearAlert: state => {
      state.alert = null
    },
    setNotification: (state, action: PayloadAction<NotificationServerEvent>) => {
      state.alert = {
        severity: 'info',
        duration: 30000,
        ...action.payload,
      }
    },
    setBusy: (state, action: PayloadAction<boolean>) => {
      state.isBusy = action.payload
    },
  },
})
