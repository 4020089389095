import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, type BoxProps } from '@mui/material'
import { logger } from '@tunasong/models'
import { useCallback, useEffect } from 'react'
import { useForm, type DefaultValues, type FieldErrors, type FieldValues } from 'react-hook-form'
import { ZodSchema } from 'zod'
import ObjectSchemaField from './form/controls/object.js'
import FormControl from './form/form-control.js'
import { useSchemaForm } from './schema.hook.js'

export interface AutoFormProps<T extends FieldValues> extends Omit<BoxProps, 'onSubmit' | 'onError'> {
  schema: ZodSchema<T>
  defaultValues?: DefaultValues<T>
  /**
   * If submitButton is specified, the form will submit on click. Otherwise, it will be submitted on every change
   * when the form is valid.
   */
  submitButton?: string
  /** Receive a callback when the submit button is clicked with a valid schema */
  onSubmit: (value: T) => void

  onError?: (errors: FieldErrors<T>) => void
}

/** For zod validation, @see https://github.com/react-hook-form/resolvers#zod */
export function AutoForm<T extends FieldValues>(props: AutoFormProps<T>) {
  const { schema, defaultValues = {}, submitButton, onSubmit, onError, ...boxProps } = props

  const mode = submitButton ? 'onSubmit' : 'onChange'
  const form = useSchemaForm(schema)

  const {
    register,
    watch,
    handleSubmit,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    mode,
    shouldFocusError: mode === 'onSubmit',
    resolver: zodResolver(schema),
    defaultValues: defaultValues as DefaultValues<T>,
  })

  const handleChange = useCallback(
    async (values: T) => {
      /** Errors is not updated here... */
      if (!(await trigger())) {
        logger.debug('AutoForm does not valudate...')
        return
      }
      logger.debug('AutoForm onSubmit', values)
      onSubmit(values as T)
    },
    [onSubmit, trigger]
  )

  const handleError = useCallback(
    (errors: FieldErrors<T>) => {
      if (onError) {
        onError(errors)
      }
    },
    [onError]
  )

  /** Submit when mode is onChange and formValues has changed */
  useEffect(() => {
    if (mode !== 'onChange') {
      return
    }
    const { unsubscribe } = watch(async data => {
      handleChange(data as T)
    })

    return () => {
      unsubscribe()
    }
  }, [mode, handleChange, watch, trigger])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...boxProps.sx }} {...boxProps}>
      <form onSubmit={handleSubmit(handleChange, handleError)}>
        <ObjectSchemaField
          name=""
          spec={form}
          register={register}
          FormControl={FormControl}
          control={control}
          errors={errors}
        />
        {submitButton ? <Button type="submit">{submitButton}</Button> : null}
      </form>
    </Box>
  )
}

export default AutoForm
