import type { Persisted, Profile } from '@tunasong/schemas'
import { profilesApi } from '../api/profiles.js'
import { assistantProfile } from './profile-assistant.js'
import { useSelector } from './selector.hook.js'

/** Get the profile for userId id. If not specified, get the profile for the currently logged in user. */
export const useProfile = (id?: string) => {
  const currentUserId = useSelector(state => state.user?.userId)

  const queryId = id === 'PUBLIC' ? undefined : id ?? currentUserId

  const isAssistant = id === 'assistant'

  /** Trigger load of current user, once. Once the loggedIn user is in Redux, the skip guard will prevent performance degradation */
  const { currentData: userProfile } = profilesApi.useLoadProfileQuery(
    { emailOrUserId: queryId ?? '' },
    { skip: !queryId || isAssistant }
  )

  const profile = isAssistant ? assistantProfile : userProfile

  return profile as Persisted<Profile> | undefined
}
