import { Checkbox, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import { Close } from '@tunasong/icons'
import { useProfiles } from '@tunasong/redux'
import { type FC } from 'react'
import { UserAvatar } from './user-avatar.js'

export interface UserListProps {
  userIds: string[]
  checked?: string[]
  size?: 'tiny' | 'small' | 'medium' | 'large'
  onChange?(userIds: string[]): void
  onRemove?(userId: string): void
}

export const UserList: FC<UserListProps> = props => {
  const { size, userIds: users = [], checked = [], onChange, onRemove } = props

  const { profiles, isLoading } = useProfiles({ userIds: users, includeAssistant: false })

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    if (onChange) {
      onChange(newChecked)
    }
  }

  return isLoading ? (
    <LinearProgress />
  ) : (
    <List>
      {profiles?.map(profile => (
        <ListItem
          key={profile.userId}
          secondaryAction={
            <>
              {onChange ? (
                <Checkbox
                  edge="end"
                  onChange={handleToggle(profile.userId)}
                  checked={checked.indexOf(profile.userId) !== -1}
                />
              ) : null}
              {onRemove ? (
                <IconButton onClick={() => onRemove(profile.userId)}>
                  <Close />
                </IconButton>
              ) : null}
            </>
          }
          disablePadding
        >
          <ListItemAvatar>
            <UserAvatar size={size} userId={profile.userId} />
          </ListItemAvatar>
          <ListItemText primary={profile.name} secondary={profile.email} />
        </ListItem>
      ))}
    </List>
  )
}
