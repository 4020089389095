import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { shortUuid, type UserInfo } from '@tunasong/models'

// Define a type for the slice state
export interface PresenceState {
  /** UUID to identify the client session. Unique per session. */
  clientId: string
  /** Presence is keyed on entityId first, senderClientId second */
  activeClients: Record<string, Record<string, UserInfo | undefined> | undefined>
  // userInfo: Record<string, UserInfo>
  /** The active entityId */
  activeEntityId: string | null
}

// Define the initial state using that type
const initialState: PresenceState = {
  clientId: shortUuid(),
  // userInfo: {},

  activeClients: {},
  activeEntityId: null,
}

/**
 * @note if you get an Immer error here, pin the Immer version to ensure a single instance
 * @see https://github.com/reduxjs/redux-toolkit/issues/1181
 */
export const presenceSlice = createSlice({
  name: 'presence',
  initialState,
  reducers: {
    enterEntity: (state, action: PayloadAction<{ userInfo: UserInfo; entityId: string; senderClientId: string }>) => {
      const { userInfo, entityId, senderClientId } = action.payload
      const presMap = state.activeClients[entityId] ?? {}
      presMap[senderClientId] = userInfo

      /** Make sure we create a new object here to trigger a re-render */
      state.activeClients[entityId] = { ...presMap }
    },
    leaveEntity: (state, action: PayloadAction<{ entityId: string; senderClientId: string; userInfo: UserInfo }>) => {
      const { entityId, senderClientId } = action.payload
      const presMap = state.activeClients[entityId] ?? {}
      delete presMap[senderClientId]
    },
    /** Set the specified clients active for the entityId */
    setActiveClientsForEntity: (
      state,
      action: PayloadAction<{ activeClients: { clientId: string; userInfo: UserInfo }[]; entityId: string }>
    ) => {
      const { activeClients, entityId } = action.payload
      const active = state.activeClients[entityId] ?? {}
      for (const { clientId, userInfo } of activeClients) {
        active[clientId] = userInfo
      }
      /** Make sure we create a new object here to trigger a re-render */
      state.activeClients[entityId] = { ...active }
    },
    /** The locally active entity */
    setActiveEntity: (state, action: PayloadAction<{ entityId: string | null }>) => {
      state.activeEntityId = action.payload.entityId
    },
  },
})

export type PresenceEnterEntityMessage = ReturnType<typeof presenceSlice.actions.enterEntity>
export type PresenceLeaveEntityMessage = ReturnType<typeof presenceSlice.actions.leaveEntity>
