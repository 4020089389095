import { useSelector } from '@tunasong/redux'
import { useMemo } from 'react'

const dedupe = <T = unknown>(v: T, i: number, self: T[]) => self.indexOf(v) === i

/** Get the list of userIds present in this room */
export const usePresence = (entityId?: string): string[] => {
  const present = useSelector(state => (entityId ? state.presence.activeClients[entityId] : null))
  /** Dedupe userIds - e.g., for users that have multiple sessions, have reloaded the browser, etc. */
  const userIds = useMemo(
    () =>
      present
        ? (Object.values(present)
            .map(p => p?.userId)
            .filter(dedupe)
            .filter(Boolean) as string[])
        : [],
    [present]
  )
  return userIds
}
