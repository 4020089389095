import { logger } from '@tunasong/models'
import type { Middleware } from 'redux'
import invariant from 'tiny-invariant'
import { entitiesApi } from '../../api/entities.js'
import type { AppDispatch, RootState } from '../../configure-store.js'
import { entitiesSlice } from './entities-slice.js'
import type { Draft } from 'immer'
import type { Entity, Persisted } from '@tunasong/schemas'

/** Sync entities actions with the RTK Query cache */
const middleware: Middleware<
  {}, // Most middleware do not modify the dispatch return value
  RootState
> = storeApi => next => action => {
  const dispatch = storeApi.dispatch as AppDispatch
  if (entitiesSlice.actions.receiveEntity.match(action)) {
    logger.debug('Middleware: Received entity', action.payload.entity)
    const { id, parentId } = action.payload.entity
    dispatch(entitiesApi.util.upsertQueryData('loadEntity', { id }, action.payload.entity))
    /** Now we also need to **update** the parent with the data */
    if (parentId) {
      const updateFn = (draft: Draft<Persisted<Entity>[]>) => {
        /** Filter out existing copy of the entity (if it exists) */
        const existing = draft.filter(e => e.id !== id)
        Object.assign(draft, [...existing, action.payload.entity])
      }
      dispatch(entitiesApi.util.updateQueryData('loadChildEntities', { parentId, networkFirst: false }, updateFn))
      dispatch(entitiesApi.util.updateQueryData('loadChildEntities', { parentId, networkFirst: true }, updateFn))
    }
  } else if (entitiesSlice.actions.receivePartialEntity.match(action)) {
    logger.debug('Middleware: Received partial entity', action.payload)
    invariant(action.payload.id, 'Partial entity must have an id')
    // next(action)
    dispatch(
      entitiesApi.util.updateQueryData('loadEntity', { id: action.payload.id }, draft => {
        Object.assign(draft, action.payload)
      })
    )
    /** @todo we may need to update the loadChildEntities caches as well */
  }
  /** Always apply the action to other reducer(s)  */
  next(action)
}

export default middleware
