import { z } from 'zod'
import { baseEntitySchema } from '../entity.js'
import { coreElementValueSchema } from '../slate/slate-element.js'

export const tableSchema = baseEntitySchema.extend({
  type: z.literal('table'),
  children: coreElementValueSchema,
})

export function isTable(node: unknown): node is z.infer<typeof tableSchema> {
  return tableSchema.safeParse(node).success
}

export type Table = z.infer<typeof tableSchema>

export default tableSchema
