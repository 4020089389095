/** Get the hotkey for the command */

import type { CommandHotkey } from '@tunasong/schemas'
import { useUserConfig } from '../config/config-service.hook.js'

/** Get the hotkey for the command.  */
export const useCmdHotkey = (cmd: string): CommandHotkey | null => {
  const [hotkeys] = useUserConfig('hotkeys')
  const hotkey = hotkeys.find(h => h.commandId === cmd)

  return hotkey ?? null
}
