/** Core entities */
import { baseEntitySchema } from '../entity.js'
import {
  audioFeatureSchema,
  audioSchema,
  isAudio,
  isAudioChordsFeature,
  isAudioFeature,
  isAudioFeatureMap,
  isAudioSeparation,
  type Audio,
  type AudioChordsFeature,
  type AudioFeature,
  type AudioFeatureUrls,
  type AudioMedia,
} from './audio.js'
import { chatSchema, isChat, type Chat } from './chat.js'
import { commentSchema, isComment, type Comment } from './comment.js'
import { entityLinkSchema, isEntityLink, type EntityLink } from './entitylink.js'
import { linkSchema, isLink, type Link } from './link.js'
import { folderSchema, isFolder, type Folder } from './folder.js'
import { imageSchema, isImage, type Image, type ImageElement } from './image.js'
import { isNote, noteSchema, type Note } from './note.js'
import { isPage, pageSchema, type Page } from './page.js'
import { isPdf, pdfSchema, type PDF } from './pdf.js'
import { isProfile, profileSchema, profileServerSchema, type Profile, type ProfileServerSchema } from './profile.js'
import { isTable, tableSchema, type Table } from './table.js'
import { isTodo, todoSchema, type Todo } from './todo.js'
import { isVideo, videoSchema, type Video, type VideoMedia } from './video.js'

/** Exports */

export {
  audioFeatureSchema,
  audioSchema,
  chatSchema,
  commentSchema,
  folderSchema,
  imageSchema,
  isAudio,
  isAudioChordsFeature,
  isAudioFeature,
  isAudioFeatureMap,
  isLink,
  isAudioSeparation,
  isChat,
  isComment,
  isEntityLink,
  isFolder,
  isImage,
  isNote,
  isPage,
  isPdf,
  isProfile,
  isTable,
  isTodo,
  isVideo,
  noteSchema,
  pageSchema,
  pdfSchema,
  profileSchema,
  profileServerSchema,
  tableSchema,
  todoSchema,
  videoSchema,
  type Audio,
  type AudioChordsFeature,
  type AudioFeature,
  type AudioFeatureUrls,
  type AudioMedia,
  type Chat,
  type Comment,
  type EntityLink,
  type Folder,
  type Image,
  type ImageElement,
  type Note,
  type Page,
  type PDF,
  type Profile,
  type Link,
  type ProfileServerSchema,
  type Table,
  type Todo,
  type Video,
  type VideoMedia,
}

const schemas = {
  folder: folderSchema,
  audio: audioSchema,
  page: pageSchema,
  profile: profileSchema,
  image: imageSchema,
  entitylink: entityLinkSchema,
  chat: chatSchema,
  todo: todoSchema,
  comment: commentSchema,
  video: videoSchema,
  pdf: pdfSchema,
  note: noteSchema,
  table: tableSchema,
  link: linkSchema,
  tr: baseEntitySchema,
  th: baseEntitySchema,
  td: baseEntitySchema,
  thead: baseEntitySchema,
  tbody: baseEntitySchema,
}

/** Add new core entity types here */
export const coreEntityNames = Object.keys(schemas) as unknown as readonly (keyof typeof schemas)[]
export type CoreEntityName = (typeof coreEntityNames)[number]

export default schemas
