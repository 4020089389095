import { z } from 'zod'
import { baseEntitySchema } from '../../core/entity.js'
import { colorNameSchema } from '../../lib/color.js'
import { clipSchema } from './audio-clip.js'

export const trackSettingsSchema = z.object({
  gain: z.number(),
  pan: z.number(),
  solo: z.boolean().optional(),
  muted: z.boolean().optional(),
  armed: z.boolean().optional(),
})

export const trackSchema = baseEntitySchema.merge(trackSettingsSchema).extend({
  type: z.literal('audiotrack'),
  parentId: z.string().optional(),
  name: z.string(),
  color: colorNameSchema.optional(),
  height: z.number().optional(),
  clips: z.array(clipSchema).optional(),
  settings: trackSettingsSchema.optional(),
})

export type Track = z.infer<typeof trackSchema>

export function isAudioTrack(node?: unknown): node is Track {
  return trackSchema.safeParse(node).success
}
