import { useCallback, useEffect, useRef, useState } from 'react'
import { useElementRef } from '../hooks/element-ref.js'

/**
 * Control scrolling of a feed. Supports setting ref in the URL to highlight elements in context.
 *
 * Set the ref for elements that have id === activeId - e.g.,
 *     <div
 *         ref={item.id === activeId ? focusRef : null}
 *       >
 */

export const useScroll = <T extends HTMLElement = HTMLDivElement>(items: { id: string }[]) => {
  /** Set the focusId to either the last element or the highlighted element */
  const startItemCount = useRef<number>(0)
  useEffect(() => {
    if (startItemCount.current > 0) {
      return
    }
    startItemCount.current = items.length
  }, [items.length])

  /** Highlighed element (if any) */
  const highlightId = useElementRef()
  const [activeId, setActiveId] = useState<string>()
  useEffect(() => {
    /** activeId is highlightId by default, unless new items have been added */
    const active = items.length === startItemCount.current && highlightId ? highlightId : items[items.length - 1]?.id
    setActiveId(active)
  }, [highlightId, items])

  const focusRef = useRef<T | null>(null)

  const handleFocusRef = useCallback((ref: T) => {
    focusRef.current = ref
    if (!ref) {
      return
    }
    ref.scrollIntoView()
  }, [])

  return { focusRef: handleFocusRef, activeId, highlightId }
}
