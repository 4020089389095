import { Box, type BoxProps } from '@mui/material'

import cn from 'classnames'
import React from 'react'
import { makeStyles } from '../styles.js'
import { useUserConfig } from '../config/config-service.hook.js'

const useStyles = makeStyles<{ size: 'small' | 'medium' | 'large'; showColumns: boolean }>({ name: 'content-layout' })(
  (theme, { size, showColumns }) => ({
    root: {
      fontSize: size === 'small' ? '0.8rem' : size === 'medium' ? '1rem' : '1.2rem',
      flex: 1,
      overflow: 'auto',
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
      [theme.breakpoints.up('sm')]: {
        margin: 'auto',
        width: 'min(1024px, 100%)',
        padding: theme.spacing(2, 4, 2, 4),
      },
    },

    paper: {
      display: 'block',
      /** @see // @see https://www.smashingmagazine.com/2019/01/css-multiple-column-layout-multicol/ */
      ...(showColumns
        ? {
            columnWidth: 400,
            columnCount: 'auto',
            columnGap: '1em',
            columnRule: `1px solid ${theme.vars.palette.secondary.light}`,
            columnFill: 'balance',
          }
        : {}),

      padding: theme.spacing(1, 1, 1, 1),

      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  })
)

export interface ContentLayoutProps extends Omit<BoxProps, 'component'> {
  className?: string
  size: 'small' | 'medium' | 'large'
}

export const ContentLayout = React.forwardRef((props: ContentLayoutProps, ref) => {
  const { className, children, size = 'large', ...restProps } = props
  const [showColumns] = useUserConfig('showContentColumns')
  const { classes } = useStyles({ size, showColumns })

  return (
    <Box ref={ref} className={cn(className, classes.root)} {...restProps}>
      <Box className={classes.paper}>{children}</Box>
    </Box>
  )
})

export default ContentLayout
