import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from './base-query.js'

export const clientApi = createApi({
  reducerPath: 'client-api',
  baseQuery,
  endpoints: builder => ({
    getUrlPreview: builder.query<{ icon?: string; title: string; description?: string }, { url: string }>({
      query: ({ url }) => `client-api/url-preview?url=${encodeURIComponent(url)}`,
    }),
  }),
})
