import type { RootState } from '@tunasong/redux'
import type { EntityOrElement, Persisted } from '@tunasong/schemas'

export type LayoutFilter<TElement extends EntityOrElement = EntityOrElement> = (
  element: Persisted<TElement>,
  state: RootState
) => boolean

/** Show only if the authenticated user is the owner of the element */
const ownerLayoutFilter: LayoutFilter = (element, state) => element.userId === state.user.userId

export const LayoutFilters = {
  owner: ownerLayoutFilter,
}
