import { getEntityTypeFromMime, shortUuid } from '@tunasong/models'

interface ObjectItem {
  name: string
  userId: string
  mimeType: string
}

export const createObjectPath = (item: ObjectItem) =>
  `users/${item.userId}/${getEntityTypeFromMime(item.mimeType)}/${shortUuid()}`
