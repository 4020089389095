import { z } from 'zod'

/** Relationship types */

export const edgeTypes = [
  'follows',
  'likes',
  'mentions',
  'embeds',
  'comments',
  'linksTo',
  'includes',
  'hasRecording',
  'member',
  'isParentOf',
  'isRelatedTo',
  'calendar',
] as const
export type EdgeType = (typeof edgeTypes)[number]

export const edgeContext = z.object({
  // entity type as string
  type: z.string(),
  name: z.string().optional(),
  id: z.string(),
})

export const edgesSchema = z.object({
  source: z.string(),
  relation: z.enum(edgeTypes),
  target: z.string(),
  /**
   * Context is the ID of the context entity where the relation happens. Typically not defined,
   * but the standard case is 'mentions' between two users in the context of a song/page entity
   */
  context: edgeContext.optional(),
  updatedAt: z.string().datetime().optional(),
})

export const edgesSchemaWithUser = edgesSchema.extend({
  userId: z.string().optional(),
})

export const edgesSchemaResult = edgesSchemaWithUser.extend({
  /** Metadata */
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
})

/** The schema of the actual storage - for internal use only */
export type Edge = z.infer<typeof edgesSchema>
export type EdgesSchemaWithUser = z.infer<typeof edgesSchemaWithUser>
export type EdgesSchemaWithMeta = z.infer<typeof edgesSchemaResult>
