import { type CoreElement } from '@tunasong/schemas'

/** A facade is a "wrapper" for a real entity that is not available, e.g., due to ACLs */
export interface Facade extends CoreElement {
  id: string
  type: 'facade'
  description: string
}

export function isFacade(node: unknown): node is Facade {
  return Boolean((node as CoreElement)?.type === 'facade')
}
