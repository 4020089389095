import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { Add, MoreVertIcon } from '@tunasong/icons'
import { matchAny } from '@tunasong/models'
import { useChildren } from '@tunasong/redux'
import {
  isEntityLink,
  isLink,
  type Entity,
  type EntityLink as EntityLinkType,
  type Link as LinkType,
  type Persisted,
} from '@tunasong/schemas'
import React, { useState, type FC } from 'react'
import { EntityLink } from '../entity/link.js'
import BookmarkDialog from './bookmark-dialog.js'

export interface BookmarksButtonProps {
  parent: Persisted<Entity>
}

export const BookmarksButton: FC<BookmarksButtonProps> = props => {
  const { parent } = props
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const handleOpen = (ev: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(ev.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const { entities: bookmarks } = useChildren<LinkType | EntityLinkType>({
    parentId: parent.id,
    filter: matchAny(isLink, isEntityLink),
  })

  const [showDialog, setShowDialog] = useState<'internal' | 'external' | false>(false)

  return (
    <>
      <Button endIcon={<MoreVertIcon />} color="primary" onClick={handleOpen} aria-label="Bookmarks Dropdown Button">
        Bookmarks
      </Button>
      <Menu anchorEl={anchorEl} keepMounted={false} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            setShowDialog('internal')
          }}
        >
          <ListItemIcon>
            <Add />
          </ListItemIcon>
          <ListItemText primary={'Internal bookmark...'} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            setShowDialog('external')
          }}
        >
          <ListItemIcon>
            <Add />
          </ListItemIcon>
          <ListItemText primary={'External bookmark...'} />
        </MenuItem>
        <MenuItem divider disabled />
        {bookmarks.map(bookmark => (
          <MenuItem key={bookmark.id}>
            <EntityLink key={bookmark.id} entity={bookmark} />
          </MenuItem>
        ))}
      </Menu>
      {showDialog ? (
        <BookmarkDialog type={showDialog} open={true} parent={parent} onClose={() => setShowDialog(false)} />
      ) : null}
    </>
  )
}

export default BookmarksButton
