/** A device control, e.g., pot */
import { z } from 'zod'

export const DeviceControlTypeValues = ['Switch', 'Pot', 'Preset'] as const
export type DeviceControlType = (typeof DeviceControlTypeValues)[number]

export const deviceControlSchema = z.object({
  type: z.enum(DeviceControlTypeValues),
  name: z.string(),
  /** Controlled by MIDI CC number */
  midiCC: z.number().optional(),
  value: z.union([z.boolean(), z.number()]).optional(),
})

export type DeviceControl = z.infer<typeof deviceControlSchema>

export interface DeviceControlSwitch extends DeviceControl {
  type: 'Switch'
  value?: boolean
}

export interface DeviceControlPot extends DeviceControl {
  type: 'Pot'
  value?: number
}

export interface DeviceControlPreset extends DeviceControl {
  type: 'Preset'
}

export const isPreset = (control: DeviceControl): control is DeviceControlPreset => control.type === 'Preset'
export const isPot = (control: DeviceControl): control is DeviceControlPot => control.type === 'Pot'
export const isSwitch = (control: DeviceControl): control is DeviceControlSwitch => control.type === 'Switch'
