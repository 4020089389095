import { type CoreElement, type Entity, isEntity } from '@tunasong/schemas'
import { entityTemplate } from '../element/templates.js'

export const noteTypes = ['general', 'performance', 'instrument'] as const
export type NoteType = (typeof noteTypes)[number]

export interface Note extends Entity, CoreElement {
  type: 'note'
  parentId: string
  noteType?: NoteType
}

export const noteTemplate = ({
  parentId,
  noteType = 'general',
  id,
}: {
  parentId: string
  noteType?: NoteType
  id?: string
}): Note => ({
  ...entityTemplate,
  id,
  type: 'note',
  children: [{ type: 'line', children: [{ text: '' }] }],
  parentId,
  noteType,
})

export function isNote(node?: unknown): node is Note {
  return Boolean(isEntity(node) && node.type === 'note')
}
