// import logo from '@tunasong/media/logo/tuna-logo.png'
import type { Persisted, Profile } from '@tunasong/schemas'

export const assistantProfile: Persisted<Profile> = {
  id: 'assistant',
  acls: [],
  userId: 'assistant',
  name: 'Tunabrain',
  // picture: logo,
  email: 'brain@tunasong.com',
  config: {},
  type: 'profile',
  quotaUsed: 0,

  updatedAt: new Date().toISOString(),
  createdAt: new Date().toISOString(),
}
