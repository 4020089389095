/**
 * @note storing JWTs in localStorage is not safe.
 * As a kludge, we encrypt it, so at least people must go the extra length to get to the data:
 *
 * 1. Compromise the site
 * 2. Load all localStorage data to a adversary site
 * 3. Figure out the data is encrypted.
 * 4. Reverse engineer the encryption scheme from minimized Javascript
 *
 * A government entity will do this easily. The others - probably not.
 */

const SESSION_KEY = 'tunasong.auth.session'

import { encrypt, decrypt } from './aes.js'
import { type AuthData } from './auth-data.js'

export const setJWT = (auth: AuthData) => {
  const jwt = JSON.stringify(auth)
  const data = encrypt(jwt)
  localStorage.setItem(SESSION_KEY, data)
}

/** Remove local JWT values */
export const clearJWT = () => {
  localStorage.removeItem(SESSION_KEY)
}

export const getJWT = (): AuthData | null => {
  const cipher = localStorage.getItem(SESSION_KEY)
  let jwt: AuthData | null = null
  try {
    jwt = cipher ? (JSON.parse(decrypt(cipher)) as AuthData) : null
  } catch {
    jwt = null
  }
  return jwt
}
