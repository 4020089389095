import { z } from 'zod'
import { baseEntitySchema } from '../../core/entity.js'

export const audioCommentSchema = baseEntitySchema.extend({
  type: z.literal('audiocomment'),
  parentId: z.string(),
  userId: z.string().describe('User id of commenter'),
  start: z.number().describe('Start time in seconds'),
  end: z.number().describe('End time in seconds').optional(),
  // Slate doc, but for now only with a single text node
  children: z.array(z.object({ text: z.string() })).length(1),
})

export type AudioComment = z.infer<typeof audioCommentSchema>

export function isAudioComment(node?: unknown): node is AudioComment {
  return audioCommentSchema.safeParse(node).success
}
