import { type EntityOrElement } from '@tunasong/schemas'
import { useMemo } from 'react'
import { getPlugin } from '../plugin/index.js'
import { usePlugins } from './editor-plugins.js'

export const usePlugin = (element?: EntityOrElement | null) => {
  const plugins = usePlugins('all')

  return useMemo(() => (element && plugins ? getPlugin(element, plugins) : undefined), [element, plugins])
}
