import type { Audio, AudioEvent, AudioFeatureType } from '@tunasong/schemas'
import { storageApi } from '../api/storage.js'

export const useFeature = <T extends AudioEvent = AudioEvent>(media: Audio | undefined, feature: AudioFeatureType) => {
  const storagePath = media?.features?.[feature]?.data

  const { currentData = undefined } = storageApi.useGetFileJSONQuery({ storagePath }, { skip: !storagePath })

  return (currentData ? currentData : undefined) as T[] | undefined
}
