import { FileCopyOutlined as CopyIcon } from '@mui/icons-material'
import { Alert, Button, IconButton, Snackbar } from '@mui/material'
import { type FC, useCallback, useState } from 'react'

export interface CopyToClipboardProps {
  text?: string
  variant?: 'button' | 'icon'
  size?: 'small' | 'large' | undefined
  title?: string
  snackBarText?: string
}

export const CopyToClipboard: FC<CopyToClipboardProps> = props => {
  const {
    text = '',
    variant = 'button',
    snackBarText = `Copied to clipboard`,
    size,
    title = 'Copy to clipboard',
  } = props
  const [showSnackbar, setShowSnackbar] = useState(false)

  const hideSnackbar = useCallback(() => setShowSnackbar(false), [])

  const copyToClipboard = useCallback(async () => {
    // https://developers.google.com/web/updates/2018/03/clipboardapi
    try {
      await navigator.clipboard.writeText(text)
    } catch (err) {
      // Fallback to old mechanism
      const el = document.createElement('textarea')
      el.value = text
      document.body.appendChild(el)
      el.select()
      el.setSelectionRange(0, 99999)
      document.execCommand('copy')
      document.body.removeChild(el)
    }

    setShowSnackbar(true)
  }, [text])

  if (!text) {
    return null
  }

  return (
    <>
      {variant === 'button' && (
        <Button size={size} onClick={copyToClipboard} title={title}>
          Copy to clipboard
        </Button>
      )}
      {variant === 'icon' && (
        <IconButton color="inherit" onClick={copyToClipboard} title={title}>
          <CopyIcon fontSize={size} />
        </IconButton>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        color="primary"
        open={showSnackbar}
        autoHideDuration={100000}
        onClose={hideSnackbar}
      >
        <Alert severity="success">{snackBarText}</Alert>
      </Snackbar>
    </>
  )
}
