import { z } from 'zod'
import { baseEntitySchema } from '../../core/entity.js'

export const pollResponseSchema = baseEntitySchema.extend({
  type: z.literal('pollresponse'),
  pollType: z.enum(['one-of-many', 'many-of-many']).default('many-of-many'),

  /** Choices for many of many  */
  choices: z.record(z.string(), z.boolean()).optional(),
  /** choice for one of many */
  choice: z.number().optional(),
})

export const manyOfManyPollResponseSchema = pollResponseSchema.extend({
  pollType: z.literal('many-of-many'),
  /** Choices for many of many  */
  choices: z.record(z.string(), z.boolean()),
  choice: z.undefined(),
})

export const oneOfManyPollResponseSchema = pollResponseSchema.extend({
  pollType: z.literal('one-of-many'),
  /** The single choice made  */
  choice: z.number(),
  choices: z.undefined(),
})

export type ManyOfManyPollResponse = z.infer<typeof manyOfManyPollResponseSchema>
export type OneOfManyPollResponse = z.infer<typeof oneOfManyPollResponseSchema>

export type PollResponse = z.infer<typeof pollResponseSchema>

export function isPollResponse(node?: unknown): node is PollResponse {
  return pollResponseSchema.safeParse(node).success
}

export function isManyOfManyPollResponse(node?: unknown): node is ManyOfManyPollResponse {
  return manyOfManyPollResponseSchema.safeParse(node).success
}

export function isOneOfManyPollResponse(node?: unknown): node is OneOfManyPollResponse {
  return oneOfManyPollResponseSchema.safeParse(node).success
}
