/** Are we running in dev? */
const url = typeof document !== 'undefined' ? document.location?.href : null

const ngrokDomain = 'directly-honest-donkey.ngrok-free.app'
// Check if the domain matches https://<sha>.preview.<domain>
const previewHost = url?.match(/^https:\/\/([^.]+\.preview\.[^\/]+)/)?.[1]
const isDev = Boolean(url?.startsWith('http://localhost'))
const isNgrok = Boolean(url?.startsWith(`https://${ngrokDomain}`))

/** @note - this is the aws-config object for CDK. We keep it here because other packages also depend on e.g., userPoolId config */
const config = {
  aws_project_region: 'eu-west-1',
  region: 'eu-west-1',
  bucketName: 'tunasong-storage',
  Auth: {
    userPoolId: 'eu-west-1_DQAVtgHXU',
    userPoolWebClientId: '4vviqhoonv2sbcf0lqmeufr7bb',
    oauth: {
      domain: 'auth.tunasong.com',
      scope: ['phone', 'email', 'profile', 'openid'],
      loginProxy: 'https://tunasong.com/api/auth/login-proxy',
      redirectSignIn: isDev
        ? `http://localhost:8000/app/login`
        : previewHost
          ? `https://${previewHost}/app/login`
          : isNgrok
            ? `https://${ngrokDomain}/app/login`
            : 'https://tunasong.com/app/login',
      logoutProxy: 'https://tunasong.com/api/auth/logout-proxy',
      redirectSignOut: isDev
        ? `http://localhost:8000/app/logout`
        : previewHost
          ? `https://${previewHost}/app/logout`
          : isNgrok
            ? `https://${ngrokDomain}/app/logout`
            : 'https://tunasong.com/app/logout',
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
  /** Custom config */
  url: isDev ? 'https://localhost:8000' : 'https://tunasong.com',
}

export default config
