import { z } from 'zod'

export const sortOptionsSchema = z.object({
  /** Type of sort - either by `field`, or explicit order */
  type: z.enum(['field', 'order']).optional(),
  sortBy: z.union([z.string(), z.number()]).optional(),
  order: z.enum(['asc', 'desc']).optional(),
  /** Default is to show starred items first. If ignoreStars is set, stars are ignored */
  ignoreStars: z.boolean().optional(),
  /** Sort order of elements when using `order` sort, as a list  `id`s */
  elementOrder: z.array(z.string()).optional(),
})
