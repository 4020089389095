import { z } from 'zod'
import { baseEntitySchema } from '../entity.js'
import { coreElementValueSchema } from '../slate/slate-element.js'

export const commentSchema = baseEntitySchema.extend({
  type: z.literal('comment'),
  userId: z.string(),
  resolved: z.boolean().default(false).optional(),
  children: coreElementValueSchema,
})

export type Comment = z.infer<typeof commentSchema>

export function isComment(node: unknown): node is Comment {
  return commentSchema.safeParse(node).success
}
