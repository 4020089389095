import { type ElementType } from '@tunasong/schemas'
import { useMemo } from 'react'
import { getElementMedia } from '../plugin/plugin-util.js'
import { usePlugins } from './editor-plugins.js'

export const useElementTypeMedia = (type?: ElementType) => {
  const plugins = usePlugins('all')
  const media = useMemo(() => getElementMedia(type, plugins), [type, plugins])
  return media
}
