import { Card, CardHeader } from '@mui/material'
import { type FC } from 'react'
import { useProfile } from '@tunasong/redux'
import { makeStyles } from '../styles.js'
import { UserAvatar } from './user-avatar.js'

const useStyles = makeStyles()(() => ({
  root: {},
  avatar: { alignItems: 'normal' },
}))
export interface ProfileCardProps {
  userId: string
}

export const ProfileCard: FC<ProfileCardProps> = props => {
  const { userId } = props
  const { classes } = useStyles()
  const user = useProfile(userId)
  const title = user?.name ?? user?.email
  const subheader = user?.name ? user?.email : ''
  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader
        avatar={<UserAvatar className={classes.avatar} userId={userId} />}
        title={title}
        subheader={subheader}
      />
    </Card>
  )
}
export default ProfileCard
