/** The properties that all Element types have in common. It is called BaseElement to avoid confusion with DOM elements and Slate Elements */

import { BLOCK_ELEMENTS, isCoreElement, type BlockType, type CoreElement } from '@tunasong/schemas'

export interface BlockElement extends CoreElement {
  type: BlockType
}

export function isBlock(element?: unknown): element is BlockElement {
  return Boolean(isCoreElement(element) && element.type && BLOCK_ELEMENTS.includes(element.type as never))
}
