import { Typography } from '@mui/material'
import { TreeItem as MuiTreeItem, SimpleTreeView } from '@mui/x-tree-view'
import { ArrowDropDown, ArrowRight } from '@tunasong/icons'
import { folderSort, isTopLevelEntity, type FilterFunc } from '@tunasong/models'
import { getElementMedia, usePlugins } from '@tunasong/plugin-lib'
import { entitiesApi, useAncestors, useStore, useThunkDispatch } from '@tunasong/redux'
import { type Entity, type Persisted } from '@tunasong/schemas'
import { useCallback, useEffect, useMemo, useState, type FC } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { EMPTY_NODE } from './constants.js'
import { EntityTreeItem, type EntityTreeItemProps } from './entity-tree-item.js'
import { LOADING_NODE } from './constants.js'

export interface EntityTreeProps {
  /**  */
  includeRoot?: boolean
  rootEntities: Persisted<Entity>[]
  /** @default show all top-level entities */
  filter?: FilterFunc
  /** Entity Ids that should not be selectable */
  disableIds?: string[]
  /** Selected item */
  selected: Persisted<Entity> | undefined
  TreeItem?: FC<EntityTreeItemProps>
  onSelect(entity?: Persisted<Entity>): void
  onSelectRoot?(): void
}

export const EntityTree: FC<EntityTreeProps> = props => {
  const {
    includeRoot = true,
    rootEntities,
    filter = isTopLevelEntity,
    selected,
    onSelectRoot,
    onSelect,
    TreeItem = EntityTreeItem,
    disableIds,
    ...restProps
  } = props

  const [expanded, setExpanded] = useState<string[]>(['ROOT'])
  const plugins = usePlugins('all')

  /** We use the result here to ensure we refresh the UI on changes */
  const [loadChildEntities] = entitiesApi.useLazyLoadChildEntitiesQuery()

  const { getState } = useStore()
  const [loadEntity] = entitiesApi.useLazyLoadEntityQuery()
  const getEntityChildren = useCallback(
    (parentId: string) => entitiesApi.endpoints.loadChildEntities.select({ parentId, networkFirst: true })(getState()),
    [getState]
  )

  /** Ensure that all parents of selected item are expanded */
  const ancestors = useAncestors(selected)
  const allExpanded = useMemo(
    () => [
      ...new Set([
        ...expanded,
        /* selected?.id, */ ...Object.values(ancestors)
          .map(e => e.id)
          .filter(Boolean),
      ]),
    ],
    [ancestors, expanded]
  ) as string[]

  const dispatch = useThunkDispatch()
  /** Always load the root, expanded, and disabledIDs. We need to load disabled because expanding will not be possible  */
  useEffect(() => {
    const rootIds = [...rootEntities.map(e => e.id), ...(disableIds ?? [])]
    for (const nodeId of rootIds) {
      loadChildEntities({ parentId: nodeId, networkFirst: true })
    }
  }, [disableIds, dispatch, loadChildEntities, rootEntities])

  const getChildren = useCallback(
    (entity: Persisted<Entity>) => {
      /** @note to ensure that we re-render when we get new data, we add a (fake) dependency on loadChildEntitiesResult */
      /** @todo does this use the current data? */
      const { data: entityChildren = [] } = getEntityChildren(entity.id)
      if (entityChildren.length === 0) {
        /** @note if children is null then the tree will not have an expand button */
        return null // isLoading ? <EntityTreeItemLoading /> : null
      }

      const children = entityChildren
        .filter(e => e.parentId === entity.id)
        .filter(filter)
        .sort()
      const e = folderSort(children.filter(filter))
      return e.map(child => {
        const { icon, materialColor } = getElementMedia(child.type, plugins)
        return (
          <TreeItem
            itemId={child.id}
            key={child.id}
            entity={child}
            /** We open up for disabled items */
            open={disableIds?.includes(child.id) || child === selected}
            excluded={disableIds?.includes(child.id)}
            Icon={icon}
            color={materialColor}
          >
            {getChildren(child)}
          </TreeItem>
        )
      })
    },
    [TreeItem, disableIds, filter, getEntityChildren, plugins, selected]
  )

  const handleToggle = useCallback(
    async (ev: unknown, itemId: string) => {
      const allExpanded = [...new Set([...expanded, itemId])].filter(Boolean)
      setExpanded(allExpanded)
      for (const nodeId of allExpanded) {
        const children = await loadChildEntities({ parentId: nodeId, networkFirst: true }).unwrap()
        /** Load the children of the children as well */
        children.forEach(async child => loadChildEntities({ parentId: child.id, networkFirst: true }))
      }
    },
    [expanded, loadChildEntities]
  )

  const handleSelect = useCallback(
    async (ev: unknown, itemId: string | null) => {
      /** Loading nodes have  */
      const ignoreNodes = [LOADING_NODE, EMPTY_NODE, ...(disableIds ?? [])]
      if (itemId === 'ROOT' && onSelectRoot) {
        onSelectRoot()
        return
      }
      if (onSelect && itemId) {
        const { data: entity } = await loadEntity({ id: itemId })
        onSelect(ignoreNodes.includes(itemId) ? undefined : entity)
      }
    },
    [disableIds, loadEntity, onSelect, onSelectRoot]
  )

  /** @note we don't memo the tree-items because we can receive new data at any time  */

  const items = (
    <>
      {includeRoot && (
        <MuiTreeItem key={'root'} itemId={'ROOT'} label={<Typography variant="subtitle1">Top</Typography>} />
      )}
      {folderSort(rootEntities.filter(filter)).map(entity => {
        const { icon, materialColor: color } = getElementMedia(entity.type, plugins)
        return (
          <TreeItem
            key={entity.id}
            itemId={entity.id}
            entity={entity}
            open={Boolean(disableIds?.includes(entity.id) || (entity && allExpanded.find(id => id === entity.id)))}
            excluded={disableIds?.includes(entity.id)}
            Icon={icon}
            color={color}
          >
            {getChildren(entity)}
          </TreeItem>
        )
      })}
    </>
  )

  return (
    <DndProvider backend={HTML5Backend}>
      <SimpleTreeView
        onItemSelectionToggle={handleToggle}
        onSelectedItemsChange={handleSelect}
        expandedItems={expanded}
        selectedItems={selected?.id ?? ''}
        slots={{
          collapseIcon: ArrowDropDown,
          expandIcon: ArrowRight,
        }}
        /** we don't use multiselect yet */
        {...restProps}
      >
        {items}
      </SimpleTreeView>
    </DndProvider>
  )
}

export default EntityTree
