/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from '../styles.js'

export const useStyles = makeStyles()(theme => ({
  root: {
    padding: 0,
  },

  button: {
    padding: theme.spacing(0.5),
    margin: 0,
  },
  avatar: {
    color: theme.vars.palette.grey[100],
  },
  avatarTiny: {
    minWidth: theme.spacing(4),
  },
  primarySmall: {
    fontSize: 12,
  },
  secondarySmall: {
    fontSize: 10,
  },
  text: {
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  primaryTiny: {
    fontSize: 10,
  },
  secondaryTiny: {
    fontSize: 8,
  },
}))

export default useStyles
