// Need to use the React-specific entry point to import createApi
import { createApi } from '@reduxjs/toolkit/query/react'
import type { AudioRequest, ImageObject } from '@tunasong/models'
import { isS3Storage, type Audio, type Persisted } from '@tunasong/schemas'
import invariant from 'tiny-invariant'
import { notificationsSlice } from '../features/notifications/notifications-slice.js'
import baseQuery from './base-query.js'

// Define a service using a base URL and expected endpoints
export const mediaApi = createApi({
  reducerPath: 'media-api',
  baseQuery,
  endpoints: builder => ({
    analyzeAudio: builder.query<void, Persisted<Audio>>({
      query: ({ storage, userId, id: mediaId }) => {
        invariant(isS3Storage(storage), `Cannot analyze audio without a storage filename specification`)
        const audioFilePath = storage.filename

        const body: AudioRequest = {
          audioFilePath,
          userId,
          mediaId,
        }
        return {
          url: `audio/analyze`,
          method: 'POST',
          body,
        }
      },
      onQueryStarted: (_, { dispatch }) => {
        dispatch(
          notificationsSlice.actions.setAlert({
            severity: 'info',
            title: 'Analyzing audio',
            message: 'This may take a few minutes...',
          })
        )
      },
    }),
    analyzeImage: builder.query<ImageObject[], { imageUrl: string; confidence?: number }>({
      query: ({ imageUrl: url, confidence = 0.25 }) => ({
        url: `ai/analyze-image`,
        method: 'POST',
        body: { url, confidence },
      }),
    }),
  }),
})
