import { Button, type ButtonProps } from '@mui/material'
import { Prev } from '@tunasong/icons'

import cn from 'classnames'
import { type FC, type MouseEvent, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDimensions } from '../layout/dimensions.js'
import { makeStyles } from '../styles.js'

export interface BackButtonProps extends ButtonProps {
  className?: string
}

const useStyles = makeStyles()(theme => ({
  icon: {
    marginRight: theme.spacing(0.5),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    '& :hover': {
      cursor: 'pointer',
    },
  },
}))

export const BackButton: FC<BackButtonProps> = props => {
  const { className, children, ...boxProps } = props
  const { classes } = useStyles()
  const { isSmallOrSmaller } = useDimensions()

  const history = window?.history

  const hasHistory = history?.length > 1

  const navigate = useNavigate()
  const navBack = useCallback(
    (ev: MouseEvent) => {
      ev.preventDefault()
      navigate(-1)
    },
    [navigate]
  )

  return (
    <Button
      size="small"
      aria-label="Back button"
      disabled={!hasHistory}
      onClick={navBack}
      color="inherit"
      className={cn(className, classes.link)}
      {...boxProps}
    >
      <Prev color="inherit" className={classes.icon} />
      {!isSmallOrSmaller ? children : null}
    </Button>
  )
}

export default BackButton
