import { z } from 'zod'
import { baseEntitySchema } from '../../core/entity.js'

export const calendarSchema = baseEntitySchema.extend({
  type: z.literal('calendar'),
})
export type Calendar = z.infer<typeof calendarSchema>

export function isCalendar(node?: unknown): node is Calendar {
  return calendarSchema.safeParse(node).success
}

const attendeeSchema = z.object({
  name: z.string(),
  userId: z.string(),
  email: z.string().email(),
  optional: z.boolean().optional().default(false),
})

export type Attendee = z.infer<typeof attendeeSchema>

export const calendarEventSchema = baseEntitySchema.extend({
  type: z.literal('calendarevent'),
  start: z.string().datetime().nullable(),
  end: z.string().datetime().nullable().optional(),
  allDay: z.boolean().default(false).optional(),
  organizer: attendeeSchema.optional(),
  attendees: z.array(attendeeSchema).optional(),
})
export type CalendarEvent = z.infer<typeof calendarEventSchema>

export function isCalendarEvent(node?: unknown): node is CalendarEvent {
  return calendarEventSchema.safeParse(node).success
}
