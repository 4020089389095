import { audioClipSchema, isAudioClip, isEntityClip, type AudioClip, type Clip, type EntityClip } from './audio-clip.js'
import { audioCommentSchema, type AudioComment } from './audio-comment.js'
import { isAudioTrack, trackSchema, type Track } from './audio-track.js'
import { bandSpaceSchema, isBandSpace, type BandSpace } from './band-space.js'
import {
  calendarEventSchema,
  calendarSchema,
  isCalendar,
  isCalendarEvent,
  type Attendee,
  type Calendar,
  type CalendarEvent,
} from './calendar.js'
import { effectSchema, EffectTypeValues, isEffect, type Effect, type EffectType } from './effect.js'
import { isMedia, mediaSchema, type Media } from './media.js'
import {
  isMidi,
  isMidiCC,
  isMidiProgramChange,
  midiSchema,
  type Midi,
  type MidiCC,
  type MidiProgramChange,
} from './midi.js'
import { isMusicXML, musicXMLSchema, type MusicXML } from './musicxml.js'
import {
  isManyOfManyPollResponse,
  isOneOfManyPollResponse,
  isPollResponse,
  manyOfManyPollResponseSchema,
  oneOfManyPollResponseSchema,
  pollResponseSchema,
  type ManyOfManyPollResponse,
  type OneOfManyPollResponse,
  type PollResponse,
} from './poll-response.js'
import {
  isManyOfManyPoll,
  isOneOfManyPoll,
  isPoll,
  pollSchema,
  pollTypes,
  type ManyOfManyPoll,
  type OneOfManyPoll,
  type Poll,
  type PollType,
} from './poll.js'
import { setlistSchema, type Setlist } from './setlist.js'
import { isSong, songSchema, type Song } from './song.js'
import { userSettingsSchema, type UserSettings, type UserSettingsData } from './user-settings.js'

const extensionSchemas = {
  /** we use non-passthrough for page for now, gradually moving the others over  */
  audioclip: audioClipSchema.passthrough(),
  audiocomment: audioCommentSchema.passthrough(),
  audiotrack: trackSchema.passthrough(),
  song: songSchema.passthrough(),
  midi: midiSchema,
  bandspace: bandSpaceSchema.passthrough(),
  calendar: calendarSchema.passthrough(),
  calendarevent: calendarEventSchema.passthrough(),
  musicxml: musicXMLSchema.passthrough(),
  usersettings: userSettingsSchema.passthrough(),
  effect: effectSchema,
  setlist: setlistSchema,
  pollresponse: pollResponseSchema,
  poll: pollSchema,
}

export const extensionEntityNames = Object.keys(
  extensionSchemas
) as unknown as readonly (keyof typeof extensionSchemas)[]

export {
  audioClipSchema,
  audioCommentSchema,
  bandSpaceSchema,
  calendarEventSchema,
  calendarSchema,
  EffectTypeValues,
  extensionSchemas,
  isAudioClip,
  isAudioTrack,
  isBandSpace,
  isCalendar,
  isCalendarEvent,
  isEffect,
  isEntityClip,
  isManyOfManyPoll,
  isManyOfManyPollResponse,
  isMedia,
  isMidi,
  isMidiCC,
  isMidiProgramChange,
  isMusicXML,
  isOneOfManyPoll,
  isOneOfManyPollResponse,
  isPoll,
  isPollResponse,
  isSong,
  manyOfManyPollResponseSchema,
  mediaSchema,
  midiSchema,
  musicXMLSchema,
  oneOfManyPollResponseSchema,
  pollResponseSchema,
  pollSchema,
  pollTypes,
  setlistSchema,
  songSchema,
  trackSchema,
  userSettingsSchema,
  type Attendee,
  type AudioClip,
  type AudioComment,
  type BandSpace,
  type Calendar,
  type CalendarEvent,
  type Clip,
  type Effect,
  type EffectType,
  type EntityClip,
  type ManyOfManyPoll,
  type ManyOfManyPollResponse,
  type Media,
  type Midi,
  type MidiCC,
  type MidiProgramChange,
  type MusicXML,
  type OneOfManyPoll,
  type OneOfManyPollResponse,
  type Poll,
  type PollResponse,
  type PollType,
  type Setlist,
  type Song,
  type Track,
  type UserSettings,
  type UserSettingsData,
}
