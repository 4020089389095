export type NotesPerBar = 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11
export type BeatsPerBar = 4 | 8
export interface Meter {
  notesPerBar: NotesPerBar
  beatsPerBar: BeatsPerBar
}

/** Parse meter - e.g., 4/4 or 7/8 */
export const parseMeter = (meter: string): Meter | null => {
  const meterRe = /^\s*(\d+)\s*\/\s*(\d+)\s*$/
  const res = meter.match(meterRe)
  if (!(res && res[0] && res[1] && res[2])) {
    return null
  }
  const notesPerBar = parseInt(res[1], 10) as NotesPerBar
  const beatsPerBar = parseInt(res[2], 10)
  if (beatsPerBar !== 4 && beatsPerBar !== 8) {
    return null
  }
  return { notesPerBar, beatsPerBar }
}

export const meterToString = (meter?: Meter) =>
  meter?.beatsPerBar && meter?.notesPerBar ? `${meter.notesPerBar} / ${meter.beatsPerBar}` : null
