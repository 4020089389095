import { z } from 'zod'
import { baseEntitySchema } from '../entity.js'

export const todoSchema = baseEntitySchema.extend({
  type: z.literal('todo'),
  completed: z.boolean().optional().default(false),
})

export type Todo = z.infer<typeof todoSchema>

export function isTodo(node?: unknown): node is Todo {
  return todoSchema.safeParse(node).success
}
