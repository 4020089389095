import { z } from 'zod'
import { baseEntitySchema } from '../entity.js'
import { videoSizes } from '../interfaces/sizes.js'
import { s3StorageSchema, storageSchema, storageUrlsSchema } from '../interfaces/storage.js'

export const videoSchema = baseEntitySchema.extend({
  type: z.literal('video'),
  size: z.enum(videoSizes).default('large').optional(),
  thumbnail: s3StorageSchema.optional(),
  transcoded: s3StorageSchema.optional(),
  storage: storageSchema.optional(),
  storageUrls: storageUrlsSchema
    .extend({
      video: z.record(z.enum(videoSizes)).optional(),
      transcoded: z.string(),
      thumbnail: z.string(),
    })
    .optional(),
})

/** @deprecated use Video instead */
export type VideoMedia = z.infer<typeof videoSchema>
export type Video = z.infer<typeof videoSchema>

export function isVideo(node?: unknown): node is Video {
  return videoSchema.safeParse(node).success
}
