import { shortUuid } from '@tunasong/models'
import { type CoreElement, type TunaDecendant } from '@tunasong/schemas'
import type { TAncestor, TElement, TNode } from '@udecode/slate'
import type { MaximizeMode, RangeMode } from 'slate'
import {
  Location,
  Node,
  Path,
  Editor as SlateEditor,
  Transforms as SlateTransforms,
  type Ancestor,
  type BaseElement,
  type BaseRange,
  type EditorAboveOptions,
  type EditorAfterOptions,
  type EditorBeforeOptions,
  type EditorDirectedDeletionOptions,
  type EditorNextOptions,
  type EditorNodeOptions,
  type EditorNodesOptions,
  type EditorNormalizeOptions,
  type EditorParentOptions,
  type EditorPathOptions,
  type EditorPreviousOptions,
  type EditorStringOptions,
  type NodeEntry,
  type NodeMatch,
  type PropsCompare,
  type PropsMerge,
} from 'slate'
import type { TextInsertTextOptions } from 'slate/dist/interfaces/transforms/text.js'
import invariant from 'tiny-invariant'
import { type TunaEditor } from './plugin-types.js'

/** Temporary helpers for Typescript until Slate gets proper support */

export const Editor = {
  deleteForward: (editor: TunaEditor, opts?: EditorDirectedDeletionOptions) =>
    SlateEditor.deleteForward(editor as SlateEditor, opts),
  deleteBackward: (editor: TunaEditor, opts?: EditorDirectedDeletionOptions) =>
    SlateEditor.deleteBackward(editor as SlateEditor, opts),
  path: (editor: TunaEditor, at: Location, opts?: EditorPathOptions) =>
    SlateEditor.path(editor as SlateEditor, at, opts),
  range: (editor: TunaEditor, at: Location, to?: Location) => SlateEditor.range(editor as SlateEditor, at, to),
  normalize: (editor: TunaEditor, opts?: EditorNormalizeOptions) => SlateEditor.normalize(editor as SlateEditor, opts),
  insertNode: (editor: TunaEditor, node: TNode) => SlateEditor.insertNode(editor as SlateEditor, node),
  parent: <T extends EditorParentOptions>(editor: TunaEditor, at: Location, opts?: T) =>
    SlateEditor.parent(editor as SlateEditor, at, opts),
  start: (editor: TunaEditor, at: Location) => SlateEditor.start(editor as SlateEditor, at),
  end: (editor: TunaEditor, at: Location) => SlateEditor.end(editor as SlateEditor, at),
  node: (editor: TunaEditor, at: Location, opts?: EditorNodeOptions) =>
    SlateEditor.node(editor as SlateEditor, at, opts) as NodeEntry<TNode>,
  nodes: <T extends TunaDecendant>(editor: TunaEditor, opts?: EditorNodesOptions<T>) =>
    SlateEditor.nodes<T>(editor as SlateEditor, opts),
  withoutNormalizing: (editor: TunaEditor, fn: () => void) => SlateEditor.withoutNormalizing(editor as SlateEditor, fn),
  above: <T extends CoreElement>(editor: TunaEditor, opts?: EditorAboveOptions<T>) =>
    SlateEditor.above(editor as SlateEditor, opts),
  // eslint-disable-next-line id-blacklist
  string: (editor: TunaEditor, at: Location, opts?: EditorStringOptions) =>
    SlateEditor.string(editor as SlateEditor, at, opts),
  after: (editor: TunaEditor, at: Location, opts?: EditorAfterOptions) =>
    SlateEditor.after(editor as SlateEditor, at, opts),
  before: (editor: TunaEditor, at: Location, opts?: EditorBeforeOptions) =>
    SlateEditor.before(editor as SlateEditor, at, opts),

  next: <T extends TunaDecendant>(editor: TunaEditor, opts?: EditorNextOptions<T>) =>
    SlateEditor.next(editor as SlateEditor, opts) as NodeEntry<TAncestor> | undefined,
  previous: (editor: TunaEditor, opts?: EditorPreviousOptions<Ancestor>) =>
    SlateEditor.previous(editor as SlateEditor, opts) as NodeEntry<TAncestor> | undefined,
  insertText: (editor: TunaEditor, text: string) => SlateEditor.insertText(editor as SlateEditor, text),
  isEmpty: (editor: TunaEditor, element: BaseElement) => SlateEditor.isEmpty(editor as SlateEditor, element),
  isVoid: (editor: TunaEditor, element: BaseElement) => SlateEditor.isVoid(editor as SlateEditor, element),
}

export const Transforms = {
  // custom helpers

  /** Insert node async, displaying a placeholder while the promise settles. */
  insertNodesAsync: async (
    editor: TunaEditor,
    finalNodesFn: () => Promise<TElement | TNode | (TElement | TNode)[] | null>,
    options: { temporaryNode?: TElement } = {}
  ) => {
    const { temporaryNode = { type: 'pulse', children: [{ text: '' }] } } = options
    const id = shortUuid()
    SlateTransforms.insertNodes(
      editor as SlateEditor,
      {
        ...temporaryNode,
        id,
      } as TElement
    )

    const insertedEntry = Array.from(
      Editor.nodes(editor, {
        at: {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, []),
        },
        match: node => 'id' in node && node.id === id,
      })
    )[0]
    invariant(insertedEntry, 'Expected to find a pending link block')
    const [, path] = insertedEntry
    try {
      const finalNode = await finalNodesFn()
      Editor.withoutNormalizing(editor, () => {
        SlateTransforms.delete(editor as SlateEditor, { at: path })
        if (finalNode) {
          SlateTransforms.insertNodes(editor as SlateEditor, finalNode, {
            at: path,
            select: true,
          })
        }
      })
    } catch (e) {
      SlateTransforms.delete(editor as SlateEditor, { at: path })
    }
  },

  // slate helpers (with types)
  moveNodes: <T extends TNode>(
    editor: TunaEditor,
    options: {
      at?: Location
      match?: NodeMatch<T>
      mode?: string
      to: Path
      voids?: boolean
    }
  ) => SlateTransforms.moveNodes(editor as SlateEditor, options as never),
  liftNodes: <T extends TNode>(
    editor: TunaEditor,
    options: {
      at?: Location
      match?: NodeMatch<T>
      mode?: MaximizeMode
      voids?: boolean
    }
  ) => SlateTransforms.liftNodes(editor as SlateEditor, options),
  select: (editor: TunaEditor, target: Location) => SlateTransforms.select(editor as SlateEditor, target),
  removeNodes: <
    S extends {
      at?: Location | undefined
      match?: NodeMatch<T> | undefined
      mode?: RangeMode | undefined
      hanging?: boolean | undefined
      voids?: boolean | undefined
    },
    T extends TNode,
  >(
    editor: TunaEditor,
    opts?: S
  ) => SlateTransforms.removeNodes<T>(editor as SlateEditor, opts),
  setNodes: <
    T extends {
      at?: Location | undefined
      match?: NodeMatch<TNode> | undefined
      mode?: MaximizeMode | undefined
      hanging?: boolean | undefined
      split?: boolean | undefined
      voids?: boolean | undefined
      compare?: PropsCompare | undefined
      merge?: PropsMerge | undefined
    },
  >(
    editor: TunaEditor,
    props: Partial<TNode>,
    opts?: T
  ) => SlateTransforms.setNodes(editor as SlateEditor, props, opts),
  insertNodes: <
    T extends {
      at?: Location | undefined
      match?: NodeMatch<Node> | undefined
      mode?: RangeMode | undefined
      hanging?: boolean | undefined
      select?: boolean | undefined
      voids?: boolean | undefined
    },
  >(
    editor: TunaEditor,
    nodes: TunaDecendant | TunaDecendant[],
    opts?: T
  ) => SlateTransforms.insertNodes(editor as SlateEditor, nodes, opts),
  move: (
    editor: TunaEditor,
    opts?: {
      distance?: number
      unit?: string
      reverse?: boolean
      edge?: 'anchor' | 'focus'
    }
  ) => SlateTransforms.move(editor as SlateEditor, opts as never),
  mergeNodes: <
    T extends {
      at?: Location | undefined
      match?: NodeMatch<Node> | undefined
      mode?: RangeMode | undefined
      hanging?: boolean | undefined
      voids?: boolean | undefined
    },
  >(
    editor: TunaEditor,
    options?: T
  ) => SlateTransforms.mergeNodes(editor as SlateEditor, options),
  delete: (
    editor: TunaEditor,
    options?: {
      at?: Location
      distance?: number
      unit?: string
      reverse?: boolean
      hanging?: boolean
      voids?: boolean
    }
  ) => SlateTransforms.delete(editor as SlateEditor, options as never),
  setSelection: (editor: TunaEditor, props: Partial<BaseRange>) =>
    SlateTransforms.setSelection(editor as SlateEditor, props),
  wrapNodes: <
    S extends {
      at?: Location | undefined
      match?: NodeMatch<T> | undefined
      mode?: MaximizeMode | undefined
      split?: boolean | undefined
      voids?: boolean | undefined
    },
    T extends TNode,
  >(
    editor: TunaEditor,
    el: BaseElement,
    props?: S
  ) => SlateTransforms.wrapNodes<T>(editor as SlateEditor, el, props),
  unwrapNodes: <
    S extends {
      at?: Location | undefined
      match?: NodeMatch<T> | undefined
      mode?: MaximizeMode | undefined
      split?: boolean | undefined
      voids?: boolean | undefined
    },
    T extends TNode,
  >(
    editor: TunaEditor,
    props?: S
  ) => SlateTransforms.unwrapNodes<T>(editor as SlateEditor, props),
  insertText: <S extends TextInsertTextOptions | undefined>(editor: TunaEditor, text: string, props?: S) =>
    SlateTransforms.insertText(editor as SlateEditor, text, props),
}

export type { TDescendant, TElement, TNode, TText } from '@udecode/slate'

export type { NodeEntry } from 'slate'
