/**
 * Store the yDoc in S3
 */

import { GetObjectCommand, PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import type { S3Storage, Storage } from '@tunasong/schemas'
import invariant from 'tiny-invariant'

const client = new S3Client({})

const storeDoc = async ({
  yDocAsUpdates,
  bucketName,
  entityId,
  docType,
}: {
  /** The yDoc encoded as updates */
  yDocAsUpdates: Uint8Array
  bucketName: string
  entityId: string
  docType: string
}) => {
  invariant(bucketName, 'No bucketName')
  invariant(entityId, 'No entityId')
  invariant(yDocAsUpdates, 'No stringEncodedyDoc')

  const objectKey = `docs/${entityId}/doc.ydoc`

  const result = await client.send(
    new PutObjectCommand({
      Bucket: bucketName,
      Key: objectKey,
      ContentType: 'application/octet-stream',
      /** yDoc encoded as updates */
      Body: yDocAsUpdates,
    })
  )
  const { VersionId: versionId } = result
  return {
    versionId,
    filename: objectKey,
    type: 'S3',
    docType: docType as S3Storage['docType'],
  } satisfies S3Storage
}

/** Retrieve the doc from S3 as Uint8Array */
const getDoc = async ({
  bucketName,
  entityId,
  versionId,
}: {
  bucketName: string
  entityId: string
  versionId?: string
}) => {
  invariant(bucketName, 'No bucketName')
  invariant(entityId, 'No entityId')

  const objectKey = `docs/${entityId}/doc.ydoc`

  try {
    const result = await client.send(
      new GetObjectCommand({
        Bucket: bucketName,
        Key: objectKey,
        VersionId: versionId,
      })
    )
    const data = await result.Body?.transformToByteArray()
    return data ?? null
  } catch (error) {
    console.warn('Error getting doc', error)
  }
  return null
}

const isDocPath = ({ entityId, storage }: { entityId?: string; storage?: Storage }) =>
  Boolean(
    entityId &&
      storage?.type === 'S3' &&
      storage.docType === 'yDoc' &&
      storage?.filename.startsWith(`docs/${entityId}/`)
  )

export const S3Doc = {
  storeDoc,
  getDoc,
  isDocPath,
}
