import { useEffect, useRef } from 'react'

import WL from 'nosleep.js'

export interface WakeLock {
  new (): WakeLock
  enable(): void
  disable(): void
}

export const GlobalWakeLock: WakeLock = WL as never

export const useWakeLockNoSleep = () => {
  /** Wake Lock for touch devices. Requires a user touch before starting */
  const enabled = useRef(false)
  useEffect(() => {
    if (typeof navigator === 'undefined') {
      return
    }

    const wakeLock = new GlobalWakeLock()
    const enableWakelock = () => {
      wakeLock.enable()
      enabled.current = true
      document.removeEventListener('touchstart', enableWakelock, false)
      document.removeEventListener('click', enableWakelock, false)
    }
    document.addEventListener('touchstart', enableWakelock, false)
    document.addEventListener('click', enableWakelock, false)
    return () => {
      if (enabled.current) {
        wakeLock.disable()
      }
      enabled.current = false
    }
  }, [])
}
