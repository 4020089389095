import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { EntityType } from '@tunasong/schemas'
// Define a type for the slice state
export interface StorageState {
  storageBaseUrl: string
  /** Wildcard auth query string for the users' entities */
  wildcardAuthQuery: string | null
  /** Flag to indicate we're uploading */
  isUploading: boolean
  /** Loading URLs from the server, indexed by entityId */
  loadingForEntity: Record<string, boolean>

  /** Map from mimeType to entity type. Defined by plugins, and must be set in app bootstrap */
  extensionToEntityType: Record<string, EntityType | undefined>
  entityTypeToMimeType: Partial<Record<EntityType, string>>
  /** Accept map for use with input / DnD accept filters */
  mimeTypeAccept: Record<string, string[]>
}

// Define the initial state using that type
const initialState: StorageState = {
  storageBaseUrl: 'https://tunasong.com',
  wildcardAuthQuery: null,
  isUploading: false,
  loadingForEntity: {},
  extensionToEntityType: {},
  entityTypeToMimeType: {},
  mimeTypeAccept: {},
}

/**
 * @note if you get an Immer error here, pin the Immer version to ensure a single instance
 * @see https://github.com/reduxjs/redux-toolkit/issues/1181
 */
export const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    setWildcardAuthQuery: (state, action: PayloadAction<{ wildcardAuthQuery: string }>) => {
      /** Don't update it, because if we do, all the URLs will change all the time */
      const isEqual = state.wildcardAuthQuery === action.payload.wildcardAuthQuery
      if (!isEqual) {
        state.wildcardAuthQuery = action.payload.wildcardAuthQuery
      }
    },
    setUploading: (state, action: PayloadAction<{ isUploading: boolean }>) => {
      state.isUploading = action.payload.isUploading
    },
    setLoading: (state, action: PayloadAction<{ entityId: string; loading: boolean }>) => {
      state.loadingForEntity[action.payload.entityId] = action.payload.loading
    },
    registerMimeTypeMappings: (
      state,
      action: PayloadAction<{
        extensionToEntityType: Record<string, EntityType | undefined>
        entityTypeToMimeType: Record<EntityType, string>
        mimeTypeAccept: Record<string, string[]>
      } | null>
    ) => {
      if (action.payload === null) {
        state.extensionToEntityType = {}
        state.entityTypeToMimeType = {}
        state.mimeTypeAccept = {}
        return
      }
      const { extensionToEntityType, entityTypeToMimeType, mimeTypeAccept } = action.payload
      state.extensionToEntityType = extensionToEntityType
      state.entityTypeToMimeType = entityTypeToMimeType
      state.mimeTypeAccept = mimeTypeAccept
    },
  },
})
