import { useCallback, useEffect, useState } from 'react'

/** Page control hooks */
export const usePageControl = (container?: HTMLElement | null) => {
  const c = container ?? document.body

  const [height, setHeight] = useState(c.getBoundingClientRect().height)

  const atEnd = useCallback(() => c.scrollHeight - c.scrollTop === c.clientHeight, [c])
  const [isAtEnd, setIsAtEnd] = useState(atEnd())
  const atBeginning = useCallback(() => c.scrollTop === 0, [c])
  const [isAtBeginning, setIsAtBeginning] = useState(atBeginning())

  useEffect(() => {
    const ro = new ResizeObserver(() => {
      const b = c.getBoundingClientRect()
      setHeight(b.height)
      setIsAtBeginning(atBeginning())
      setIsAtEnd(atEnd())
    })
    ro.observe(c)
    return () => {
      ro.disconnect()
    }
  }, [atBeginning, atEnd, c])

  useEffect(() => {
    const handleScroll = () => {
      setIsAtBeginning(atBeginning())
      setIsAtEnd(atEnd())
    }

    c.addEventListener('scroll', handleScroll)
    return () => c.removeEventListener('scroll', handleScroll)
  }, [atBeginning, atEnd, c])

  const pageDown = useCallback(() => c.scrollBy(0, height), [c, height])
  const pageUp = useCallback(() => c.scrollBy(0, -height), [c, height])

  return { height, isAtBeginning, isAtEnd, pageDown, pageUp }
}
