import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { UserConfig } from '@tunasong/manifest'
import manifest from '@tunasong/manifest'

// Define a type for the slice state
export interface AppConfig {
  baseDomain: string
  apiBaseUrl: string
  chatUrl: string

  userConfig: UserConfig
  // user config is loaded from persisted storage, e.g., profile
  userConfigLoaded: boolean
}

// Define the initial state using that type
const initialState: AppConfig = {
  baseDomain: 'tunasong.com',
  apiBaseUrl: 'https://tunasong.com/api',
  chatUrl: 'https://tunasong.com/chat/',
  // Create a user config with the default values
  userConfig: manifest.userConfig.parse({ plugins: {} }),
  userConfigLoaded: false,
}

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setApiBaseUrl: (state, action: PayloadAction<{ apiBaseUrl: string }>) => {
      state.apiBaseUrl = action.payload.apiBaseUrl
    },
    setChatUrl: (state, action: PayloadAction<{ chatUrl: string }>) => {
      state.chatUrl = action.payload.chatUrl
    },
    updateUserConfig: (state, action: PayloadAction<Partial<UserConfig>>) => {
      state.userConfig = { ...state.userConfig, ...action.payload }
    },
    setUserConfigLoaded: (state, action: PayloadAction<boolean>) => {
      state.userConfigLoaded = action.payload
    },
  },
})
