import { type CoreElement } from '@tunasong/schemas'
import type { TunaEditor } from '../plugin-types.js'
import React from 'react'

export const renderBlockDecorations = (editor: TunaEditor, element: CoreElement) =>
  editor.plugins
    .map(plugin =>
      plugin.blockDecorations ? (
        <React.Fragment key={plugin.key}>{plugin.blockDecorations(editor, element)}</React.Fragment>
      ) : null
    )
    .filter(Boolean)
