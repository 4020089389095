import { FormControl, InputLabel, ListItemText, MenuItem, Select, capitalize, type SelectProps } from '@mui/material'
import { shortUuid } from '@tunasong/models'
import { useMemo } from 'react'

export interface EnumSelectProps {
  label?: string
  values?: string[] | Record<string, string>
  multiple?: boolean
}

export function EnumSelect<T>(props: SelectProps<T> & EnumSelectProps) {
  const { values = [], variant, multiple = false, label, inputProps, fullWidth = true, onChange, ...restProps } = props

  const elementId = useMemo(() => shortUuid(), [])
  const labelId = useMemo(() => shortUuid(), [])

  const enumValues = useMemo(() => (Array.isArray(values) ? values : Object.values(values)), [values])
  const enumKeys = useMemo(
    () => (Array.isArray(values) ? values.map(v => capitalize(v)) : Object.keys(values)),
    [values]
  )

  return (
    <FormControl fullWidth={fullWidth} sx={{ mt: 1 }}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        {...restProps}
        labelId={labelId}
        multiple={multiple}
        label={label}
        inputProps={{
          id: elementId,
          ...inputProps,
        }}
        inputRef={inputProps?.ref}
        variant={variant}
        onChange={onChange}
      >
        {enumValues.map((val, idx) => (
          <MenuItem key={idx} value={val}>
            <ListItemText>{enumKeys[idx]}</ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
