import { skipToken } from '@reduxjs/toolkit/query'
import { entitiesApi } from '../api/entities.js'
import type { EdgeType } from '@tunasong/schemas'
import { useMemo } from 'react'

export const useEntityBacklinks = ({ entityId, relation }: { entityId?: string; relation: EdgeType }) => {
  const { currentData, ...restProps } = entitiesApi.useLoadEntityBacklinksQuery(
    !entityId ? skipToken : { id: entityId }
  )

  const backlinks = useMemo(() => {
    if (!currentData) {
      return []
    }

    return relation ? currentData.filter(e => e.relation === relation) : currentData
  }, [currentData, relation])

  return { backlinks, ...restProps }
}
