import { createAsyncThunk } from '@reduxjs/toolkit'
import { canWrite, type ShareItem } from '@tunasong/models'
import type { ACL, Persisted } from '@tunasong/schemas'
import { entitiesApi } from '../../api/entities.js'
import type { RootState } from '../../configure-store.js'
import type { TunaThunkDispatch } from '../../hooks/thunk-dispatch.hook.js'
import invariant from 'tiny-invariant'

export const addChild = createAsyncThunk<
  Persisted<ShareItem>,
  { parentId: string; childId: string },
  { state: RootState; dispatch: TunaThunkDispatch }
>(
  'entities/addChild',
  async ({ parentId: providedParentId, childId }, { dispatch, getState }): Promise<Persisted<ShareItem>> => {
    const { userId } = getState().user

    invariant(userId, 'User must be logged in to add a child entity')

    const parentId = providedParentId === 'ROOT' ? null : providedParentId

    const parent = parentId ? entitiesApi.endpoints.loadEntity.select({ id: parentId })(getState())?.data : null
    const child = entitiesApi.endpoints.loadEntity.select({ id: childId })(getState())?.data
    const childETag = getState().entities.eTags[childId]

    if (!child || (parentId && !parent)) {
      throw new Error(`Entity ${childId} not found - this is unexpected`)
    }

    const canWriteChild = userId === child.userId || canWrite(userId, child.acls)
    const canWriteParent = !parent || (userId && (userId === parent?.userId || canWrite(userId, parent?.acls ?? [])))

    if (!(canWriteChild && canWriteParent)) {
      throw new Error(`User ${userId} does not have permission add child ${childId} to parent ${parentId}`)
    }

    /** Update the child with the parentId */

    dispatch(
      entitiesApi.endpoints.updateEntity.initiate({
        id: child.id,
        partialEntity: { parentId: parentId ?? 'ROOT' },
        eTag: childETag,
      })
    ).unwrap()

    /** We handle duplicates in ACLs server side., Set the new child ACLs to the same as the parent  */
    const childAcls = parent
      ? [...(parent.acls ?? []), { principal: parent.userId, permission: 'WRITE' } as ACL]
      : child.acls ?? []

    /** Add the entity ACLs to the added entity. We don't track the result in the store */
    return dispatch(entitiesApi.endpoints.share.initiate({ item: child, acls: childAcls }, { track: false })).unwrap()
  }
)
