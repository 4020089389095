/** Entity properties */

import { type CoreElement } from '@tunasong/schemas'
import { reservedPropertyMap } from './element-reserved.js'

/** Get the fields of the Element fields that are not standard  */
export const getCustomElementFields = <T extends Partial<CoreElement> = Partial<CoreElement>>(element: T) => {
  const properties = {} as Record<string, unknown>
  for (const [key, value] of Object.entries(element)) {
    if ((reservedPropertyMap as Record<string, unknown>)[key]) {
      continue
    }
    properties[key] = value
  }
  return properties
}

export function isString(e: unknown): e is string {
  return typeof e === 'string'
}

export function isBoolean(e: unknown): e is boolean {
  return typeof e === 'boolean'
}

export function isFunction(e: unknown): e is () => void {
  return typeof e === 'function'
}

export function isNumber(e: unknown): e is number {
  return typeof e === 'number'
}

export function isDateString(e: unknown): e is string {
  const isoString =
    /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/
  if (!isString(e)) {
    return false
  }
  return isoString.test(e)
}

export enum PropertyType {
  STRING = 'string',
  DATESTRING = 'datestring',
  NUMBER = 'number',
}

export const guessFieldType = (field: unknown) => {
  if (isDateString(field)) {
    return PropertyType.DATESTRING
  }
  if (isString(field)) {
    return PropertyType.STRING
  }
  if (isNumber(field)) {
    return PropertyType.NUMBER
  }
  return PropertyType.STRING
}
