// Need to use the React-specific entry point to import createApi
import { createApi } from '@reduxjs/toolkit/query/react'
import { z } from 'zod'
import baseQuery from './base-query.js'

/** Zod schema for GameScore */
export const GameScoreSchema = z.object({
  gameId: z.string(),
  nickName: z.string(),
  score: z.number(),
  createdAt: z.string().optional(),
})

const Leaderboard = z.array(GameScoreSchema)

export type GameScore = z.infer<typeof GameScoreSchema>

export type Leaderboard = z.infer<typeof Leaderboard>

// Define a service using a base URL and expected endpoints
export const gamesApi = createApi({
  reducerPath: 'games-api',
  tagTypes: ['Leaderboard'],
  baseQuery,
  endpoints: builder => ({
    getLeaderboard: builder.query<Leaderboard, { gameId: string; limit?: number }>({
      providesTags: ['Leaderboard'],
      query: ({ gameId, limit = 10 }) => ({
        url: `games/leaderboard?gameId=${gameId}&limit=${limit}`,
        validateStatus: (res: Response, body: unknown) => {
          if (res.type === 'cors') {
            return true
          }
          const zResult = Leaderboard.safeParse(body)
          return zResult.success
        },
      }),
    }),
    registerScore: builder.mutation<GameScore, { gameId: string; nickName: string; score: number }>({
      invalidatesTags: ['Leaderboard'],
      query: ({ gameId, score, nickName }) => ({
        url: `games/`,
        method: 'POST',
        body: { gameId, nickName, score },
        // validateStatus: (res: Response, body: unknown) => res.status === 201 && PersistedScore.safeParse(body).success,
      }),
    }),
  }),
})
