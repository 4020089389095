import { z } from 'zod'
import { elementOptionsSchema } from './slate/slate-element-options.js'
import { coreElementSchema } from './slate/slate-element.js'
import { storageSchema, storageUrlsSchema } from './interfaces/storage.js'
import { entityCommandSpecSchema, entityEventSchema } from './entity-events.js'
import { customCommandSchema } from './command.js'

/**
 * @note we don't merge with the slate core options here
 * to avoid a type recursion problem
 * https://www.notion.so/Type-instantiation-issue-in-RTK-Query-baseQuery-781f1329f8bd441c8044895d596cfd4d?pvs=4
 */
export const baseEntitySchema = z.object({
  type: z.string(),
  /** id of the entity */
  id: z.string().optional(),
  /** Name of the entity */
  name: z.string().optional(),
  /** Description of the entity */
  description: z.string().optional(),
  /** List of tags for the entity */
  tags: z.array(z.string()).optional(),
  /** Entity UI options */
  options: elementOptionsSchema.optional(),
  /** Metadata. This is typically created by the plugins. */
  metadata: z.record(z.unknown()).optional(),
  /** ID of parent entity */
  parentId: z.string().optional(),
  /** The ID of the Entity used as a template for this instance */
  templateId: z.string().optional(),
  /** Default view name for the entity */
  defaultView: z.string().optional(),
  /** @deprecated Entity has sketches. This means the Entity can have sketches children. */
  sketches: z.boolean().optional(),
  /** @depreated Starred entitites are given sort priority */
  starred: z.boolean().optional(),
  /** The element is in the trash. */
  trash: z.boolean().optional(),
  /** Some entities are created before they are complete, e.g., due to long-running async generation tasks */
  pending: z.boolean().optional(),
  /** Storage-backed entities */
  storage: storageSchema.optional(),
  /** Storage-backed entities signed URLs */
  storageUrls: storageUrlsSchema.optional(),

  /** Event commands to run for the entity */
  eventCommands: z.record(entityEventSchema, z.array(entityCommandSpecSchema)).optional(),
  customCommands: z.array(customCommandSchema).optional().describe('Custom commands provided by the entity'),

  /**
   * @deprecated. Use storage with docType `yDoc` instead.
   */
  yDoc: z.union([z.null(), z.string()]).optional(),
})

/** An Entity that is also a Slate element */
export const elementEntitySchema = baseEntitySchema.merge(coreElementSchema)

export type BaseEntity = z.infer<typeof baseEntitySchema>

export function isBaseEntity(node?: unknown): node is BaseEntity {
  return baseEntitySchema.safeParse(node).success
}
