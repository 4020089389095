import { type CoreElement } from '@tunasong/schemas'
import { useCallback, useEffect } from 'react'
import { Location, type NodeEntry } from 'slate'
import { ReactEditor } from 'slate-react'
import { getEditorContainerId } from '../editor-container.js'
import { type TunaEditor } from '../plugin-types.js'
import { type VirtualElement } from '@popperjs/core'
import invariant from 'tiny-invariant'
import { logger } from '@tunasong/models'

const editorEvents = [
  'show-block-properties',
  'show-preview',
  'show-insert-block',
  'insert-suggestion',
  'show-suggestions',
  'hide-suggestions',
  'show-context-menu',
  'show-mention',
  'hide-mention',
  'show-comments',
  'show-content',
  'show-emoji',
  'complete',
  /** Hide modals - typically in response to ESC or clickaway */
  'hide-modal',
  /** Links */
  'show-link',
  'hide-link',
  'show-commands',
  'hide-commands',
  'show-chords',
] as const

export type EditorEventType = (typeof editorEvents)[number]

export interface BaseEditorEventData {
  type: EditorEventType
  /** The HTML Element representing the Block */
  htmlElement: HTMLElement
  /** The visual anchorEl for the event  */
  anchorEl?: VirtualElement | HTMLElement | null
  /** The Slate NodeEntry for the Block */
  nodeEntry: NodeEntry<CoreElement>
  /** The current Slate selection. Useful when inserting stuff */
  selection: Location
}

export interface InsertSuggestionEvent extends BaseEditorEventData {
  type: 'insert-suggestion'
  /** 0-based index of suggestion to insert */
  suggestionIndex: number
}

export interface ShowSuggestionsEvent extends BaseEditorEventData {
  type: 'show-suggestions'
}
export interface HideSuggestionsEvent extends BaseEditorEventData {
  type: 'hide-suggestions'
}
export interface ShowBlockPropertiesEvent extends BaseEditorEventData {
  type: 'show-block-properties'
  /** Description of event. Can be used e.g., in dialog titles */
  title?: string
}

export interface ShowPreviewEvent extends BaseEditorEventData {
  type: 'show-preview'
}

export interface ShowInsertBlockEvent extends BaseEditorEventData {
  type: 'show-insert-block'
}
export interface ShowContextMenuEvent extends BaseEditorEventData {
  type: 'show-context-menu'
}
export interface ShowMentionEvent extends BaseEditorEventData {
  type: 'show-mention'
}
export interface ShowCommentsEvent extends BaseEditorEventData {
  type: 'show-comments'
}

export interface HideMentionEvent extends BaseEditorEventData {
  type: 'hide-mention'
}
export interface ShowContentEvent extends BaseEditorEventData {
  type: 'show-content'
}
export interface ShowEmojiEvent extends BaseEditorEventData {
  type: 'show-emoji'
}
export interface CompleteEvent extends BaseEditorEventData {
  type: 'complete'
  editor: TunaEditor
}

export interface HideModalEvent extends BaseEditorEventData {
  type: 'hide-modal'
}

export interface ShowLinkEvent extends BaseEditorEventData {
  type: 'show-link'
}
export interface HideLinkEvent extends BaseEditorEventData {
  type: 'hide-link'
}

/** Commands */
export interface ShowCommandsEvent extends BaseEditorEventData {
  type: 'show-commands'
}

export interface HideCommandsEvent extends BaseEditorEventData {
  type: 'hide-commands'
}
export interface ShowChordsCommand extends BaseEditorEventData {
  type: 'show-chords'
}

/** Hide modals - typically in response to ESC or clickaway */

/** @todo type the other events here too */
export type EditorEventData =
  | InsertSuggestionEvent
  | ShowBlockPropertiesEvent
  | ShowInsertBlockEvent
  | ShowSuggestionsEvent
  | HideSuggestionsEvent
  | ShowContextMenuEvent
  | ShowMentionEvent
  | ShowCommentsEvent
  | HideMentionEvent
  | ShowContentEvent
  | ShowEmojiEvent
  | CompleteEvent
  | HideModalEvent
  | ShowLinkEvent
  | HideLinkEvent
  | ShowPreviewEvent
  | ShowCommandsEvent
  | HideCommandsEvent
  | ShowChordsCommand

export type EditorEvent = CustomEvent<EditorEventData>

export interface EditorMessage {
  editor?: TunaEditor | null
  type: EditorEventType
  handler?: (ev: EditorEvent) => void
  /** If disabled, do not subscribe to events. Useful e.g., for read-only editors */
  disabled?: boolean
}

export const dispatchEditorMessage = ({
  editor,
  type,
  data,
  nodeEntry,
  anchorEl,
}: {
  editor: TunaEditor
  type: EditorEventType
  anchorEl?: VirtualElement | null
  nodeEntry: NodeEntry<CoreElement>
  data?: Omit<EditorEventData, 'htmlElement' | 'nodeEntry'>
}) => {
  invariant(editor, `Cannot dispatch message without editor`)

  const htmlElement = document.getElementById(getEditorContainerId(editor))
  if (!htmlElement) {
    throw new Error(`Cannot dispatch message because we have no anchor HTMLElement`)
  }
  const event = {
    type,
    anchorEl,
    htmlElement,
    nodeEntry,
    selection: editor.selection,
    ...data,
  } as EditorEventData
  const eventData = { detail: event }

  const dev: EditorEvent = new CustomEvent<EditorEventData>(type, eventData)
  return htmlElement.dispatchEvent(dev)
}

export const useEditorMessage = ({ editor, type, handler, disabled = false }: EditorMessage) => {
  useEffect(() => {
    if (!editor) {
      return
    }
    const editorId = getEditorContainerId(editor)
    const e = document.getElementById(editorId)
    if (!(e && handler) || disabled) {
      return
    }
    e.addEventListener(type, handler as EventListener)
    return () => {
      e.removeEventListener(type, handler as EventListener)
    }
  }, [handler, type, disabled, editor])

  /** Return a send function */
  const dispatchEvent = useCallback(
    (element: CoreElement, anchorEl: HTMLElement | null, data?: Omit<EditorEventData, 'htmlElement' | 'nodeEntry'>) => {
      if (!editor) {
        logger.warn(`Dispatched event without editor`, element, data)
        return
      }

      const e = document.getElementById(getEditorContainerId(editor))
      invariant(e, `Cannot dispatch message because we have no editor HTMLElement`)
      let path = [0]
      try {
        path = ReactEditor.findPath(editor as unknown as ReactEditor, element)
      } catch {}
      const nodeEntry: NodeEntry<CoreElement> = [element, path]

      return dispatchEditorMessage({ editor, type, data, nodeEntry, anchorEl })
    },
    [editor, type]
  )
  return dispatchEvent
}
