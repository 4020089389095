import { baseEntitySchema } from '../entity.js'
import { z } from 'zod'

export const pageSchema = baseEntitySchema.extend({
  type: z.literal('page'),
})

export function isPage(node: unknown): node is z.infer<typeof pageSchema> {
  return pageSchema.safeParse(node).success
}

export type Page = z.infer<typeof pageSchema>

export default pageSchema
