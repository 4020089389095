import { type EntityOrElement } from '@tunasong/schemas'
import { useCallback, useState } from 'react'
import { ConfirmDialog } from './confirm-dialog.js'

interface ConfirmDeleteDialog<T extends EntityOrElement> {
  onConfirm(e: T): void
}

export function useConfirmDeleteDialog<T extends EntityOrElement>() {
  const [confirmItem, setConfirmItem] = useState<T | null>(null)
  const handleHide = useCallback(() => setConfirmItem(null), [])

  const ConfirmDeleteDialog = useCallback(
    ({ onConfirm }: ConfirmDeleteDialog<T>) => {
      if (!confirmItem) {
        return null
      }

      const handleConfirm = () => {
        setConfirmItem(null)
        onConfirm(confirmItem)
      }

      return (
        <ConfirmDialog
          open={Boolean(confirmItem)}
          text={`Really permanently delete ${confirmItem.type}: ${confirmItem.name}? This action is non-reversable.`}
          onCancel={handleHide}
          onConfirm={handleConfirm}
        />
      )
    },
    [confirmItem, handleHide]
  )

  return {
    ConfirmDeleteDialog,
    setConfirmItem,
    /** Can be used directly in e.g., click handlers */
    setConfirmItemHandler: (item: T) => () => setConfirmItem(item),
  }
}
