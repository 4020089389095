import { getBrowserName, getOS, isMobileDevice, getRawOS } from './detect.js'

export const useDeviceInfo = () => {
  const os = getOS()
  const rawOS = getRawOS()
  const maxTouchPoints = typeof navigator !== 'undefined' ? navigator.maxTouchPoints : -1
  const browserName = getBrowserName()
  const isIPad = os === 'mac' && browserName === 'safari' && maxTouchPoints >= 2

  return { os, rawOS, isIPad, isMobileDevice, isMobile: isMobileDevice(), browserName, maxTouchPoints }
}
