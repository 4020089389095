import { Box } from '@mui/material'
import React, { type FC, useState } from 'react'
import { useVisibilityTrigger } from './visibility-trigger.hook.js'

export interface VisibilityTriggerProps {
  onVisible?(): void
}

export const VisibilityTrigger: FC<VisibilityTriggerProps> = props => {
  const { onVisible } = props

  const [endRef, setEndRef] = useState<React.ElementRef<'div'> | null>(null)

  useVisibilityTrigger({ el: endRef, cb: onVisible })

  return <Box sx={{ height: 1 }} ref={setEndRef} />
}

export default VisibilityTrigger
