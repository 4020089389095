import { PluginContext, maturityFilter, type PluginConfigSet, type TunaPlugin } from '@tunasong/plugin-lib'
import type { Maturity } from '@tunasong/schemas'
import { useContext, useEffect, useState } from 'react'

export const usePlugins = (type: PluginConfigSet | null = 'all', maturity: Maturity = 'stable') => {
  const repo = useContext(PluginContext)

  const [pluginCollection, setCollection] = useState<TunaPlugin[] | null>(
    type ? repo?.pluginCollections[type] ?? null : null
  )

  useEffect(() => {
    if (!(repo && type) || pluginCollection) {
      return
    }
    repo
      .loadCollection(type)
      ?.then(coll =>
        setCollection(coll.flatMap(plugin => [plugin, ...(plugin.plugins ?? [])]).filter(maturityFilter(maturity)))
      )
  }, [maturity, pluginCollection, repo, type])

  return pluginCollection
}
