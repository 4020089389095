import { z } from 'zod'

export const meterSchema = z.object({
  notesPerBar: z.union([
    z.literal(2),
    z.literal(3),
    z.literal(4),
    z.literal(5),
    z.literal(6),
    z.literal(7),
    z.literal(8),
    z.literal(9),
    z.literal(10),
    z.literal(11),
  ]),
  beatsPerBar: z.union([z.literal(4), z.literal(8)]),
})

export type Meter = z.infer<typeof meterSchema>

export const rhythmSchema = z.object({
  tempo: z.number().optional(),
  meter: meterSchema.optional(),
  triplet: z.boolean().optional(),
})

export type Rhythm = z.infer<typeof rhythmSchema>
