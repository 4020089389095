import { useEffect, useRef } from 'react'
import invariant from 'tiny-invariant'
import { isSafari } from '../browser/index.js'
import { logger } from '@tunasong/models'

/** WakeLock using the Chrome experimental Wakelock API */
export const useWakeLockNavigator = () => {
  const activeWakeLock = useRef<WakeLockSentinel | null>(null)

  useEffect(() => {
    // create an async function to request a wake lock
    const requestWakeLock = async () => {
      try {
        invariant('wakeLock' in navigator, 'WakeLock API not available')
        if (activeWakeLock.current) {
          await activeWakeLock.current.release()
        }
        const wakeLock = await navigator.wakeLock.request('screen')

        // listen for our release event
        wakeLock.addEventListener('release', () => {
          activeWakeLock.current = null
        })
        activeWakeLock.current = wakeLock
      } catch (err) {
        logger.warn(`Wakelock request failed: `, err)
      }
    }

    const handleVisibilityChange = () => {
      /** Safari does not allow visibility change */
      if (!isSafari() && activeWakeLock.current === null && document.visibilityState === 'visible') {
        requestWakeLock()
      }
    }

    const handleClick = () => {
      if (activeWakeLock.current) {
        return
      }
      requestWakeLock()
    }

    /** Initial lock */
    handleVisibilityChange()

    document.addEventListener('visibilitychange', handleVisibilityChange)
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      document.removeEventListener('click', handleClick)
    }
  }, [])
}
