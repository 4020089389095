import { useCallback, useState } from 'react'

/** Simple dialog that handles the open/close state */
export const useSimpleDialogState = () => {
  const [isOpen, setIsOpen] = useState(false)
  const open = useCallback(() => setIsOpen(true), [])
  const close = useCallback(() => setIsOpen(false), [])

  return { isOpen, setIsOpen, open, close }
}
