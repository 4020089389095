import { defaultEditorValue } from '@tunasong/models'
import { type TunaDecendant } from '@tunasong/schemas'
import { createCollaborativeDoc } from '@tunasong/sync-lib'

/** Create an yDoc string from slate nodes.  */
export const getYDocString = (nodes: TunaDecendant[]) => {
  const { yDoc } = createCollaborativeDoc({ nodes })
  return yDoc
}

export const getDefaultYDocString = () => {
  const { yDoc } = createCollaborativeDoc({ nodes: defaultEditorValue })
  return yDoc
}
