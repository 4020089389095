import { Box, FormLabel, capitalize } from '@mui/material'
import { type FC } from 'react'
import { type ListFormField } from '../../../lib/form-field.js'
import { type FormComponentGroupComponentProps } from '../form-component.js'
import { formBoxStyles } from '../sx.js'

export interface ListSchemaFieldProps extends FormComponentGroupComponentProps<ListFormField, unknown[]> {}

/** @note not fully implemented */
export const ListSchemaField: FC<ListSchemaFieldProps> = props => {
  const { name, spec, errors, FormControl, ...restProps } = props
  const label = capitalize(spec.metadata?.label ?? name)

  /** @todo implement this */
  const list: unknown[] = []
  return (
    <Box sx={formBoxStyles}>
      <FormLabel sx={{ mb: 1 }}>{label}</FormLabel>

      {list.map((item, index) => (
        <FormControl
          key={index}
          name={`${name}[${index}]`}
          errors={errors}
          formField={spec.valueType}
          defaultValue={item}
          {...restProps}
        />
      ))}
      {/* <Button onClick={addElement}>Add</Button> */}
    </Box>
  )
}

export default ListSchemaField
