/** Sync protocol message types */
export enum YJSMessageType {
  Sync = 0,
  Awareness = 1,
  Auth = 2,
  QueryAwareness = 3,
}

export const YJSMessageTypeName: Record<YJSMessageType, string> = {
  0: 'Sync',
  1: 'Awareness',
  2: 'Auth',
  3: 'QueryAwareness',
}

export enum YJSSyncType {
  /** SyncStep0 that is the same as SyncStep1, but force a full sync for clients seen before */
  SyncStep0 = 100,
  SyncStep1 = 0,
  SyncStep2 = 1,
  Update = 2,
}
