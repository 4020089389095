import { entityTypeNames } from '@tunasong/schemas'
import { z } from 'zod'

const eventTypes = [
  'general',
  'share',
  'invite',
  'mention',
  'poll-result',
  'poll-invite',
  'comment',
  'redux-push',
] as const

export type ServerEventType = (typeof eventTypes)[number]

/** Server Events, typically triggered from changes in the entities */

export const ServerEventSchema = z.object({
  type: z.enum(eventTypes),
  entity: z.object({
    id: z.string(),
    parentId: z.string().optional(),
    name: z.string().optional(),
    type: z.enum(entityTypeNames),
  }),
  actions: z
    .array(
      z.object({
        title: z.string(),
        action: z.string(),
      })
    )
    .optional(),
})
export type ServerEvent = z.infer<typeof ServerEventSchema>

export interface NotificationServerEvent extends ServerEvent, NotificationOptions {
  title?: string
  message: string

  /** Principal who have sent the notification. System message if not set */
  from?: string

  /** Principal ID to receive the message */
  to: string
}
