// Generated by ts-to-zod
import { z } from 'zod'

export const instrumentTypes = ['vocals', 'guitar', 'bass', 'keyboard', 'piano', 'drums'] as const
export type InstrumentType = (typeof instrumentTypes)[number]

export const instrumentSchema = z.object({
  type: z.enum(instrumentTypes),
  name: z.string().optional(),
  notes: z.string().optional(),
})

export type Instrument = z.infer<typeof instrumentSchema>

export enum GuitarBrand {
  Fender = 'Fender',
  Gibson = 'Gibson',
  Epiphone = 'Epiphone',
  Martin = 'Martin',
  Ibanez = 'Ibanez',
  Rickenbacker = 'Rickenbacker',
  PRS = 'PRS',
  Other = 'Other',
}

export enum GuitarType {
  Electric = 'Electric',
  Acoustic = 'Acoustic',
  Mandolin = 'Mandolin',
  Ukulele = 'Ukulele',
}

export enum GuitarPickups {
  Neck = 'Neck',
  'Neck & Middle' = 'NeckMiddle',
  'Middle' = 'Middle',
  'Middle & Bridge' = 'MiddleBridge',
  Bridge = 'Bridge',
}

export const guitarSchema = instrumentSchema.extend({
  type: z.literal('guitar'),
  variant: z.nativeEnum(GuitarType).optional(),
  brand: z.nativeEnum(GuitarBrand).optional(),
  pickups: z.nativeEnum(GuitarPickups).optional(),

  strings: z.number().default(6).optional(),
  tuning: z.string().default('Standard').optional(),
})
export type Guitar = z.infer<typeof guitarSchema>

export const bassSchema = instrumentSchema.extend({
  type: z.literal('bass'),
})
export type Bass = z.infer<typeof bassSchema>

export const vocalsSchema = instrumentSchema.extend({
  type: z.literal('vocals'),
})
export type Vocals = z.infer<typeof vocalsSchema>

export const keyboardsSchema = instrumentSchema.extend({
  type: z.literal('keyboard'),
})
export type Keyboards = z.infer<typeof keyboardsSchema>

export const pianoSchema = instrumentSchema.extend({
  type: z.literal('piano'),
})
export type Piano = z.infer<typeof pianoSchema>

export const drumsSchema = instrumentSchema.extend({
  type: z.literal('drums'),
})
export type Drums = z.infer<typeof drumsSchema>

export function isPiano(node: unknown): node is Piano {
  return pianoSchema.safeParse(node).success
}
