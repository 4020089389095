import { IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material'
import { Close as CloseIcon } from '@tunasong/icons'
import { type Owned } from '@tunasong/models'
import { useProfiles } from '@tunasong/redux'
import { type ACL } from '@tunasong/schemas'
import { useCallback, useMemo, type FC } from 'react'
import { UserAvatar } from '../profile/index.js'

export interface ACLListProps {
  entity?: Owned
  /** Principal list that is pending a server operation */
  pendingPrincipals?: string[]
  onDelete?(acl: ACL): void
}

/** Show the ACL list for an entity */
export const ACLList: FC<ACLListProps> = props => {
  const { entity, pendingPrincipals = [], onDelete } = props
  const { acls = [], userId } = entity ?? {}
  const userIds = useMemo(() => acls.map(acl => acl.principal), [acls])
  const { profiles } = useProfiles({ userIds, includeAssistant: false })

  const handleDelete = useCallback((a: ACL) => () => onDelete ? onDelete(a) : null, [onDelete])

  if (acls.length === 0) {
    return null
  }

  return (
    <List>
      {acls.some(a => a.principal === 'PUBLIC') ? (
        <ListItem>
          <ListItemIcon>
            <UserAvatar aria-label="user" userId="PUBLIC" />
          </ListItemIcon>
          <ListItemText primary="Public" secondary="Anyone can view" />
        </ListItem>
      ) : null}
      {acls
        .filter(acl => acl.principal && acl.principal !== userId && acl.principal !== 'PUBLIC') // Don't show owner in shared list
        .map((a, idx) => {
          const profile = profiles?.find(p => p.userId === a.principal)
          const id = profile ? profile.email : `Loading ${a.principal}...`
          return (
            <ListItem key={idx}>
              <ListItemIcon>
                <UserAvatar aria-label="user" userId={profile?.userId} />
              </ListItemIcon>
              <ListItemText primary={id} secondary={a.permission} />
              {onDelete ? (
                <ListItemSecondaryAction>
                  <IconButton aria-label="close" onClick={handleDelete(a)}>
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              ) : null}
            </ListItem>
          )
        })}
      {pendingPrincipals.map((a, idx) => (
        <ListItem key={idx}>
          <ListItemIcon>
            <UserAvatar aria-label="user" fallbackName={a} />
          </ListItemIcon>
          <ListItemText primary={a} secondary="Pending..." />
        </ListItem>
      ))}
    </List>
  )
}

export default ACLList
