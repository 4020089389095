// Generated by ts-to-zod
import { z } from 'zod'

export const audioEventBaseSchema = z.object({
  type: z.string(),
  start: z.number(),
  end: z.number().optional(),
  name: z.string(),
})
export type AudioEventBase = z.infer<typeof audioEventBaseSchema>

export const loopEventSchema = audioEventBaseSchema.extend({
  type: z.literal('loop'),
  end: z.number(),
})
export type LoopEvent = z.infer<typeof loopEventSchema>
export function isLoopEvent(obj: unknown): obj is LoopEvent {
  return loopEventSchema.safeParse(obj).success
}

export const noteEventSchema = audioEventBaseSchema.extend({
  type: z.literal('note'),
})
export function isNoteEvent(obj: unknown): obj is NoteEvent {
  return noteEventSchema.safeParse(obj).success
}
export type NoteEvent = z.infer<typeof noteEventSchema>

export const chordEventSchema = audioEventBaseSchema.extend({
  type: z.literal('chord'),
})
export function isChordEvent(obj: unknown): obj is ChordEvent {
  return chordEventSchema.safeParse(obj).success
}
export type ChordEvent = z.infer<typeof chordEventSchema>

export const scaleEventSchema = audioEventBaseSchema.extend({
  type: z.literal('scale'),
})
export function isScaleEvent(obj: unknown): obj is ScaleEvent {
  return scaleEventSchema.safeParse(obj).success
}
export type ScaleEvent = z.infer<typeof scaleEventSchema>

export const markerEventSchema = audioEventBaseSchema.extend({
  type: z.literal('marker'),
})
export function isMarkerEvent(obj: unknown): obj is MarkerEvent {
  return markerEventSchema.safeParse(obj).success
}
export type MarkerEvent = z.infer<typeof markerEventSchema>

export const onsetEventSchema = audioEventBaseSchema.extend({
  type: z.literal('onset'),
})
export function isOnsetEvent(obj: unknown): obj is OnsetEvent {
  return onsetEventSchema.safeParse(obj).success
}
export type OnsetEvent = z.infer<typeof onsetEventSchema>

export const chromaSchema = z.object({
  C: z.number(),
  Csharp: z.number(),
  D: z.number(),
  Dsharp: z.number(),
  E: z.number(),
  F: z.number(),
  Fsharp: z.number(),
  G: z.number(),
  Gsharp: z.number(),
  A: z.number(),
  Asharp: z.number(),
  B: z.number(),
})

export const chromaEventSchema = audioEventBaseSchema.extend({
  type: z.literal('chroma'),
  chromaType: z.union([z.literal('stft'), z.literal('cqt')]),
  values: chromaSchema,
})
export function isChromaEvent(obj: unknown): obj is ChromaEvent {
  return chromaEventSchema.safeParse(obj).success
}
export type ChromaEvent = z.infer<typeof chromaEventSchema>

export const positionEventSchema = audioEventBaseSchema.extend({
  type: z.literal('position'),
})
export function isPositionEvent(obj: unknown): obj is PositionEvent {
  return positionEventSchema.safeParse(obj).success
}
export type PositionEvent = z.infer<typeof positionEventSchema>

export const audioEventSchema = z.union([
  noteEventSchema,
  chordEventSchema,
  scaleEventSchema,
  markerEventSchema,
  onsetEventSchema,
  loopEventSchema,
  positionEventSchema,
])

export function isAudioEvent(obj: unknown): obj is AudioEvent {
  return audioEventSchema.safeParse(obj).success
}

export type AudioEvent = z.infer<typeof audioEventSchema>
