// from https://github.com/katlasik/mime-matcher/blob/master/src/index.js
// const MIME_TYPE_REGEX = /^(\*|[a-z0-9._\-]+)\/(\*|[a-z0-9._\-]+)(?:; ([a-zA-Z0-9._\-=]+))?$/
const MIME_TYPE_REGEX = /^(\*|[a-z0-9._\-]+)\/(\*|[a-z0-9._\-]+)(?:; ([a-zA-Z0-9._\-=]+))?(?:\+([a-zA-Z0-9._\-]+))?$/

type Matcher = (val?: string) => boolean

function createMatcher(expected?: string): Matcher {
  if (expected === '*') {
    return () => true
  } else {
    return actual => actual === expected
  }
}

function parse(mimeType: string) {
  if (!mimeType) {
    return {
      valid: false,
    }
  }

  const match = mimeType.match(MIME_TYPE_REGEX)
  const valid = match !== null

  if (!valid) {
    return {
      valid: false,
    }
  }

  const [, type, subType, parameter] = Array.from(match)
  return {
    valid,
    type,
    subType,
    parameter,
  }
}

class MimeMatcher {
  expected: { typeMatcher: Matcher; subTypeMatcher: Matcher }[] = []

  constructor(...expected: string[]) {
    this.expected = expected.map(mimeType => {
      const { valid, type, subType } = parse(mimeType)
      if (valid) {
        return {
          typeMatcher: createMatcher(type),
          subTypeMatcher: createMatcher(subType),
        }
      } else {
        const msg = `Value "${mimeType}" is not valid mime type.It should have format "type/subtype".`
        throw new TypeError(msg)
      }
    })
  }

  match(actual: string) {
    const { valid, type, subType } = parse(actual)
    if (valid) {
      return this.expected.some(({ typeMatcher, subTypeMatcher }) => typeMatcher(type) && subTypeMatcher(subType))
    } else {
      return false
    }
  }
}

export { MimeMatcher }
