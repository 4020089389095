import { z } from 'zod'
import { baseEntitySchema } from '../entity.js'
import { storageSchema } from '../interfaces/storage.js'

export const pdfSchema = baseEntitySchema.extend({
  type: z.literal('pdf'),
  storage: storageSchema.optional(),
})

export type PDF = z.infer<typeof pdfSchema>

export function isPdf(node?: unknown): node is PDF {
  return pdfSchema.safeParse(node).success
}
