import { getCaretClientRect } from './cursor.js'
import { type VirtualElement } from '@popperjs/core'

/** Get a "fake" anchorEl for the element at the cursor when it is called */
export const getCursorStaticAnchorEl = (): VirtualElement | null => {
  const selection = window.getSelection()
  if (!selection) {
    return null
  }

  const rect = getCaretClientRect(selection)

  return {
    getBoundingClientRect: () => rect,
  }
}
