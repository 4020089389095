import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { logger } from '@tunasong/models'
import { type Entity, type Persisted } from '@tunasong/schemas'

export interface EntitiesState {
  eTags: { [id: string]: string | null }
  receivedEntities: Persisted<Entity>[]
}

// Define the initial state using that type
export const initialState: EntitiesState = {
  eTags: {},
  receivedEntities: [],
}

export const entitiesSlice = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    /** We use eTags to avoid overwriting metadata on Entities. Most editing is through an YDoc, which does not need any sync */
    setEtag: (state, action: PayloadAction<{ id: string; eTag: string }>) => {
      state.eTags[action.payload.id] = action.payload.eTag
    },
    /** Receive the entity from the backend */
    receiveEntity: (state, action: PayloadAction<{ entity: Persisted<Entity>; eTag?: string | null }>) => {
      /** @no-op - handle this action in entitiesApi */
      logger.debug('Received entity', action.payload.entity)
      state.receivedEntities = [...state.receivedEntities, action.payload.entity]
    },
    receivePartialEntity: (state, action: PayloadAction<Partial<Entity> & { id: string }>) => {
      /**
       * @no-op - we handle this in entities-middleware
       */
      logger.debug('Received partial entity', action.payload)
    },
  },
})
