import { Box, type BoxProps, LinearProgress, Typography } from '@mui/material'
import { type FC, type ReactNode } from 'react'

interface ProgressOnLoadProps extends BoxProps {
  loading?: boolean
  title?: string
  Component?: FC
  children: ReactNode
}

/** Show a progress bar on load. @default Component is LinearProgress */
export const ProgressOnLoad: FC<ProgressOnLoadProps> = props => {
  const { loading = false, title, Component = LinearProgress } = props
  return loading ? (
    <Box sx={props.sx}>
      <Component />
      {title ? (
        <Typography sx={{ textAlign: 'center' }} variant="caption">
          {title}
        </Typography>
      ) : null}
    </Box>
  ) : (
    <Box sx={props.sx}>{props.children}</Box>
  )
}

export default ProgressOnLoad
