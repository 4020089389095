import { type Entity } from '@tunasong/schemas'
import type { Persisted } from '@tunasong/schemas'
import Fuse from 'fuse.js'

export const entitySearch =
  <T extends Entity>(entities: Persisted<T>[]) =>
  (query: string) => {
    if (!query) {
      return entities
    }
    /** See https://fusejs.io/ */
    const options: Fuse.IFuseOptions<Persisted<T>> = {
      shouldSort: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      // maxPatternLength: 32,
      minMatchCharLength: 0,
      keys: [
        { name: 'name', weight: 0.7 },
        { name: 'description', weight: 0.2 },
        { name: 'tags', weight: 1.0 },
        { name: 'metadata.chords', weight: 0.5 },
      ],
    }

    const fuse = new Fuse(entities, options)
    return fuse.search(query).map(r => r.item)
  }
