import { z } from 'zod'
import baseQuery from './base-query.js'
import { createApi } from '@reduxjs/toolkit/query/react'

export const workflowInputSchema = z.object({
  /** The userId the job is running for. */
  userId: z.string(),
})
export type WorkflowInput = z.infer<typeof workflowOutputSchema>

export const workflowOutputSchema = workflowInputSchema.extend({
  name: z.string(),
  status: z.enum(['ABORTED', 'FAILED', 'RUNNING', 'SUCCEEDED', 'TIMED_OUT', 'PENDING_REDRIVE']),
  startDate: z.string().datetime(),
  stopDate: z.string().datetime(),
  /** Extend this */
  input: workflowInputSchema.passthrough(),
  output: z.unknown(),
})
export type WorkflowOutput = z.infer<typeof workflowOutputSchema>

export const exportWorkflowOutputSchema = workflowOutputSchema.extend({
  url: z.string(),
})
export type ExportWorkflowOutput = z.infer<typeof exportWorkflowOutputSchema>

// Define a service using a base URL and expected endpoints
export const workflowsApi = createApi({
  reducerPath: 'workflows-api',
  baseQuery,
  tagTypes: ['ExportWorkflows', 'ImportEnexWorkflows'],
  endpoints: builder => ({
    export: builder.mutation<void, void>({
      invalidatesTags: ['ExportWorkflows'],
      query: () => ({
        method: 'POST',
        url: `workflows/export`,
        body: {},
      }),
    }),
    importEnex: builder.mutation<
      void,
      {
        name: string
        parentId?: string
        enexStorageKey: string
      }
    >({
      invalidatesTags: ['ImportEnexWorkflows'],
      query: params => ({
        method: 'POST',
        url: `workflows/import-enex`,
        body: params,
      }),
    }),
    getExports: builder.query<ExportWorkflowOutput[], void>({
      providesTags: ['ExportWorkflows'],
      query: () => `workflows/export`,
    }),
  }),
})
