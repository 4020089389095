import * as React from 'react'
import cn from 'classnames'
import useStyles from './icon.styles.js'

function GuitarTab(props: { className?: string }) {
  const { className, ...restProps } = props
  const { classes } = useStyles()
  return (
    <svg className={cn(classes.root, className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...restProps}>
      <path
        fill="none"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M14 10.5a1.5 1.5 0 01-1.5 1.5h0a1.5 1.5 0 01-1.5-1.5v-3A1.5 1.5 0 0112.5 6h0A1.5 1.5 0 0114 7.5"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M11 10.5A1.5 1.5 0 0112.5 9h0a1.5 1.5 0 011.5 1.5M14 24.5a1.5 1.5 0 01-1.5 1.5h0a1.5 1.5 0 010-3h0a1.5 1.5 0 011.5 1.5zM14 21.5a1.5 1.5 0 01-1.5 1.5h0a1.5 1.5 0 010-3h0a1.5 1.5 0 011.5 1.5zM17 6h10M5 6h3M17 26h10M5 26h3M5 16h22M17 11h10M5 11h3M17 21h10M5 21h3"
      />
    </svg>
  )
}

export default GuitarTab
