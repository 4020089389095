/** @see https://css-tricks.com/using-requestanimationframe-with-react-hooks/ */

import { useCallback, useEffect, useRef } from 'react'

/** Run function in a loop at each available animation frame */
export const useAnimationFrameLoop = (callback: (deltaTime: number) => void) => {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = useRef<number>(0)
  const previousTimeRef = useRef<number>(0)

  const animate = useCallback(
    (time: number) => {
      if (previousTimeRef.current) {
        const deltaTime = time - previousTimeRef.current
        callback(deltaTime)
      }
      previousTimeRef.current = time
      requestRef.current = requestAnimationFrame(animate)
    },
    [callback]
  )

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate)
    return () => {
      cancelAnimationFrame(requestRef.current)
      requestRef.current = 0
    }
  }, [animate]) // Make sure the effect runs only once
}
