import { z } from 'zod'

/** @todo this is lax because we have unversioned entities in the database */
export const versionSchema = z.object({
  id: z.string(),
  version: z.number().optional().default(0),
  versionAt: z.string().datetime().optional(),
})

export type Versioned<T> = z.infer<typeof versionSchema> & T

export function isVersioned<T>(item: T): item is z.infer<typeof versionSchema> & T {
  const result = versionSchema.safeParse(item)
  return Boolean(result.success && result.data.version && result.data.version > 0)
}
