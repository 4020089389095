import { isEntity } from '@tunasong/schemas'
import { type SortOptions } from '../element/index.js'

function hasOwnProperty<X, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return (obj as Record<string, unknown>).hasOwnProperty(prop)
}

export const elementSort = <T extends Record<string, unknown>>(items: T[] = [], options?: SortOptions) =>
  [...items].sort((a: T, b: T) => {
    const {
      order = 'desc',
      elementOrder = [],
      sortBy = 'updatedAt',
      type = 'field',
      ignoreStars = true,
    } = options ?? {}

    if (!ignoreStars) {
      const aStarred = Boolean(isEntity(a) && a.starred)
      const bStarred = Boolean(isEntity(b) && b.starred)
      /** Handle starred items */
      if (aStarred && !bStarred) {
        return -1
      } else if (!aStarred && bStarred) {
        return 1
      } else if (aStarred && bStarred) {
        return 0
      }
    }
    /**
     * Field sort
     * @todo more sophisticated type guard here
     * */
    if (type === 'field' && hasOwnProperty(a, sortBy) && hasOwnProperty(b, sortBy)) {
      const compA = a[sortBy] as string
      const compB = b[sortBy] as string

      if (compA === compB) {
        return 0
      }

      if (order === 'desc') {
        return compA > compB ? -1 : 1
      }
      return compA < compB ? -1 : 1
    }
    /** Use element order instead */

    let elA = typeof a.id === 'string' ? elementOrder.indexOf(a.id) : Number.MAX_SAFE_INTEGER
    elA = elA >= 0 ? elA : Number.MAX_SAFE_INTEGER
    let elB = typeof b.id === 'string' ? elementOrder.indexOf(b.id) : Number.MAX_SAFE_INTEGER
    elB = elB >= 0 ? elB : Number.MAX_SAFE_INTEGER
    return elA === elB ? 0 : elA < elB ? -1 : 1
  })

export default elementSort
