import { Button, Dialog, DialogActions, DialogContent, TextField, Typography, type DialogProps } from '@mui/material'
import { isUrl } from '@tunasong/models'
import { clientApi, useEntityGraph, useThunkDispatch } from '@tunasong/redux'
import { type Entity, type Link as LinkType, type Persisted } from '@tunasong/schemas'
import { useState, type FC, type MouseEvent } from 'react'
import { DialogTitle } from '../dialog/dialog-title.js'
import { VBox } from '../layout/vertical-box.js'

export interface BookmarkDialogProps extends Omit<DialogProps, 'onClose'> {
  parent: Persisted<Entity>
  type: 'internal' | 'external'
  onClose: (ev: MouseEvent) => void
}

export const BookmarkDialog: FC<BookmarkDialogProps> = props => {
  const { parent, type, onClose, open, ...restProps } = props

  const dispatch = useThunkDispatch()
  const { createEntity, isCreatingEntity } = useEntityGraph()

  const [externalLink, setExternalLink] = useState('')

  const isValid = type === 'external' ? isUrl(externalLink) : false

  const createLinkBookmark = async (ev: MouseEvent) => {
    if (isCreatingEntity) {
      return
    }
    ev.preventDefault()

    // Check type of link
    if (externalLink) {
      const { title, description, icon } = await dispatch(
        clientApi.endpoints.getUrlPreview.initiate({ url: externalLink })
      ).unwrap()
      const entity = {
        type: 'link',
        name: title,
        url: externalLink,
        description,
        icon,
      } satisfies LinkType

      await createEntity({
        parent,
        entity,
      })
      onClose(ev)
      return
    }
  }

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={onClose} {...restProps}>
      <DialogTitle onClose={onClose} title="Create new bookmark" />
      <DialogContent>
        {type === 'internal' ? (
          <VBox>
            {/* <FullSearch
              onOpen={e => {
                onSelected(e)
              }}
              defaultFilter={defaultFilter}
              onClose={onCanceled}
              autoFocus={false}
            /> */}
          </VBox>
        ) : null}

        {type === 'external' ? (
          <VBox>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              External link
            </Typography>
            <TextField label="URL" fullWidth value={externalLink} onChange={ev => setExternalLink(ev.target.value)} />
          </VBox>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button disabled={!isValid || isCreatingEntity} onClick={createLinkBookmark} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BookmarkDialog
