import { z } from 'zod'
import { baseEntitySchema } from '../entity.js'
import { imageSizeTypes } from '../interfaces/sizes.js'
import { storageSchema, storageUrlsSchema } from '../interfaces/storage.js'

export const imageSchema = baseEntitySchema.extend({
  type: z.literal('image'),
  url: z.string().optional(),
  /** Thumbnail URLs */
  size: z.enum(imageSizeTypes).optional(),
  // when explicitly set with through resize, it will be a number
  width: z.union([z.number(), z.string()]).optional(),
  align: z.enum(['left', 'center', 'right']).optional(),
  rotation: z.number().default(0).optional(),
  storage: storageSchema.optional(),
  storageUrls: storageUrlsSchema
    .extend({
      sizes: z.record(z.enum(imageSizeTypes), z.string()).optional(),
    })
    .optional(),
})

/** @deprecated use Image instead */
export type ImageElement = z.infer<typeof imageSchema>
export type Image = ImageElement

export function isImage(node?: unknown): node is ImageElement {
  return imageSchema.safeParse(node).success
}
