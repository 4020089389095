import { z } from 'zod'
import { noteNameSchema } from './note.js'

const modes = ['ionian', 'dorian', 'phrygian', 'lydian', 'mixolydian', 'aeolian', 'locrian'] as const

const keys = ['major', 'minor', 'harmonicminor', ...modes] as const
const keyTypeSchema = z.enum(keys)

export const modeSchema = z.enum(modes)
export const scaleSchema = z.object({
  root: noteNameSchema,
  type: keyTypeSchema.optional(),
})

export type Scale = z.infer<typeof scaleSchema>
export type Key = Scale

export type ModeType = z.infer<typeof modeSchema>
export type KeyType = z.infer<typeof keyTypeSchema>
