import { useEntityUpdate } from '@tunasong/redux'
import { type EntityOrElement } from '@tunasong/schemas'
import { useMemo } from 'react'

export const useTags = (entity?: EntityOrElement) => {
  const { tags, systemTags } = useMemo(() => {
    const allTags = entity?.tags ?? []
    const systemTags = new Set(allTags.filter(tag => tag.startsWith('system:')))
    const tags = new Set(allTags.filter(tag => !tag.startsWith('system:')))
    return { tags, systemTags }
  }, [entity?.tags])

  const updateEntity = useEntityUpdate()
  const hasTag = (tag: string) => tags.has(tag) || systemTags.has(tag)
  const addTag = (tag: string) => {
    if (!entity?.id || hasTag(tag)) {
      return
    }
    updateEntity(entity.id, { tags: [...tags, tag] })
  }
  const removeTag = (tag: string) => {
    if (!entity?.id || !hasTag(tag)) {
      return
    }
    updateEntity(entity.id, { tags: [...tags].filter(t => t !== tag) })
  }

  return { tags, systemTags, addTag, removeTag, hasTag }
}
