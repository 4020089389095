import { Typography, type Theme } from '@mui/material'
import classNames from 'classnames'
import { type FC } from 'react'
import { makeStyles } from '../styles.js'
import { Hotkey } from './hotkey.js'
import { makeLabel } from './make-label.js'
import { type Shortcut } from './shortcut.js'

interface ShortcutBoxProps {
  className?: string
  shortcut: Shortcut
  showDescription?: boolean
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  description: {
    marginLeft: theme.spacing(),
  },
}))

const ShortcutBox: FC<ShortcutBoxProps> = props => {
  const { className, shortcut, showDescription = true } = props
  const { classes } = useStyles()
  if (!shortcut) {
    return null
  }
  const { description } = shortcut
  return (
    <div className={classNames(className, classes.root)}>
      <Hotkey>{makeLabel(shortcut.hotkey)}</Hotkey>
      {showDescription && (
        <Typography variant="subtitle1" className={classes.description}>
          {description}
        </Typography>
      )}
    </div>
  )
}

export default ShortcutBox
