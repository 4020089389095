import { useEffect, useState } from 'react'

export const useCachedValue = <T>(value: T) => {
  /** Local value for fast performance */
  const state = useState<T>(value)
  const [, setLocalValue] = state
  useEffect(() => {
    setLocalValue(value)
  }, [setLocalValue, value])
  return state
}
