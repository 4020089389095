import { useMemo } from 'react'
import { entitiesApi } from '../api/index.js'
import { profilesApi } from '../api/profiles.js'
import { assistantProfile } from './profile-assistant.js'
import { useSelector } from './selector.hook.js'

export const useCollaborators = (entityId?: string) => {
  const userId = useSelector(state => state.user.userId)
  const entitySelector = entityId ? entitiesApi.endpoints.loadEntity.select({ id: entityId }) : null
  const entity = useSelector(state => (entitySelector ? entitySelector(state).data : null))

  const users = useMemo(
    () => [userId, ...(entity?.acls ?? []).map(a => a.principal)].filter(Boolean) as string[],
    [entity, userId]
  )

  const { currentData: loadedProfiles = [] } = profilesApi.useLoadProfilesQuery({ userIds: users })

  const profiles = useMemo(
    () => [
      assistantProfile,
      ...loadedProfiles.map(p => ({ ...p, type: 'profile' })).filter(p => (entity ? users.includes(p.userId) : true)),
    ],
    [entity, loadedProfiles, users]
  )

  /** If entity is known, we use the ACL information. Otherwise we'll use the profiles in our store */
  return profiles
}
