import * as z from 'zod'
import { todoSchema } from '../../core/entities/todo.js'
import { baseEntitySchema } from '../../core/entity.js'
import { audioTrackSchema } from '../audio/audio-track.js'
import { audioEventSchema } from '../audio/event.js'
import { instrumentSchema } from '../music/instrument.js'
import { rhythmSchema } from '../music/rhythm.js'
import { scaleSchema } from '../music/scale.js'

export interface SongMetadata {
  [name: string]: string | string[] | undefined
  text?: string
  chords?: string[] // Chord names
}

export const songSchema = baseEntitySchema.extend({
  type: z.literal('song'),
  parentId: z.string().optional(),
  sectionType: z.literal('Song').optional(),
  name: z.string().optional(),
  instruments: z.array(instrumentSchema).optional(),
  todos: z.array(todoSchema).optional(),
  /** Metadata of the children content. Used for easy display, search and so on. */
  metadata: z.record(z.unknown()).optional(),

  rhythm: rhythmSchema.optional(),
  scale: z.union([scaleSchema, z.null()]).optional(),

  /** DAW */
  tracks: z.array(audioTrackSchema).optional(),
  events: z.array(audioEventSchema).optional(),
})

export type Song = z.infer<typeof songSchema>

export function isSong(node?: unknown): node is Song {
  return songSchema.safeParse(node).success
}
