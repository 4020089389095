import { useCallback, useMemo } from 'react'
import { assistantProfile } from './profile-assistant.js'
import { profilesApi } from '../api/profiles.js'
import { useSelector } from './selector.hook.js'

export const useProfiles = ({
  userIds = [],
  includeAssistant = true,
}: {
  userIds?: string[]
  includeAssistant?: boolean
} = {}) => {
  const { currentData: rawProfiles, isLoading } = profilesApi.useLoadProfilesQuery(
    { userIds },
    { skip: userIds.length === 0 }
  )

  const profiles = useMemo(
    () => [includeAssistant ? assistantProfile : null, ...(rawProfiles ?? [])].filter(Boolean),
    [includeAssistant, rawProfiles]
  )

  const me = useSelector(state => state.user.userId)

  const getName = useCallback(
    (id: string) => {
      if (!id) {
        return 'Unknown'
      }
      if (id === me) {
        return 'You'
      }
      const profile = profiles.find(profile => profile?.id === id)
      return profile ? profile.name || profile.email : '...'
    },
    [me, profiles]
  )

  return {
    profiles,
    isLoading,
    getName,
  }
}
