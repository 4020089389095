import { AlertTitle, Button, Alert as MuiAlert, Snackbar, type SnackbarProps } from '@mui/material'
import { features, useAncestors, useSelector, useThunkDispatch } from '@tunasong/redux'
import { type FC, useCallback, useEffect, useState } from 'react'
import { getPathFromPathOrUrl } from '../navigation/get-path.js'
import { Link } from '../navigation/link.js'
import { useNavigate } from '../navigation/navigate.js'

export interface AlertProps extends SnackbarProps {
  className?: string
}

export const Alert: FC<AlertProps> = props => {
  const { autoHideDuration = 10000, ...restProps } = props
  const dispatch = useThunkDispatch()
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const entityId = useSelector(state => state.presence.activeEntityId)
  const alert = useSelector(state => state.notifications.alert)
  const ancestors = useAncestors({ parentId: entityId ?? undefined })

  useEffect(() => {
    const alertEntityId = alert?.entity?.id ?? null
    if (!alert) {
      return
    }
    if (entityId && (alertEntityId === entityId || alert.entity?.parentId === entityId)) {
      // Don't show alerts for entities that are active
      return
    }
    setOpen(true)
  }, [alert, ancestors, entityId])

  const handleClose = useCallback(() => {
    setOpen(false)
    dispatch(features.notifications.actions.setAlert(null))
  }, [dispatch])

  const nav = useCallback(
    (href: string) => () => {
      navigate(getPathFromPathOrUrl(href))
      setOpen(false)
    },
    [navigate]
  )

  if (!alert) {
    return null
  }

  const { severity, message, title, duration = autoHideDuration, actions, link } = alert

  const actionButtons =
    actions?.map(({ title, action }, idx) => (
      <Button key={idx} onClick={nav(action)}>
        {title}
      </Button>
    )) ?? []
  const action = <>{actionButtons}</>

  return (
    <Snackbar
      sx={{
        '& > * + *': {
          mt: 2,
        },
      }}
      open={open}
      autoHideDuration={duration}
      onClick={handleClose}
      onClose={handleClose}
      {...restProps}
    >
      <MuiAlert severity={severity} action={action}>
        {title ? <AlertTitle>{title}</AlertTitle> : null}
        {link ? <Link to={link}>{message}</Link> : message}
      </MuiAlert>
    </Snackbar>
  )
}

export default Alert
