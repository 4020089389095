import { logger } from '@tunasong/models'
import { type Entity, type Persisted } from '@tunasong/schemas'
import { Mutex } from 'async-mutex'
import { useCallback, useRef } from 'react'
import { entitiesApi } from '../api/entities.js'
import { useStore } from '../configure-store.js'
import { useThunkDispatch } from './thunk-dispatch.hook.js'
import type { Draft } from 'immer'

interface EntityUpdate {
  /** Debounce delay before sending changes to the API in MS. @default 0 */
  debounceDelay?: number
  /** Use upsert, i.e., create the entity if it does not exist */
  upsert?: boolean
}
/**
 * Update an entity in Redux and update the server (debounced).
 * We accept only one outstanding request per entity hook.
 */

// We use a mutex to ensure only one update is running at a time for a single entity. We do this
// to avoid eTag problems.
const entityMutexes = new Map<string, Mutex>()

export const useEntityUpdate = <T extends Entity = Entity>({
  debounceDelay = 0,
  upsert = false,
}: EntityUpdate = {}) => {
  const dispatch = useThunkDispatch()
  const [updateMutation] = entitiesApi.useUpdateEntityMutation()
  const { getState } = useStore()

  const timoutId = useRef<number | NodeJS.Timeout | null>(null)
  const updates = useRef<Partial<Entity> | null>(null)
  /** We debounce */
  const update = useCallback(
    (entityId: string, partialEntity: Partial<T>) => {
      if (!entityId) {
        logger.warn('No entity id provided to useEntityUpdate')
        return
      }

      const updatedAt = new Date().toISOString()

      // if partial entity is empty, we touch the entity in redux
      if (Object.keys(partialEntity).length === 0) {
        dispatch(
          entitiesApi.util.updateQueryData('loadEntity', { id: entityId }, draft => {
            draft.updatedAt = updatedAt
          })
        )
        const parentId = entitiesApi.endpoints.loadEntity.select({ id: entityId })(getState())?.data?.parentId
        // Update the parent entity if it exists
        if (parentId) {
          const updateFn = (draft: Draft<Persisted<Entity>[]>) => {
            const e = draft.find(e => e.id === entityId)
            if (!e) {
              return
            }
            e.updatedAt = updatedAt
          }
          dispatch(entitiesApi.util.updateQueryData('loadChildEntities', { parentId, networkFirst: false }, updateFn))
          dispatch(entitiesApi.util.updateQueryData('loadChildEntities', { parentId, networkFirst: true }, updateFn))
        }
        // We don't need to update the server
        return
      }

      let entityMutex = entityMutexes.get(entityId)
      if (!entityMutex) {
        entityMutex = new Mutex()
        entityMutexes.set(entityId, entityMutex)
      }

      /** Optimistic update of the cache immediately */

      dispatch(
        entitiesApi.util.updateQueryData('loadEntity', { id: entityId }, draft => ({
          ...draft,
          ...partialEntity,
          updatedAt,
        }))
      )

      if (timoutId.current) {
        clearTimeout(timoutId.current)
      }

      /** Accumulate changes before we apply them */
      updates.current = { ...updates.current, ...partialEntity, updatedAt }

      timoutId.current = setTimeout(() => {
        // Ensure we only run one update at a time to avoid eTag issues
        entityMutex.runExclusive(async () => {
          const accumulatedUpdates = updates.current
          /** Since we timeout and runExclusive, there may be no updates here. That's OK */
          if (!accumulatedUpdates) {
            return
          }
          /** Reset the updates object, so we can start to accumulate new changes */
          updates.current = null
          /**
           * @note we don't update Redux here because it is delayed and
           * we want to avoid overwriting Redux with stale data.
           * However, the Service Worker will update the Redux cache,
           * so it's a bit unclear what the best approach is here.
           */

          const eTag = getState().entities.eTags[entityId]  
          await updateMutation({ id: entityId, partialEntity: accumulatedUpdates, eTag, upsert }).unwrap()
        })
      }, debounceDelay)
    },
    [debounceDelay, dispatch, getState, updateMutation, upsert]
  )
  return update
}
