/** Get the element ref from the URL */

import { useEffect, useState } from 'react'
import queryString from 'query-string'
import { isString } from '@tunasong/models'

export const useElementRef = () => {
  const [ref, setRef] = useState<string>()

  const search = typeof document !== 'undefined' ? document?.location?.search : null

  useEffect(() => {
    if (!search) {
      return
    }
    const params = queryString.parse(search)
    const ref = isString(params.ref) ? params.ref : undefined
    setRef(ref)
  }, [search])

  return ref
}
