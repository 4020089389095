/** Link helpers */

import type { Entity } from '@tunasong/schemas'

const localBaseUrl = 'http://localhost:8000'

const baseUrl = 'https://tunasong.com'
const appUrl = `${baseUrl}/app`

export const getLink = (entity: Pick<Entity, 'id' | 'parentId' | 'type'>) => {
  if (!entity) {
    return appUrl
  }
  const { id, parentId } = entity

  switch (entity.type) {
    /** We use the parent for chat messages */
    case 'comment':
      return `${appUrl}/entities/${parentId}?ref=${id}`
    default:
      return `${appUrl}/entities/${id}`
  }
}

export const isTunaLink = (url: string) => Boolean(url.startsWith(baseUrl) || url.startsWith(localBaseUrl))
