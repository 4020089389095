import { type Color, colors } from '@mui/material'
import { type ColorName } from '@tunasong/schemas'

const excludeColors = ['grey', 'blueGrey']

export const colorNames = [...Object.keys(colors).filter(c => c !== 'common')]
  .filter(c => !excludeColors.includes(c))
  .sort()
  .reverse() as readonly ColorName[]

export const getColor = (colorName: ColorName): Color => colors[colorName]
