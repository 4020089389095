import { z } from 'zod'
import { aclSchema } from './acl.js'
/** Zod schema for Persisted */

export const persistedSchema = z.object({
  id: z.string(),
  userId: z.string(),
  acls: z.array(aclSchema).optional(),
  lastEditedBy: z.string().optional(),
  trash: z.boolean().optional().describe('The element is in the trash.'),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
})

export type Persisted<T> = z.infer<typeof persistedSchema> & T

export function isPersisted<T>(item?: T): item is Persisted<T> {
  return persistedSchema.safeParse(item).success
}
