/* eslint-disable @typescript-eslint/no-explicit-any */
import { SyncElement } from './model.js'

export function toSlateNode(element: any) {
  const text = SyncElement.getText(element)
  const children = SyncElement.getChildren(element)
  const node: Record<string, unknown> = {}
  if (text !== undefined) {
    node.text = text.toString()
  }
  if (children !== undefined) {
    node.children = children.map(toSlateNode)
  }
  for (const [key, value] of element.entries()) {
    if (key !== 'children' && key !== 'text') {
      node[key] = value
    }
  }
  return node
}

export function toSlateDoc(doc: any) {
  return doc.map(toSlateNode)
}
