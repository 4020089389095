import type { Entity } from '@tunasong/schemas'
import { useCallback } from 'react'
import { entitiesApi, type StartKey } from '../api/entities.js'
import { type EntitiesParams } from './entities.js'
import { useWithIncremental, type QueryFn } from './with-incremental.hook.js'

export interface IncrementalOptions extends EntitiesParams {
  pageSize: number
  startKey?: StartKey
  trash?: boolean
}

/** Hook to allow incremental loading of entities */
export const useEntitiesInc = <T extends Entity = Entity>(props: IncrementalOptions) => {
  const { pageSize, startKey, ...restProps } = props

  const [load, loadResult] = entitiesApi.useLazyLoadAllEntitiesPartialQuery()

  const onLoad: QueryFn<T> = useCallback(
    async ({ limit, startKey }: { limit: number; startKey?: string }) => {
      const data = await load({ ...restProps, limit, startKey }).unwrap()

      return { ...data, entities: data.entities as unknown as T[] }
    },
    [load, restProps]
  )

  /** Handle incremental load hook */
  const incremental = useWithIncremental({
    onLoad,
    pageSize,
    startKey,
  })

  return {
    ...incremental,
    entities: incremental.entities as T[],
    isLoading: loadResult.isLoading,
    isFetching: loadResult.isFetching,
    isError: loadResult.isError,
    error: loadResult.error,
  }
}
