/** Trigger a callback when the visibility changes */

import { useEffect } from 'react'

export const useVisibilityTrigger = ({ el, cb }: { el?: HTMLElement | null; cb?: () => void }) => {
  useEffect(() => {
    if (!(el && cb)) {
      return
    }

    const observer = new IntersectionObserver(entries => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          cb()
        }
      }
    })

    observer.observe(el)
    return () => {
      observer.unobserve(el)
    }
  }, [cb, el])
}
