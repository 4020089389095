/** Entity container to handle entity hooks and such */

import { isPersisted, type Entity } from '@tunasong/schemas'
import type React from 'react'
import { useRunEventCommands } from './commands/index.js'

/**
 * Entity events container to handle entity hooks and such
 */
export const EntityEventsContainer = ({ entity, children }: { entity?: Entity; children: React.ReactNode }) => {
  // Run onEnter and onLeave commands
  const persistedEntity = isPersisted(entity) ? entity : undefined
  useRunEventCommands({ entity: persistedEntity })

  /** @todo Consider wrapping in a "in trash" wrapper if the entity is in the trash */

  return children
}
