import { getGlobalCommands, useGlobalAppContext, usePlugins, type Command } from '@tunasong/plugin-lib'
import { useMemo } from 'react'
import { useUserConfig } from './config-service.hook.js'

/** Commands that are available globally */
export const useGlobalCommands = ({ text }: { text?: string }): Command[] => {
  const [maturity] = useUserConfig('featureMaturity')
  const plugins = usePlugins('all')
  const globalContext = useGlobalAppContext()

  const commands = useMemo(
    () => (plugins ? getGlobalCommands({ text, plugins, globalContext, maturity }) : []),
    [globalContext, maturity, plugins, text]
  )

  return commands
}
