import { type EntityOrElement } from '@tunasong/schemas'
import { useCallback } from 'react'
import { type TunaPlugin } from '../plugin-types.js'
import { renderContent } from '../render/index.js'
import { usePlugins } from './editor-plugins.js'

interface RenderContentProps {
  plugins?: TunaPlugin[] | null
  contentView?: string
  // use the 'default' view if the element doesn't implement the specified one
  useDefault?: boolean
}

/** Render the content for element, applying all the relevant plugins. */
export const useRenderContent = ({ plugins, contentView, useDefault = true }: RenderContentProps = {}) => {
  const allPlugins = usePlugins('all')
  const activePlugins = plugins ?? allPlugins

  const render = useCallback(
    (element?: EntityOrElement) => {
      if (!(element && activePlugins)) {
        return null
      }

      const content = renderContent({ element, plugins: activePlugins, contentView, useDefault })
      return content && content.length > 0 ? <>{content}</> : null
    },
    [activePlugins, contentView, useDefault]
  )

  return { render }
}
