/** MIDI specification for a device, e.g., a pedal */
import { z } from 'zod'

export const midiSpecSchema = z.object({
  /** Program Change numbers for patches. If bank is specified, a CC 0 is sent before the PC */
  programs: z.array(
    // bank is CC 0, bankLsb is CC 32
    z.object({ name: z.string(), pc: z.number(), bank: z.number().optional(), bankLsb: z.number().optional() })
  ),
  /** Change Control numbers */
  cc: z.array(z.object({ name: z.string(), cc: z.number() })),
})

export type MidiSpec = z.infer<typeof midiSpecSchema>
