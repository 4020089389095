import { matchAll } from '@tunasong/models'
import { entitiesApi, useChild, useEntityUpdate } from '@tunasong/redux'
import {
  isEntity,
  isPersisted,
  type Entity,
  type Persisted,
  type UserSettings,
  type UserSettingsData,
} from '@tunasong/schemas'
import { useCallback, useEffect, useRef, useState } from 'react'
import invariant from 'tiny-invariant'
import { useCurrentUser } from '../user/current-user.js'

/** Get the child entity matching the filter. If there are > 1 children matching the critiera this hook will throw */
export const useUserSettings = (entity?: Persisted<Entity>) => {
  const { userId } = useCurrentUser()
  /** Settings are private, so we need to filter for the userId */
  const filter = matchAll(
    e => isEntity(e) && e.type === 'usersettings',
    e => isPersisted(e) && e.userId === userId
  )
  /** Load settings once, then just update */
  const [localUserSettings, setLocalUserSettings] = useState<UserSettingsData | null>(null)

  const { child, isSuccess, isFetching } = useChild<Persisted<UserSettings>>({
    parentId: entity?.id,
    filter,
    /** networkFirst is critical here, so that we don't create duplicates */
    networkFirst: true,
  })

  const [createEntity] = entitiesApi.useCreateEntityMutation()

  const shouldCreateSettings = isSuccess && !isFetching && !child && entity

  /** Clear settings when entity changes */
  useEffect(() => {
    setLocalUserSettings(null)
  }, [entity?.id])

  /** Update from child settings if localSettings is undefined (i.e., not initialized) */
  useEffect(() => {
    if (!isSuccess || localUserSettings !== null) {
      return
    }
    // Prioritize local settings over child settings
    // We need to avoid triggering a save here
    setLocalUserSettings(localSettings => ({ ...localSettings, ...child?.settings }))
  }, [child?.settings, isSuccess, localUserSettings])

  const isCreating = useRef(false)

  const createSettings = useCallback(
    (parent: Persisted<Entity>, settings: UserSettings['settings']) => {
      if (isCreating.current) {
        return
      }

      isCreating.current = true

      return createEntity({
        parent,
        entity: { type: 'usersettings', settings } as UserSettings,
        isPrivate: true,
      })
        .unwrap()
        .finally(() => {
          isCreating.current = false
        })
    },
    [createEntity]
  )

  const updateEntity = useEntityUpdate<UserSettings>({ debounceDelay: 100 })

  const setUserSettings = useCallback(
    async (data: Partial<UserSettingsData>) => {
      const newSettings = { ...localUserSettings, ...data }

      setLocalUserSettings(newSettings)

      if (shouldCreateSettings) {
        await createSettings(entity, newSettings)
        return
      }
      invariant(child?.id, 'Child must exist to update settings')

      updateEntity(child.id, {
        settings: newSettings,
      })
    },
    [child?.id, createSettings, entity, localUserSettings, shouldCreateSettings, updateEntity]
  )

  return [localUserSettings, setUserSettings, isSuccess] as const
}
