import { useEffect, useState } from 'react'
// from https://usehooks.com/useHover/

export function useHover<T extends HTMLElement | null = HTMLDivElement>(node?: T) {
  const [value, setValue] = useState(false)

  useEffect(() => {
    if (!node) {
      return
    }
    const handleMouseMove = () => setValue(true)
    const handleMouseOut = () => setValue(false)

    /** We use mousemove instead of mouseover to avoid triggering when content (not pointer) moves (e.g., when adding a line) */
    node.addEventListener('mousemove', handleMouseMove)
    node.addEventListener('mouseleave', handleMouseOut)

    return () => {
      setValue(false)
      node.removeEventListener('mouseleave', handleMouseMove)
      node.removeEventListener('mouseout', handleMouseOut)
    }
  }, [node])

  return value
}
