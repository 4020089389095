import { Divider, ListItem, ListItemIcon, ListItemText, MenuList, Typography } from '@mui/material'
import { hasPublicAccess, type Owned } from '@tunasong/models'
import { useProfile } from '@tunasong/redux'
import { type ACL } from '@tunasong/schemas'
import classNames from 'classnames'
import { useMemo, type FC } from 'react'
import { UserAvatar } from '../profile/index.js'
import { makeStyles } from '../styles.js'
import ACLList from './acl-list.js'

export interface ShareListProps {
  className?: string
  entity?: Owned
  /** Pending users */
  pending?: string[]
  onDelete(acl: ACL): void
}

const useStyles = makeStyles()(theme => ({
  root: {},
  shareHeader: {
    marginTop: theme.spacing(2),
  },
}))

export const ShareList: FC<ShareListProps> = props => {
  const { className, pending = [], entity, onDelete } = props
  const { classes } = useStyles()
  const acls: ACL[] = useMemo(() => entity?.acls ?? [], [entity])
  const ownerProfile = useProfile(entity?.userId)

  if (!Array.isArray(acls)) {
    return null
  }

  const ownerEmail = ownerProfile ? ownerProfile.email : 'Loading...'

  return (
    <div className={classNames(className, classes.root)}>
      <MenuList>
        <ListItem>
          <ListItemIcon>
            <UserAvatar aria-label="user" userId={entity?.userId} />
          </ListItemIcon>
          <ListItemText primary={ownerEmail} secondary="Owner" />
        </ListItem>
        {hasPublicAccess(acls) && (
          <ListItem>
            <ListItemIcon>
              <UserAvatar aria-label="public" userId="PUBLIC" />
            </ListItemIcon>
            <ListItemText primary={'Public'} secondary={'READ'} />
          </ListItem>
        )}
        <Divider />
      </MenuList>
      {acls.length > 0 && (
        <Typography className={classes.shareHeader} variant="subtitle1">
          Collaborators
        </Typography>
      )}
      <ACLList entity={entity} onDelete={onDelete} pendingPrincipals={pending} />
    </div>
  )
}

export default ShareList
