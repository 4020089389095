import { sortOptionsSchema } from '../sort.js'
import { z } from 'zod'

export const elementOptionsSchema = z.object({
  sort: sortOptionsSchema.optional(),
  /** Present the children in a list or a grid. @default is grid */
  list: z
    .object({
      type: z.enum(['list', 'grid']),
    })
    .optional(),
  /** The active view to render the element in */
  activeView: z.string().optional(),
})

export type ElementOptions = z.infer<typeof elementOptionsSchema>
