import { useEffect, useRef } from 'react'

export const useOnNavigate = (cb: () => void) => {
  const pathname = useRef<string>()
  useEffect(() => {
    pathname.current = document.location.pathname
    return () => {
      if (pathname.current === document.location.pathname) {
        return
      }
      cb()
    }
  })
}
