import { ExpandLess } from '@mui/icons-material'
import { Button, type ButtonProps } from '@mui/material'
import { ExpandMore } from '@tunasong/icons'
import React, { useState, type MouseEvent } from 'react'

export interface RevealButtonProps extends Omit<ButtonProps, 'onChange'> {
  name: string
  Icon?: JSX.Element
  children: React.ReactNode
  onShow?(): void
  onHide?(): void
}

export const RevealButton = (props: RevealButtonProps) => {
  const { name, Icon, children, onShow, onHide, ...buttonProps } = props

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleDropdownClick = (ev: MouseEvent<HTMLButtonElement>) => {
    const show = Boolean(!anchorEl)
    setAnchorEl(anchorEl => (anchorEl ? null : ev.currentTarget))
    if (show && onShow) {
      onShow()
    }
    if (!show && onHide) {
      onHide()
    }
  }

  return (
    <>
      <Button
        sx={{
          textTransform: 'none',
        }}
        onClick={handleDropdownClick}
        startIcon={Icon}
        endIcon={anchorEl ? <ExpandLess /> : <ExpandMore />}
        {...buttonProps}
      >
        {name}
      </Button>
      {anchorEl && children}
    </>
  )
}

export default RevealButton
