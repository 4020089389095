import { type EntityOrElement } from '@tunasong/schemas'
import { type FC } from 'react'

/** This is for the our plugins - no Slate-Plugin compat required */
export const condRenderComponent =
  <T extends EntityOrElement, S extends { element: T } = { element: T }>(
    predicate: (e: T) => boolean,
    Component: FC<S> | FC<S>[]
  ): FC<S> =>
  (props: S): JSX.Element | null => {
    if (!predicate(props?.element)) {
      return null
    }

    const Components = Array.isArray(Component) ? Component : [Component]
    return (
      <>
        {Components.map((Component, i) => (
          <Component key={i} {...props} />
        ))}
      </>
    )
  }
