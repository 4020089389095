/** Get UI extensions */

import { useContext } from 'react'
import { UIExtensionsContext } from './extensions.js'

export const useUIExtensions = () => {
  const extensions = useContext(UIExtensionsContext)

  return extensions ? extensions : {}
}
