/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from './styles.js'

export default makeStyles()(() => ({
  root: {
    fill: 'currentColor',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fontSize: '1.2857142857142856rem',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    flexShrink: 0,
    userSelect: 'none',
  },
}))
