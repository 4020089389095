import { isTaggedSysEntity, type NavigationEntity } from '@tunasong/models'

interface EntityPathOptions {
  hideSysEntities?: boolean
}

/** Entity path back to root */
function genEntityPath<T extends NavigationEntity = NavigationEntity>(
  e: T,
  entities: Record<string, T>,
  seenSet = new Set(),
  ignoreSysEntities = true
): T[] {
  if (!(e && entities)) {
    return []
  }
  const { id, parentId } = e

  if (seenSet.has(id)) {
    return []
  }

  seenSet.add(id)

  if (!parentId || parentId === id) {
    return [e]
  }

  const parent = entities[parentId]
  const parentPath = genEntityPath(parent, entities, seenSet)
  const isSysEntity = isTaggedSysEntity(e)
  return isSysEntity && ignoreSysEntities ? parentPath : [e, ...parentPath]
}

/** Generate the path from root - i.e., the root element is the first element */
export const entityPath = <T extends NavigationEntity = NavigationEntity>(
  e: T,
  entities: Record<string, T>,
  options: EntityPathOptions = { hideSysEntities: true }
): T[] => genEntityPath(e, entities, new Set(), options.hideSysEntities).reverse()
