import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const tools = [
  'Settings',
  'Chat',
  'Activities',
  'Notes',
  'Pool',
  'Context',
  'Suggestions',
  'Record',
  'Guitar',
  'Debug',
] as const
export type SidebarToolName = (typeof tools)[number]

// Define a type for the slice state
export interface UIState {
  sidebar: {
    open: boolean
    activeTool: SidebarToolName | null
  }
}

// Define the initial state using that type
const initialState: UIState = {
  sidebar: {
    open: false,
    activeTool: null,
  },
}

/**
 * @note if you get an Immer error here, pin the Immer version to ensure a single instance
 * @see https://github.com/reduxjs/redux-toolkit/issues/1181
 */
export const uiSlice = createSlice({
  name: 'ui',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSidebarActiveTool: (state, action: PayloadAction<SidebarToolName | null>) => {
      state.sidebar.activeTool = action.payload
      state.sidebar.open = action.payload === null ? false : true
    },
    toggleSidebarActiveTool: (state, action: PayloadAction<SidebarToolName>) => {
      state.sidebar.activeTool = state.sidebar.activeTool === action.payload ? null : action.payload
      state.sidebar.open = state.sidebar.activeTool === null ? false : true
    },

    toggleSidebar: state => {
      if (state.sidebar.activeTool === null) {
        state.sidebar.activeTool = 'Settings'
        state.sidebar.open = true
      } else {
        state.sidebar.activeTool = null
        state.sidebar.open = false
      }
    },
    setSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.sidebar.open = action.payload
    },
  },
})
