import { skipToken } from '@reduxjs/toolkit/query'
import type { Edge, EdgeType } from '@tunasong/schemas'
import { useCallback, useMemo } from 'react'
import invariant from 'tiny-invariant'
import { entitiesApi } from '../api/entities.js'

export const useEdges = ({
  sourceId,
  targetId,
  relation,
}: {
  sourceId?: string
  targetId?: string
  relation?: EdgeType
}) => {
  const {
    currentData = [],
    isSuccess,
    isLoading,
  } = entitiesApi.useLoadEntityEdgesQuery(sourceId ? { source: sourceId, target: targetId } : skipToken)
  const edges = useMemo(
    () => (relation ? currentData.filter(link => link.relation === relation) : currentData),
    [currentData, relation]
  )

  const [createEdgeMutation, createStatus] = entitiesApi.useCreateEntityEdgeMutation()
  const [deleteEdgeMutation] = entitiesApi.useDeleteEntityEdgeMutation()

  const createEdge = useCallback(
    (targetId: string, edgeType: EdgeType) => {
      if (!sourceId) {
        throw new Error('Source entity is required')
      }
      return createEdgeMutation({
        edge: {
          source: sourceId,
          target: targetId,
          relation: edgeType,
        },
      })
    },
    [createEdgeMutation, sourceId]
  )

  const deleteEdge = useCallback(
    (edge: Edge) => {
      invariant(sourceId && targetId, 'Source and target entity is required')
      return deleteEdgeMutation(edge)
    },
    [deleteEdgeMutation, sourceId, targetId]
  )

  return { edges, hasLoaded: isSuccess, isLoading, isCreating: createStatus.isLoading, createEdge, deleteEdge }
}
