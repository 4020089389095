import { useCallback, useRef } from 'react'
import type { ZodSchema } from 'zod'
import type { CDialogProps } from './dialog.js'
import { useGlobalFormDialog } from './form-dialog-store.js'
import { FormDialog as FormDialogComponent } from './form-dialog.js'

/** Simple dialog for the form that handles the open/close state
 *
 * Usage:
 *
 * const { ValueDialog, showModal } = useValueDialog()
 * someFunction = async () => {
 *  const value = await showDialog()
 * }
 * return (
 *  <ValueDialog />
 * )
 */
export const useFormDialog = () => {
  const value = useRef<unknown>(null)

  const { setFormDialog } = useGlobalFormDialog()

  const closePromise = useRef<Promise<unknown>>()
  const resolve = useRef<((value: unknown) => void) | null>(null)

  const handleChange = useCallback((val: unknown) => {
    value.current = val
  }, [])

  const handleClose = useCallback(
    (success: boolean) => {
      setFormDialog(null)
      if (resolve.current) {
        const returnValue = success ? value.current : null
        resolve.current(returnValue)
      }
      resolve.current = null
    },
    [setFormDialog]
  )

  const prompt = useCallback(
    <T extends Record<string, unknown>>({
      schema,
      dialogProps,
    }: {
      dialogProps: Omit<CDialogProps, 'onClose' | 'open'>
      schema: ZodSchema<T>
    }) => {
      if (closePromise.current) {
        throw new Error('Only one dialog can be open at a time ')
      }
      setFormDialog(
        <FormDialogComponent
          open={true}
          schema={schema}
          dialogProps={dialogProps}
          onChange={handleChange}
          onClose={handleClose}
        />
      )
      // We need to wait until the dialog has closed before returning the value
      closePromise.current = new Promise(r => {
        // wait for the dialog to close
        resolve.current = r
      }).finally(() => {
        closePromise.current = undefined
      })
      return closePromise.current as Promise<T | null>
    },
    [handleChange, handleClose, setFormDialog]
  )

  return prompt
}
