/**
 * Stable ref is used to use e.g., function arguments that are not stable in useEffect hooks
 * to avoid infinite recursion
 */

import { useEffect, useRef } from 'react'

export const useStableRef = <T>(val: T): ReturnType<typeof useRef<T>> => {
  const ref = useRef<T>(val)
  useEffect(() => {
    ref.current = val
  }, [val])

  return ref
}

/**
 * @see https://github.com/reactjs/rfcs/pull/220 for the Facebook approach, but React Compiler
 * will likely provide a better solution.
 */
