import { entitiesApi } from '@tunasong/redux'
import type { EntityCommandSpec } from '@tunasong/schemas'
import { useCallback } from 'react'
import invariant from 'tiny-invariant'
import { useGlobalAppContext } from '../app-context.js'
import { getCustomCommands } from './custom-commands.hook.js'
import { usePlugins } from './editor-plugins.js'

/**
 * Provide a command runner that injects the context and parameters to the command.
 */
export const useCommandSpecRunner = () => {
  const plugins = usePlugins('all')

  const [loadEntity] = entitiesApi.useLazyLoadEntityQuery()
  const globalContext = useGlobalAppContext()

  const runner = useCallback(
    async ({ spec, targetEntityId }: { spec: EntityCommandSpec; targetEntityId: string }) => {
      invariant(plugins, 'plugins is null')
      // @todo handle non-entity commands as well.
      // @todo add entityType to the command to avoid loading the entity
      const targetEntity = await loadEntity({ id: targetEntityId }).unwrap()
      const cmdEntity = await loadEntity({ id: spec.entityId }).unwrap()
      // Get the commands from the plugin for cmdEntity
      const plugin = plugins.find(p => p.type === cmdEntity.type)
      invariant(plugin, 'plugin for cmdEntity is null')

      // Entity commands
      const entityCommands = plugin.getEntityCommands?.({ entity: cmdEntity, globalContext }) ?? []

      const customCommands = getCustomCommands({ cmdEntity, targetEntity, runner })

      const commands = [...entityCommands, ...customCommands]

      // Find the command
      const command = commands.find(c => c.id === spec.commandId)
      invariant(command, `command ${spec.commandId} not found in ${entityCommands.length} commands`)

      // Run the command
      return command.cmd?.(spec.commandParams)
    },
    [globalContext, loadEntity, plugins]
  )

  return plugins ? runner : null
}
