import { isCollabEntity } from '@tunasong/models'
import { skipToken, storageApi } from '@tunasong/redux'
import { type Entity } from '@tunasong/schemas'
import { Y, getElementValue, getSlateYDocVersion } from '@tunasong/sync-lib'
import { toUint8Array } from 'js-base64'
import { useMemo, useRef } from 'react'

/** Create a collaborative YDoc from the specified entity */
export const useYDoc = <T extends Entity = Entity>(entity?: T) => {
  const isCollab = isCollabEntity(entity)
  const { id, yDoc: entityYDoc, storageUrls } = entity ?? {}

  const useS3 = Boolean(storageUrls)

  // Legacy support for yDoc as string inside the entity
  const entityYDocAsUpdates = useMemo(() => (entityYDoc ? toUint8Array(entityYDoc) : undefined), [entityYDoc])

  // New support for yDoc in S3 storage
  const { data: yDocS3, ...restProps } = storageApi.useGetAuthorizedStorageFileQuery(
    storageUrls ? { storageUrls } : skipToken
  )

  const docUpdates = useS3 ? yDocS3 : entityYDocAsUpdates

  const docRef = useRef<Y.Doc>()

  /** If yDoc is set we apply the changes to the local doc */
  const { doc, value } = useMemo(() => {
    if (!id || !isCollab || !docUpdates) {
      return {}
    }

    /** Has ID changed? */
    if (id !== docRef.current?.guid) {
      docRef.current?.destroy()
      docRef.current = new Y.Doc({ guid: id })
    }
    const doc = docRef.current

    /** apply updates from yDoc string */
    Y.applyUpdate(doc, docUpdates)

    const value = getElementValue(doc)

    return { doc, value }
  }, [id, isCollab, docUpdates])

  const element = useMemo(() => (value ? ({ ...entity, children: value } as never as T) : null), [entity, value])

  const { v1, v2 } = useMemo(() => (doc ? getSlateYDocVersion(doc) : { v1: false, v2: false }), [doc])

  /** Ensure we never return a doc for the wrong ID */
  return { doc, element, value, v1, v2, ...restProps }
}
