import { z } from 'zod'
import { baseEntitySchema } from '../../core/entity.js'

export const pollTypes = ['one-of-many', 'many-of-many'] as const
export type PollType = (typeof pollTypes)[number]

export const pollSchema = baseEntitySchema.extend({
  type: z.literal('poll'),
  pollType: z.enum(pollTypes),
  name: z.string(),
  pollOptions: z.array(
    z.object({
      name: z.string(),
      entityRef: z.string().optional(),
    })
  ),
  randomizeOptions: z.boolean().optional().describe('Randomize the order of the poll options'),
  invited: z.array(z.string()).optional(),
  closed: z.boolean().optional(),
  allowChange: z.boolean().optional(),

  // Note we do this here instead of extended types because we cannot merge a union schema
  responseQuota: z.number().optional(),
  // specified in the extended types
  finalOption: z.unknown().optional(),
})

export const oneOfManyPollSchema = pollSchema.extend({
  pollType: z.literal('one-of-many'),
  finalOption: z.number().optional(),
})
export type OneOfManyPoll = z.infer<typeof oneOfManyPollSchema>

export const manyOfManyPollSchema = pollSchema.extend({
  pollType: z.literal('many-of-many'),
  finalOption: z.record(z.boolean()).optional(),
})
export type ManyOfManyPoll = z.infer<typeof manyOfManyPollSchema>

export type Poll = z.infer<typeof pollSchema>

export function isOneOfManyPoll(node?: unknown): node is OneOfManyPoll {
  return oneOfManyPollSchema.safeParse(node).success
}

export function isManyOfManyPoll(node?: unknown): node is ManyOfManyPoll {
  return manyOfManyPollSchema.safeParse(node).success
}

export function isPoll(node?: unknown): node is Poll {
  return pollSchema.safeParse(node).success
}
