import { v4 as uuidv4 } from 'uuid'

interface Vocab {
  vocab: string
  re: RegExp
}

type SupportedBase = 16 | 36 | 58 | 62
const baseVocabs: Record<SupportedBase, Vocab> = {
  16: {
    vocab: '0123456789abcdef',

    re: /^[0123456789abcdef]+$/,
  },
  36: {
    vocab: '0123456789abcdefghijklmnopqrstuvwxyz',
    re: /^[0123456789abcdefghijklmnopqrstuvwxyz]+$/,
  },
  58: {
    vocab: '123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ',
    re: /^[123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ]+$/,
  },
  62: {
    vocab: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    re: /^[0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]+$/,
  },
}

export const isBaseEncoded = (id: string) =>
  baseVocabs[16].re.test(id) || baseVocabs[36].re.test(id) || baseVocabs[58].re.test(id) || baseVocabs[62].re.test(id)

const DEFAULT_BASE = 36

const validateVocab = (base: number, vocab: string) => {
  if (vocab.length !== base) {
    throw new Error(`Configuration error: vocabulary is of length ${vocab.length}, should be ${Number(base)}`)
  }
}

/** Encode to vocabulary */
const encode = (value: bigint, base: SupportedBase) => {
  if (value === BigInt(0)) {
    return '0'
  }
  const { vocab } = baseVocabs[base]
  validateVocab(base, vocab)
  let s: string[] = []

  while (value > BigInt(0)) {
    const idx = value % BigInt(base)
    s = [vocab[Number(idx)], ...s]
    value = value / BigInt(base)
  }
  return s.join('')
}

const decode = (val: string, base: SupportedBase) =>
  val
    .split('')
    .reverse()
    .reduce(
      (prev, curr, i) => prev + BigInt(baseVocabs[base].vocab.indexOf(curr)) * BigInt(base) ** BigInt(i),
      BigInt(0)
    )

export const shortUuid = ({ base = DEFAULT_BASE, uuid = uuidv4() }: { base?: SupportedBase; uuid?: string } = {}) => {
  /** Create a uuid and strip the dashes */
  const hex = uuid.toLowerCase().replace(/-/g, '')
  const intUuid = BigInt(`0x${hex}`)
  return encode(intUuid, base)
}

// e.g., 2mc9k2b50u14ei66c1qq6qkhm
export const isShortUuid = (id: string) => id.length > 20 && baseVocabs[36].re.test(id)

/** Convert a UUID in the specified base (@default: 36) to a standard UUID string */
export const longUuid = ({ base, uuid }: { base: SupportedBase; uuid: string }) => {
  /** Validate the base62 format */
  if (!uuid.match(baseVocabs[base].re)) {
    throw new Error(`ID must be in base ${base} format: ${uuid}`)
  }

  const val = decode(uuid, base)
  const hex = encode(val, 16)
  if (hex.length !== 32) {
    throw new Error(`UUID hex value is not 32 characters: ${hex}`)
  }
  return `${hex.slice(0, 8)}-${hex.slice(8, 8 + 4)}-${hex.slice(12, 12 + 4)}-${hex.slice(16, 16 + 4)}-${hex.slice(
    20,
    20 + 12
  )}`
}
