import { z } from 'zod'
import { baseEntitySchema } from '../entity.js'

export const entityLinkSchema = baseEntitySchema.extend({
  type: z.literal('entitylink'),
  ref: baseEntitySchema.pick({ type: true, name: true, description: true }).extend({ id: z.string() }),
  error: z.boolean().optional(),
})

export type EntityLink = z.infer<typeof entityLinkSchema>

export function isEntityLink(node: unknown): node is EntityLink {
  return entityLinkSchema.safeParse(node).success
}
