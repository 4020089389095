import { combineReducers, configureStore as tkConfigureStore, type Middleware } from '@reduxjs/toolkit'
import { useStore as useReduxStore } from 'react-redux'
import { activitiesApi } from './api/activities.js'
import { entitiesApi } from './api/entities.js'
import { gamesApi } from './api/games.js'
import { mediaApi } from './api/media.js'
import { nlpApi } from './api/nlp.js'
import { profilesApi } from './api/profiles.js'
import { pushApi } from './api/push.js'
import { searchApi } from './api/search.js'
import { slugsApi } from './api/slugs.js'
import { storageApi } from './api/storage.js'
import { workflowsApi } from './api/workflows.js'
import { audioSlice } from './features/audio/audio-slice.js'
import { configSlice } from './features/config/config-slice.js'
import { editorsSlice } from './features/editors/editors-slice.js'
import entitiesMiddleware from './features/entities/entities-middleware.js'
import { entitiesSlice } from './features/entities/entities-slice.js'
import { notificationsSlice } from './features/notifications/notifications-slice.js'
import { presenceSlice } from './features/presence/presence-slice.js'
import { storageSlice } from './features/storage/storage-slice.js'
import { suggestionsSlice } from './features/suggestions/suggestions-slice.js'
import { uiSlice } from './features/ui/ui-slice.js'
import { userSlice } from './features/user/user-slice.js'
import { webRTCSlice } from './features/webrtc/webrtc-slice.js'
import { clientApi } from './api/client-api.js'

const featureMiddleware = [
  nlpApi.middleware,
  profilesApi.middleware,
  slugsApi.middleware,
  clientApi.middleware,
  activitiesApi.middleware,
  storageApi.middleware,
  searchApi.middleware,
  entitiesApi.middleware,
  mediaApi.middleware,
  pushApi.middleware,
  gamesApi.middleware,
  workflowsApi.middleware,

  /** Handle WS Redux messages */
  entitiesMiddleware,
]

// const rootReducer = combineReducers({
export const baseReducers = {
  /** @note we may want to listen to the plain redux events here, @see https://github.com/reduxjs/redux-toolkit/issues/1120#issuecomment-911669687 */
  [entitiesApi.reducerPath]: entitiesApi.reducer,
  [nlpApi.reducerPath]: nlpApi.reducer,
  [profilesApi.reducerPath]: profilesApi.reducer,
  [slugsApi.reducerPath]: slugsApi.reducer,
  [clientApi.reducerPath]: clientApi.reducer,
  [activitiesApi.reducerPath]: activitiesApi.reducer,
  [storageApi.reducerPath]: storageApi.reducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [mediaApi.reducerPath]: mediaApi.reducer,
  [pushApi.reducerPath]: pushApi.reducer,
  [gamesApi.reducerPath]: gamesApi.reducer,
  [workflowsApi.reducerPath]: workflowsApi.reducer,
  entities: entitiesSlice.reducer,
  suggestions: suggestionsSlice.reducer,
  user: userSlice.reducer,
  storage: storageSlice.reducer,
  config: configSlice.reducer,
  audio: audioSlice.reducer,
  notifications: notificationsSlice.reducer,
  webrtc: webRTCSlice.reducer,
  presence: presenceSlice.reducer,
  editors: editorsSlice.reducer,
  ui: uiSlice.reducer,
}

const baseRootReducer = combineReducers(baseReducers)

// : Reducer<T, Action<S>> | ReducersMapObject<T, Action<S>>
export const configureStore = <T>(rootReducer = baseRootReducer, middleware: Middleware<T>[] = []) =>
  tkConfigureStore({
    reducer: rootReducer,
    devTools: {
      // Set to keep devtools timetravel for 100 actions
      maxAge: 100,
      // options as if you were setting it up by hand
      // https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#windowdevtoolsextensionconfig
      // actionSanitizer: action => ({ ...action, payload: '<<SANITIZED>>' }),

      stateSanitizer: state => ({ ...state, 'entities-api': '<<SANITIZED>>' }),
      // stateSanitizer: isDev() ? undefined : state => ({ ...state, 'entities-api': '<<SANITIZED>>' }),
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        // /** These slow things down in development. I'll keep the immutableCheck since it'st he most important */
        serializableCheck: false,
        immutableCheck: false,
      })
        .concat(...featureMiddleware)
        /** @todo fix this - see https://github.com/reduxjs/redux-toolkit/issues/3962 */
        .concat(...middleware),
  })

export const rootStore = configureStore()
export type AppDispatch = typeof rootStore.dispatch

export type RootState = ReturnType<typeof baseRootReducer>

export const useStore = useReduxStore<RootState>

export type TunaGetState = ReturnType<typeof useStore>['getState']
