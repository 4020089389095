/* eslint-disable @typescript-eslint/no-explicit-any */
import { createHyperscript } from 'slate-hyperscript'
import { type TunaEditor } from '../plugin-types.js'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      [key: string]: unknown
    }
  }
}

export const jsxScript = createHyperscript({
  elements: {
    haudio: { type: 'audio' },
    hvideo: { type: 'video' },
    himage: { type: 'image' },
    htable: { type: 'table' },
    htr: { type: 'tr' },
    htd: { type: 'td' },
    hth: { type: 'th' },
    href: { type: 'ref' },
    htodo: { type: 'todo', completed: false },
    hline: { type: 'line' },
    hdivider: { type: 'divider' },
    hsection: { type: 'section' },
    hlink: { type: 'link' },
    /** Correctly named below */
    chord: { type: 'chord' },
    heading: { type: 'heading' },
    emoji: { type: 'emoji' },
    /** Not in use - just for testing normalization */
    p: { type: 'p' },
  },
})

// delete potentially circular attributes added by babel, prior to passing to h()

/** @see https://github.com/ianstormtaylor/slate/pull/2437 */
/**
 * @note we have an issue with @vitejs/plugin-react and the use of @babel/plugin-transform-react-jsx-source
 *
 * Since we are using @babel/plugin-transform-react-jsx-source, we need to remove the __self and __source properties
 *
 * Alternatively, we turn off the react plugin in vite.config.ts
 */
export const jsx = (tagName: any, attributes: any, ...children: any[]) =>
  // delete attributes.__self
  // delete attributes.__source
  jsxScript(tagName, attributes, ...children)

/** Expect hsDoc editor to always have selection set */
export const hsDoc = (doc: JSX.Element) => doc as unknown as TunaEditor
