import { getPkce } from './oauth-pkce.js'

export interface PKCE {
  verifier: string
  challenge: string
}
export const pkce = async () =>
  new Promise<PKCE>(resolve => {
    getPkce(43, (error: Error | null, { verifier, challenge }: PKCE) => {
      if (error) {
        throw error
      }
      resolve({ verifier, challenge })
    })
  })
