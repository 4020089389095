/** http://xahlee.info/comp/unicode_computing_symbols.html */
export const CMD = '⌘'
export const WIN = '❖'
export const SHIFT = '⇧'
export const BACKSPACE = '⌫'
export const OPTION = '⌥'
export const RETURN = '⏎'
export const TAB = '➔|'
export const ARROW_UP = '↑'
export const ARROW_DOWN = '↓'
export const ARROW_LEFT = '←'
export const ARROW_RIGHT = '→'
export const CTRL = '⌃'
export const ALT = '⎇'

export interface Shortcut {
  /** isHotKey compatible hotkey spec */
  hotkey: string
  description: string
}
