import * as React from 'react'
import cn from 'classnames'
import useStyles from './icon.styles.js'
function Guitar(props: { className?: string }) {
  const { className, ...restProps } = props
  const { classes } = useStyles()
  return (
    <svg
      className={cn(classes.root, className)}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...restProps}
    >
      <path d="M19.938 1.938l-2 2 .343.343-3.687 3.688C11.93 5.824 8.91 7.047 8.406 9.25c-.293 1.012-1.23 1.668-2.281 1.688-1.137.023-2.195.402-2.969 1.187-1.883 1.902-1.422 5.387 1 7.781C5.56 21.29 7.31 22.012 8.906 22c1.164-.008 2.239-.383 3.031-1.188.778-.785 1.18-1.863 1.188-3 .008-1.05.617-1.976 1.625-2.28 2.477-.962 2.941-3.802 1.25-6.095l3.719-3.718.343.343 2-2zm-8.5 6.968c.558-.004 1.14.2 1.687.531L11 11.563a1.984 1.984 0 00-1.906.53 1.98 1.98 0 000 2.813 1.98 1.98 0 002.812 0c.52-.52.703-1.242.531-1.906l2.126-2.125c.253.426.445.867.5 1.313.046.37.011 1.656-1.094 1.937-1.492.453-2.485 1.855-2.5 3.469-.004.511-.133 1.218-.688 1.781-.57.578-1.316.715-1.844.719-1.164.008-2.453-.59-3.437-1.563-.867-.86-1.43-1.91-1.563-2.968-.117-.934.118-1.735.657-2.282.554-.562 1.304-.707 1.812-.719 1.614-.03 2.969-1.066 3.407-2.562.207-.777 1.269-1.09 1.624-1.094zm-5 5.563l-.938.937L8.625 18.5l.938-.938z" />
    </svg>
  )
}

export default Guitar
