import { Box, type BoxProps } from '@mui/material'
import { useProfiles } from '@tunasong/redux'
import { type FC } from 'react'
import { usePresence } from '../hooks/presence.js'
import { UserAvatar } from '../profile/index.js'

export interface PresenceProps extends BoxProps {
  className?: string
  entityId?: string
}

export const Presence: FC<PresenceProps> = props => {
  const { entityId, ...restProps } = props

  const userIds = usePresence(entityId)

  /** Load profiles in batch */
  const {} = useProfiles({ userIds })

  return (
    <Box display="flex" flexDirection="row" {...restProps}>
      {userIds.map((userId, idx) => (
        <UserAvatar size="tiny" key={idx} userId={userId} />
      ))}
    </Box>
  )
}

export default Presence
