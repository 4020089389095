import { baseEntitySchema } from '../entity.js'
import { z } from 'zod'
import { coreElementValueSchema } from '../slate/slate-element.js'

export const noteSchema = baseEntitySchema.extend({
  type: z.literal('note'),
  /** Notes do not use YDoc since they are simple and personal */
  children: coreElementValueSchema,
})

export function isNote(node: unknown): node is z.infer<typeof noteSchema> {
  return noteSchema.safeParse(node).success
}

export type Note = z.infer<typeof noteSchema>

export default noteSchema
