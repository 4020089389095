import { type EntityType } from '@tunasong/schemas'
import { useCallback } from 'react'
import { type RootState } from '../configure-store.js'
import { useSelector } from './selector.hook.js'
import { logger, MimeMatcher } from '@tunasong/models'

/** Map between stored entity types (e.g., wav audio) and mime types */
export const useMimeTypes = () => {
  const entityTypeToMimeType = useSelector((state: RootState) => state.storage.entityTypeToMimeType)

  const extensionToEntityType = useSelector((state: RootState) => state.storage.extensionToEntityType)
  const mimeTypeAccept = useSelector((state: RootState) => state.storage.mimeTypeAccept)

  const getMimeTypeFromEntityType = useCallback(
    (entityType: EntityType) => entityTypeToMimeType[entityType],
    [entityTypeToMimeType]
  )
  const getEntityTypeFromMimeType = useCallback(
    (mimeType: string) => {
      let type: EntityType | undefined
      try {
        for (const [entityType, supportedMimeType] of Object.entries(entityTypeToMimeType)) {
          const matcher = new MimeMatcher(supportedMimeType)
          const accepted = matcher.match(mimeType)
          if (accepted) {
            type = entityType as EntityType
            break
          }
        }
      } catch (e) {
        logger.error(`Error checking mime type: ${e}`)
      }
      return type
    },
    [entityTypeToMimeType]
  )
  const getEntityTypeFromExtension = useCallback(
    (extension: string) => {
      const ext = extension.startsWith('.') ? extension : `.${extension}`
      return extensionToEntityType[ext]
    },
    [extensionToEntityType]
  )

  return { getMimeTypeFromEntityType, getEntityTypeFromExtension, getEntityTypeFromMimeType, mimeTypeAccept }
}
