import { z } from 'zod'

export const standardNoteNames = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'] as const
export const standardNoteNamesWithAlt = [
  'C',
  'C#/Db',
  'D',
  'D#/Eb',
  'E',
  'F',
  'F#/Gb',
  'G',
  'G#/Ab',
  'A',
  'A#/Bb',
  'B',
] as const

const noteNames = [
  ...standardNoteNames,
  'Dbb',
  'Db',
  'Ebb',
  'Eb',
  'Fb',
  'E#',
  'Gb',
  'Abb',
  'Ab',
  'Bbb',
  'Bb',
  'Cb',
  'B#',
] as const

export const noteNameSchema = z.enum(noteNames)
export type NoteName = z.infer<typeof noteNameSchema>

export const standardNoteNamesSchema = z.enum(standardNoteNames)
export type StandardNoteName = z.infer<typeof standardNoteNamesSchema>

export const standardNoteNamesWithAltSchema = z.enum(standardNoteNamesWithAlt)
export type StandardNoteNameWithAlt = z.infer<typeof standardNoteNamesWithAltSchema>
