import { z } from 'zod'
import { elementOptionsSchema } from './slate-element-options.js'

export const blockElementTypeNames = [
  'line',
  'li',
  'lic',
  'ul',
  'ol',
  'ref',
  'heading',
  'section',
  'embed',
  'todo',
  'divider',
] as const
export const inlineElementTypeNames = ['emoji', 'chord', 'link', 'entitylink', 'mention'] as const
export const proxyElementTypeNames = ['facade'] as const

export const elementTypeNames = [...inlineElementTypeNames, ...proxyElementTypeNames, ...blockElementTypeNames] as const

export const slateElementSchema = z.object({
  type: z.string(),
  text: z.string().optional(),
  children: z.array(z.any()),
})

export function isSlateElement(element?: unknown): element is SlateElement {
  return slateElementSchema.safeParse(element).success
}

export const coreElementValueSchema = z
  .array(
    // z.union([
    //   z.object({ text: z.string() }),
    //   z.object({
    //     type: z.string(),
    //     children: z.array(z.any()),
    //   }),
    // ])
    z.any()
  )
  .min(1)

export const coreElementSchema = slateElementSchema.omit({ children: true }).extend({
  id: z.string().optional(),
  name: z.string().optional(),
  description: z.string().optional(),
  tags: z.array(z.string()).optional(),
  options: elementOptionsSchema.optional(),
  comments: z.boolean().optional().describe('Specifies whether the element has comments'),
  children: coreElementValueSchema,
})

export type CoreElement<T = {}> = z.infer<typeof coreElementSchema> & { [key: string]: unknown } & T

export function isCoreElement<T>(element?: T): element is CoreElement<T> {
  return coreElementSchema.safeParse(element).success
}

export type SlateElement = z.infer<typeof slateElementSchema>

/** @todo move away? */
export type ProxyElementType = (typeof proxyElementTypeNames)[number]
/** A facade/proxy element used when the data of the original element cannot be read  */
export const isProxyElementType = (name: string) => proxyElementTypeNames.includes(name as never)
