import { useCallback, useEffect, useState } from 'react'

export const isFullScreen = () => Boolean(document.fullscreenElement)

export const useFullScreen = (el: Element | null) => {
  const [fullScreen, setFullscreen] = useState(false)

  /** Start fullscreen */

  const toggleFullScreen = useCallback(() => {
    if (!el) {
      return
    }
    const fs = Boolean(document.fullscreenElement)
    if (!fs) {
      el.requestFullscreen()
    } else {
      document.exitFullscreen()
    }
  }, [el])

  useEffect(() => {
    const fs = () => {
      setFullscreen(Boolean(document.fullscreenElement))
    }
    document.addEventListener('fullscreenchange', fs)
    return () => {
      document.removeEventListener('fullscreenchange', fs)
    }
  }, [])
  /** End fullscreen */

  return { toggleFullScreen, isFullScreen: fullScreen }
}
