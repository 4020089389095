/**
 * Simple Key Value Store using idb / IndexedDB
 * @see https://www.npmjs.com/package/idb
 * */

import { openDB } from 'idb'

/**
 * @note Safari does not support Blobs in IndexedDB
 * https://developers.google.com/web/fundamentals/instant-and-offline/web-storage/indexeddb-best-practices
 * use ArrayBuffer for that kind of data
 */
export type DBStorageType = string | number | boolean | Record<string, unknown> | unknown[] | ArrayBuffer | Blob

export const openDatabase = <T = DBStorageType>(dbName: string) => {
  if (typeof window === 'undefined') {
    return null
  }
  const dbPromise = openDB(dbName, 1, {
    upgrade(db) {
      db.createObjectStore('keyval')
    },
  })

  return {
    async has(key: IDBValidKey) {
      return Boolean((await dbPromise).getKey('keyval', key))
    },
    async get(key: IDBValidKey) {
      return (await dbPromise).get('keyval', key) as Promise<T>
    },
    async set(key: IDBValidKey, val: T) {
      return (await dbPromise).put('keyval', val, key)
    },
    async delete(key: IDBValidKey) {
      return (await dbPromise).delete('keyval', key)
    },
    async clear() {
      return (await dbPromise).clear('keyval')
    },
    async keys() {
      return (await dbPromise).getAllKeys('keyval')
    },
    dbPromise,
  }
}
