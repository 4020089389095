/** Context to allow for UI component extensions */

import { type Entity } from '@tunasong/schemas'
import type { Persisted } from '@tunasong/schemas'
import React, { type FC } from 'react'

export interface UIExtensionItemMenuProps {
  entity: Persisted<Entity>
  anchorEl: Element | null
  clickAway?: boolean
  onClose(): void
}

export interface UIExtensions {
  entityList?: {
    /** Drop-down menu for entity list items */
    ItemMenu?: FC<UIExtensionItemMenuProps>
  }
}

export const UIExtensionsContext = React.createContext<UIExtensions | null>(null)
